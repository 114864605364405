import { CoachHome } from '@customTypes/response/IChat';
import { PromiseCallbacks, useFetch } from '@hooks/useFetch';
import { getProviderCoachHome } from '@services/provider';

export const useCoachHome = (callbacks?: PromiseCallbacks) =>
  useFetch<CoachHome>(['coach-home'], () => getProviderCoachHome(), {
    onSuccess: callbacks?.onSuccess,
    refetchOnWindowFocus: true,
    enabled: callbacks?.enabled !== undefined ? callbacks?.enabled : true,
  });
