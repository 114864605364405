import { CoachHomeData } from '@customTypes/response/IChat';
import * as React from 'react';

export interface IFloatingChatWindowSettings {
  show: boolean;
  initialProvider?: CoachHomeData;
  viewMode?: 'full-view' | 'chat-view';
}
export interface ILayoutContext {
  updateChatWindowSettings: ({ show }: IFloatingChatWindowSettings) => void;
  layoutRef: React.RefObject<HTMLDivElement>;
  chatWindowVisible: boolean;
  reloadChat?: ()=>void;
}

const LayoutContext = React.createContext<ILayoutContext>({} as ILayoutContext);

export default LayoutContext;
