import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@widgets/Typography';
import cancellationIcon from '@assets/broken-session.png';
import Button from '@widgets/Button';
import './SessionCancelPopup.scss';

function WarningModalV2({ handleCancel, handleClose }: { handleCancel: () => void, handleClose: () => void }) {
  const { t } = useTranslation();
  return (
    <div className="within-24hrs">
      <div>
        <img src={cancellationIcon} alt="broken-icon" className="broken-icon" />
      </div>
      <div className="subText">
        <Typography size={20} weight="600" textAlign="center">
          {t('WITHIN_24HRS_HEADER')}
        </Typography>
        <Typography
          size={16}
          weight="400"
          withColor="#0a0c0e5c"
          textAlign="center"
        >
          {t('CREDIT_WONT_REFUND')}
        </Typography>
        <Typography size={16} weight="400" withColor="#0a0c0e5c" textAlign="center">
          {t('WISH_TO_CANCEL')}
        </Typography>
      </div>
      <div className="warning-actions">
        <Button
          label={t('YES_CANCEL')}
          variant="blue"
          onClick={handleCancel}
          fontSize={15}
        />
        <Button
          label={t('NO_CANCEL')}
          variant="secondary"
          onClick={handleClose}
          fontSize={14}
        />
      </div>
    </div>
  );
}

export default WarningModalV2;
