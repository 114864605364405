import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@widgets/Button';
import './OnsiteCardLayout.scss';
import Typography from '@widgets/Typography';
import IconText from '@components/IconText';
import DateBadge from '@components/DateBadge';
import Loader from '@widgets/Loader';
import Tags from '@widgets/Tags';
import { IOnsiteCardLayoutProps } from './types';
// todo check the schema to make sure the props are correct & replace static dta with the dynamic values
function OnsiteCardLayout({
  isDeployementCard,
  cardTitle,
  dateIcon,
  locationIcon,
  events,
  providerName,
  providerAvatar,
  providerSpeciality,
  cardContent,
  buttonVariant = 'secondary',
  onClick,
  eventIcon,
  eventLocation,
  eventTimings,
  eventDate,
  eventMonth,
  cardWidth = '25.685rem',
  isLoading,
  redirectUrl,
  disabled,
}: IOnsiteCardLayoutProps) {
  const { t } = useTranslation();
  const getStyles = () => {
    const styles: React.CSSProperties = {
      width: cardWidth,
    };
    return styles;
  };

  const location =
    typeof eventLocation === 'string'
      ? eventLocation
      : `${eventLocation.address}, ${eventLocation.city}`;

  return (
    <article className="onsite-card-layout" style={getStyles()}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <section className="event-infor-container">
            {isDeployementCard && eventDate && eventMonth ? (
              <DateBadge date={eventDate} month={eventMonth} />
            ) : (
              <img src={eventIcon} alt="event-icon" />
            )}
            <section className="event-info">
              <Typography
                size={20}
                lineHeight="120%"
                weight="600"
                overFlowControl={!isDeployementCard ? 'ellipsis' : 'clip'}
                additionalStyles={
                  !isDeployementCard
                    ? {
                      width: '100%',
                      whiteSpace: 'nowrap',
                    }
                    : {}
                }
              >
                {cardTitle}
              </Typography>
              <section className="event-details">
                <IconText
                  icon={dateIcon}
                  text={`${eventTimings} onwards`}
                  fontColor="#1570EF"
                  textWidth="100%"
                />
                <IconText
                  icon={locationIcon}
                  text={location}
                  fontColor="#1570EF"
                  redirectUrl={redirectUrl}
                  redirectIconColor="#1570EF"
                  textWidth={isDeployementCard ? '14rem' : 'auto'}
                />
              </section>
              {events && (
                <Tags tags={events.map((event) => t(event.eventType))} />
              )}
            </section>
          </section>

          <section className="onsite-card-body">
            {isDeployementCard ? (
              <>
                <Typography size={10} weight="400" withColor="#828B9C">
                  Counsellor
                </Typography>
                <section className="provider-details">
                  <img
                    src={providerAvatar}
                    alt="provider-avatar"
                    className="provider-avatar"
                  />
                  <section className="provider-info">
                    <Typography size={14} weight="500">
                      {providerName}
                    </Typography>
                    <Typography size={10} weight="400" withColor="#828B9C">
                      {providerSpeciality}
                    </Typography>
                  </section>
                </section>
              </>
            ) : (
              <Typography
                size={14}
                weight="400"
                withColor="#6c6d6e"
                overFlowControl="ellipsis"
                display="-webkit-box"
                additionalStyles={{
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}
              >
                {cardContent}
              </Typography>
            )}
          </section>

          <section className="onsite-card-footer">
            <Button
              label={isDeployementCard ? 'View Details' : 'Learn More'}
              onClick={onClick}
              width="100%"
              variant={buttonVariant}
              disabled={disabled}
            />
          </section>
        </>
      )}
    </article>
  );
}

export default OnsiteCardLayout;
