import { PromiseCallbacks, useMutation } from '@hooks/useFetch';
import { loginAsResponder } from '@services/responder';

function useResponderLogin(
  email: string,
  password: string,
  user: string | null,
  { onSuccess, onError }: PromiseCallbacks,
) {
  return useMutation(() => loginAsResponder(email, password, user), {
    onSuccess,
    onError,
  });
}

export default useResponderLogin;
