import * as React from 'react';
import './TextBox.scss';
import { AnimatePresence, motion, TargetAndTransition } from 'framer-motion';
import { fade } from '@animations/variants';
import Typography from '@widgets/Typography';
import validationSuccessIcon from './assets/validation-success.svg';
import validationFailIcon from './assets/validation-fail.svg';

interface ITextBox {
  id?: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string,
  icon?: string,
  animate?: boolean,
  variant?: 'normal' | 'box' | 'solarized' | 'box-border',
  type?: 'text' | 'password';
  disabled?: boolean;
  showValidationMark?: boolean;
  isValidInput?: boolean;
  errorMessage?: string;
  maxLength?: number;
  customColors?: {
    backgroundColor: string;
    fontColor: string;
    borderColor: string;
  }
}

const variantBGColorMap = {
  normal: '#fff',
  box: '#F1F1F5',
  solarized: ' #E6EDF2',
  'box-border': '#FFF',
};

function TextBox({
  id,
  value,
  onChange,
  customColors,
  errorMessage = '',
  placeholder = '',
  icon = '',
  animate = false,
  variant = 'normal',
  type = 'text',
  disabled = false,
  showValidationMark = false,
  isValidInput = true,
  maxLength = 1000,
}: ITextBox) {
  const getFocusAnimations = (): TargetAndTransition => {
    let animations: TargetAndTransition = {};
    if (animate) {
      animations = { ...animations, scaleX: 1.1, translateX: -20 };
    }

    if (variant === 'box-border') {
      animations = { ...animations, border: '1px solid #263E58' };
    }

    return animations;
  };

  const getStyles = (): React.CSSProperties => {
    const styles: React.CSSProperties = {};
    if (icon) {
      styles.background = `${variantBGColorMap[variant]} url(${icon}) no-repeat scroll 13px 50%`;
      styles.paddingLeft = '40px';
    } else {
      styles.backgroundColor = variantBGColorMap[variant];
      styles.paddingLeft = '20px';
    }

    if (customColors) {
      const { backgroundColor, fontColor, borderColor } = customColors;
      styles.background = backgroundColor;
      styles.color = fontColor;
      styles.border = `1px solid ${borderColor}`;
    }

    return styles;
  };

  return (
    <div className="textbox-container">
      <div className="textbox-action-container">
        <motion.input
          id={id || placeholder?.replaceAll(' ', '').replaceAll('*', '')}
          type={type}
          className={variant}
          onChange={onChange}
          value={value}
          whileHover={variant === 'box-border' ? { border: '1px solid #263E58' } : {}}
          whileFocus={getFocusAnimations()}
          placeholder={placeholder}
          disabled={disabled}
          style={getStyles()}
          maxLength={maxLength}
        />
        <AnimatePresence>
          {showValidationMark && (
            <motion.img
              className="validation-success"
              src={isValidInput ? validationSuccessIcon : validationFailIcon}
              alt="validation success"
              variants={fade}
              initial="out"
              animate="in"
              exit="out"
            />
          )}
        </AnimatePresence>
      </div>
      {errorMessage && (
        <motion.div
          className="error-message-container"
          variants={fade}
          initial="out"
          animate="in"
          exit="out"
        >
          <Typography size={12} weight="400" color="error">
            {errorMessage}
          </Typography>
        </motion.div>
      )}
    </div>
  );
}

export default TextBox;
