import { useFetch } from '@hooks/useFetch';
import { getProviderLeaveDays } from '@services/provider';

function useProviderLeaveDays(providerId: string) {
  return useFetch<string[]>(
    ['provider', 'leave', providerId],
    () => getProviderLeaveDays(providerId),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export default useProviderLeaveDays;
