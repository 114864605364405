import toast, { ToastBar, Toaster, useToasterStore } from 'react-hot-toast';
import * as React from 'react';

const TOAST_LIMIT = 1;

export function StyledToaster() {
  const { toasts } = useToasterStore();

  React.useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  return (
    <Toaster
      containerStyle={{ top: 50 }}
      toastOptions={{
        duration: 3000,
        blank: {
          style: { backgroundColor: '#263E58' },
        },
        success: {
          style: { backgroundColor: '#008a48' },
        },
        error: {
          style: { backgroundColor: '#DB4256' },
        },
        style: {
          display: 'flex',
          gap: '0.5rem',
          justifyContent: 'space-between',
          minWidth: '50vw',
          minHeight: '2.5rem',
          width: '50vw',
          maxWidth: '50%',
          backgroundColor: '#263E58',
          padding: '0.1rem 1rem',
          color: 'white',
          fontFamily: 'Poppins, sans-serif',
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ message }) => (
            <>
              <div style={{ textAlign: 'left', fontSize: 12 }}>{message}</div>
              {t.type !== 'loading' && (
                <button
                  style={{
                    all: 'unset',
                    fontSize: '0.75em',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                  }}
                  type="button"
                  onClick={() => toast.dismiss(t.id)}
                >
                  Okay
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
