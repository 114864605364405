import * as React from 'react';
import Typography from '@widgets/Typography';
import './Tags.scss';
import { CssSize } from '@widgets/ClickableCard/types';
import { FontWeights } from '@widgets/Typography/Typography';

type TagVariants = 'normal' | 'blue' | 'pill';

interface ITags {
  tags: string | string[];
  variant?: TagVariants;
  backgroundColor?: string;
  textColor?: string;
  textSize?: number;
  height?: CssSize;
  fontWeight?: FontWeights;
}

const variantTextColorMap: Record<TagVariants, string> = {
  normal: '#121212',
  blue: '#2A68B6',
  pill: '#FFFFFF',
};

function Tags({
  tags,
  variant = 'normal',
  backgroundColor,
  textColor,
  textSize,
  height = '24px',
  fontWeight = '500',
}: ITags) {
  const tagItems = typeof tags === 'string' ? tags.split(',') : tags;
  return (
    <div className="tags-container">
      {tagItems.map((tag) => (
        <div
          key={tag}
          className={`tag ${variant}`}
          style={backgroundColor ? { backgroundColor, height } : { height }}
        >
          <Typography
            size={textSize || 10}
            weight={fontWeight}
            withColor={textColor || variantTextColorMap[variant]}
          >
            {tag}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default Tags;
