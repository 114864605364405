import {
  IBookMeetingRequest,
  ProviderMatchRequest,
  IRescheduleRequest,
} from '@customTypes/request/provider';
import { ProviderSlotAPIVersions } from '@customTypes/responder';
import { beautifyUrl } from '@utilities/common/Path';
import Config from '../config';
import NetworkClient from './Network';

const errorMessages = {
  generic: 'Unable to load the provider data, please try again!',
  reschedule: 'something went wrong, please try again!',
};

const client = new NetworkClient();

export const getProviderOpenSlots = async (
  providerId: string,
  role: string,
  locationId: string | null,
  version: ProviderSlotAPIVersions,
  credits?: string,
) => {
  const slotUrls: Record<ProviderSlotAPIVersions, string> = {
    v1: Config.api.provider.slotsV1,
    v2: Config.api.provider.slotsV2,
  };

  console.log(credits);

  const response = await client.doGet(
    beautifyUrl(slotUrls[version], [providerId]),
    undefined,
    { role, locationId, credits },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getProviderOpenSlotsV2 = async ({
  providerId,
  role,
  locationId,
  userId,
  count,
}: {
  providerId: string;
  role: string;
  locationId: string | null;
  userId?: string;
  count?: string;
}) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.slotsV2, [providerId]),
    undefined,
    { role, locationId, count, userId },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const matchWithProvider = async (
  userId: string,
  providerMatchRequest: ProviderMatchRequest,
  headers: { timezone?: string },
) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.provider.match, [userId]),
    providerMatchRequest,
    headers,
  );
  if (response && response.success) {
    return {
      success: true,
    };
  }

  throw new Error(errorMessages.generic);
};

export const bookMeeting = async (
  userId: string,
  body: IBookMeetingRequest,
  headers: { timezone?: string },
) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.provider.book, [userId]),
    body,
    headers,
  );
  if (response.data && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

export const getProviderCountries = (
  userId: string,
  params: { role: string[]; engagement: string },
) =>
  client
    .doGet(
      beautifyUrl(Config.api.provider.countriesList, [userId]),
      undefined,
      params,
    )
    .then((response) => response.data.countries);

export const getMatchingConfig = () =>
  client
    .doGet(Config.api.provider.matchConfig)
    .then((response) => response.data);

export const getProviderProfile = async ({
  providerId,
  role,
  userId,
}: {
  providerId: string;
  role: string;
  userId: string;
}) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.profile, [providerId]),
    undefined,
    { role, userId },
  );
  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

export const getProviderHome = async (userId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.home, [userId]),
  );
  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

export const cancelMeeting = async (
  meetingId: string,
  note: string,
  reason?: { type: string; otherReason: string },
) => {
  const payload = reason
    ? {
      reason,
      options: {},
    }
    : {
      options: {
        message: note,
      },
    };
  const response = await client.doPost(
    beautifyUrl(Config.api.provider.cancelSession, [meetingId]),
    payload,
  );
  if (response.error) {
    throw new Error(response.error?.message);
  }

  return response.data;
};
export const cancelMeetingAsCareNavigator = async (
  userId: string,
  meetingId: string,
  note: string,
  reason?: { type: string; otherReason: string },
) => {
  const payload = reason
    ? {
      reason,
      options: {},
    }
    : {
      options: {
        message: note,
      },
    };
  const response = await client.doPut(
    beautifyUrl(Config.api.careNavigator.cancel, [userId, meetingId]),
    payload,
  );
  if (response.error) {
    throw new Error(response.error?.message);
  }

  return response.data;
};

export const confirmSessionRequest = async (meetingId: string) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.provider.confirmSessionRequest, [meetingId]),
    {},
  );
  if (response.error) {
    throw new Error(response.error?.message);
  }

  return response.data;
};

export const getProviderCoachHome = async () => {
  const response = await client.doGet(Config.api.user.coachHome);
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response?.data?.error?.message);
};

export const rescheduleSession = async (
  param: IRescheduleRequest,
  meetingId: string,
) => {
  const response = await client.doPut(
    beautifyUrl(Config.api.provider.rescheduleMeeting, [meetingId]),
    param,
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response?.error?.message || errorMessages.reschedule);
};

export const rescheduleSessionAsCareNavigator = async (
  param: IRescheduleRequest,
  userId: string,
  meetingId: string,
) => {
  const response = await client.doPut(
    beautifyUrl(Config.api.careNavigator.reschedule, [userId, meetingId]),
    param,
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response?.error?.message || errorMessages.reschedule);
};

export const getCancellationReasons = async ({
  locale,
  userType,
}: {
  locale: string;
  userType: number;
}) => {
  const response = await client.doGet(
    Config.api.provider.cancellationReasons,
    {},
    {
      locale,
      userType,
    },
  );
  if (response && response.success) {
    return response.data.cancellationReasons;
  }
  throw new Error(response?.error?.message || errorMessages.reschedule);
};

export const getProviderList = async (
  userId: string,
  { role, engagement, countryId, goals, languages }: any,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.list, [userId]),
    // { headers: timezone },
    undefined,
    { role, engagement, countryId, goals, languages },
  );
  return response.data;
};

export const matchProvider =
  (userId: string, body: { providerId: number }) => async () => {
    const response = await client.doPost(
      Config.api.provider.assign.replace('{userId}', String(userId)),
      body,
    );
    return !!response.data;
  };

// export const bookMeeting =
//   () => async (body: { selectedSlot: number; selectedDate: string }) => {
//     const response = await client.doPost(Config.api.provider.book, body);
//     return response.data;
//   };

export const getProviderLeaveDays = async (providerId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.getLeaveDays, [providerId]),
  );
  if (response && response.success) {
    return (
      response.data?.leaves.map(
        (item: Record<'displayDate', string>) => item.displayDate,
      ) || []
    );
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

export const getProviderServices = async (providerId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.getServices, [providerId]),
  );
  if (response && response.success) {
    return response.data || {};
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

