import * as React from 'react';
import { IVariables } from '@customTypes/response/IVariables';

export interface IUserDetails {
  userData: any;
  isLoading: boolean;
  currentUserConfig: IVariables;
}

export interface ICurrentUserContext {
  currentUserData: IUserDetails;
  updateUserData: (userData: Partial<IUserDetails>) => void;
}

export const CurrentUserContext = React.createContext<ICurrentUserContext>(
  {} as ICurrentUserContext,
);
