import * as React from 'react';
import './FloatingChatWindowV2.scss';

import {
  OldChatContactFormat,
  RecentChatMessageFormat,
} from '@utilities/common/Date';
import { CoachHomeData } from '@customTypes/response/IChat';
import Typography from '@widgets/Typography';
import moment from 'moment';
import Loader from '@widgets/Loader';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import useTracking from '@hooks/useTracking';
import { AllConversationListType } from '../../data-types/ChatBrokerTypes';
import FloatingWidget from '../FloatingWidget/FloatingWidget';
import ChatContact from '../ChatContactV2';
import { useChatBroker } from '../../broker/useChatBroker';
import LiveChat from '../LiveChatV2';
import { HowToUseChat } from './HowToUseChat';

export interface FloatingChatWindowV2Props {
  initialProvider?: CoachHomeData | null;
  viewMode?: 'full-view' | 'chat-view';
}

function FloatingChatWindowV2({
  initialProvider,
  viewMode = 'chat-view',
}: FloatingChatWindowV2Props) {
  const { track } = useTracking();
  const { isLoading, allConversationList, liveChatData } = useChatBroker();
  const [selectedChat, setSelectedChat] =
    React.useState<AllConversationListType>({} as AllConversationListType);
  const [parentDimensions, setParentDimensions] = React.useState<DOMRect>();
  const pageNo = React.useRef<number>(1);

  function onConversationSelect(conversation: AllConversationListType) {
    setSelectedChat(conversation);
    pageNo.current = 1;
    liveChatData.getSelectedUserMessages({
      selectedConversationId: conversation?.conversationId,
      channelId: conversation.channelId,
      pageNo: pageNo.current,
      channelType: conversation.channelType,
    });
  }
  React.useEffect(() => {
    if (initialProvider?.provider.providerId && allConversationList) {
      const chatList = [
        ...allConversationList.active,
        ...allConversationList.oldChat,
      ];
      const [filteredUser] = chatList.filter(
        (chat) => +chat.providerId === +initialProvider.provider.providerId,
      );
      onConversationSelect(filteredUser);
    }
  }, [initialProvider, allConversationList?.active?.length]);

  const parentMeasuredRef = React.useCallback((node: any) => {
    if (node !== null) {
      setParentDimensions(node.getBoundingClientRect());
    }
  }, []);
  const getLastMessagedTimeLabel = (
    currentDate: moment.Moment,
    lastMessagedDate: moment.Moment,
  ): string => {
    const isSameDay = currentDate.startOf('day') <= lastMessagedDate;
    const isYesterday =
      currentDate.subtract('1', 'day').startOf('day') <= lastMessagedDate;
    if (isSameDay) {
      return lastMessagedDate.format(RecentChatMessageFormat);
    }
    if (isYesterday) {
      return 'yesterday';
    }

    return lastMessagedDate.format(OldChatContactFormat);
  };
  return (
    <FloatingWidget initialPosition={{ right: '1rem', bottom: '2rem' }}>
      <section
        className={`chat-window-container ${viewMode}`}
        ref={parentMeasuredRef}
      >
        {isLoading && (
          <Loader useFullPage={false} dimension={parentDimensions} />
        )}
        {viewMode === 'full-view' && (
          <section className="contacts-container">
            <section className="contacts-header-container">
              <Typography size={24} weight="600" color="primary">
                Chats
              </Typography>
            </section>

            <section className="chats-wrapper">
              <div className="chats-list">
                {allConversationList?.active?.map((conversation) => (
                  <ChatContact
                    profileImage={conversation.providerImage}
                    key={conversation?.conversationId}
                    name={conversation.providerName}
                    message={conversation.lastMessage || 'Shared a file'}
                    messagesUnread={conversation.unreadCount}
                    messagedTime={getLastMessagedTimeLabel(
                      moment(),
                      moment(conversation.lastMessageDate),
                    )}
                    selected={
                      selectedChat?.conversationId ===
                      conversation?.conversationId
                    }
                    onClick={() => {
                      onConversationSelect(conversation);
                      track(EventNames.messages, {
                        eventAction: EventActions.click,
                        eventCategory: EventCategories.viewUserChat,
                        eventLabel: 'view_user_chat',
                        userType: Analytics.getUserType(),
                        eventValue: conversation?.providerId.toString(),
                      });
                    }}
                  />
                ))}
              </div>
              <HowToUseChat />
            </section>
          </section>
        )}
        <section className={`chat-container ${viewMode}`}>
          <LiveChat
            userMetaData={selectedChat}
            getUnReadCount={liveChatData.getSelectedUserUnreadCount}
            selectedUserMsg={liveChatData.selectedUserData}
            isLoading={liveChatData.isLoading}
            getSelectedUserMessages={liveChatData.getSelectedUserMessages}
            pageNoRef={pageNo}
            updateMessageConsumptionInfo={
              liveChatData.updateMsgConsumptionStatus
            }
            isNextMessageLoaded={liveChatData.isNextMessageLoaded}
          />
        </section>
      </section>
    </FloatingWidget>
  );
}

export default FloatingChatWindowV2;
