import * as React from 'react';
import useAuth from '@hooks/useAuth';
import useTracking from '@hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import ChatFloatingButton from '../Chat/components/ChatFloatingButton';
import './Layout.scss';
import LayoutContext, {
  IFloatingChatWindowSettings,
  ILayoutContext,
} from '../../../shared/context/LayoutContext';
import usePaths from '../../../utilities/hooks/usePaths';
import Header from '../Header';
import FloatingChatWindowV2 from '../Chat/components/FloatingChatWindowV2';
import { useCoachHome } from '../Chat/utils/hooks/useHome';
import {
  routeExceptionsForSectionComponents,
  routeForFilterComponent,
} from './constant/LayoutConstant';
import FilterSection from '../FilterSection/FilterSection';

export interface ILayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: ILayoutProps) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [firstPath] = usePaths();
  const [isExceptionPath, setExceptionPath] = React.useState<boolean>(true); // * isExceptionPath is for rerendering layout if the current screen doesn't require navbar or header
  const { isLoggedIn, user } = useAuth();
  const { track } = useTracking();

  const [floatingChatWindowSettings, setFloatingChatWindowSettings] =
    React.useState<IFloatingChatWindowSettings>({
      show: false,
      initialProvider: undefined,
      viewMode: 'full-view',
    });
  const { show, initialProvider, viewMode } = floatingChatWindowSettings;

  const updateChatWindowSettings = (
    chatWindowSettings: Partial<IFloatingChatWindowSettings>,
  ) => {
    setFloatingChatWindowSettings((prev) => ({
      ...prev,
      ...chatWindowSettings,
    }));
  };
  const { data: homeData, refetch: refetchCoachHome } = useCoachHome({
    enabled: Boolean(isLoggedIn),
  });
  const contextValues = React.useMemo<ILayoutContext>(
    () => ({
      layoutRef: parentRef,
      updateChatWindowSettings,
      chatWindowVisible: show,
      reloadChat: refetchCoachHome,
    }),
    [show],
  );

  const showFilter = React.useMemo<boolean>(
    () => routeForFilterComponent.includes(firstPath),
    [firstPath],
  );
  React.useEffect(() => {
    if (routeExceptionsForSectionComponents.includes(firstPath)) {
      setExceptionPath(true);
    } else {
      setExceptionPath(false);
    }
  }, [firstPath]);

  const showChatButton = React.useMemo(
    () =>
      !isExceptionPath &&
      user?.isEmployee &&
      !!Object.keys(homeData || {}).length,
    [isExceptionPath, homeData],
  );
  React.useEffect(() => {
    // only for tracking event
    if (showChatButton && homeData) {
      const key = Object.keys(homeData || {})[0];

      updateChatWindowSettings({
        initialProvider: homeData[key],
        viewMode: window.innerWidth > 768 ? 'full-view' : 'chat-view',
      });
      track(EventNames.userChatScreen, {
        eventAction: EventActions.load,
        eventCategory: EventCategories.userChatScreen,
        userType: Analytics.getUserType(),
      });
    }
  }, [showChatButton, window.innerWidth]);

  return (
    <div className="layout-container" ref={parentRef}>
      <LayoutContext.Provider value={contextValues}>
        {show && (
          <FloatingChatWindowV2
            initialProvider={initialProvider}
            viewMode={viewMode}
          />
        )}
        {!isExceptionPath && <Header />}
        {showChatButton && <ChatFloatingButton coachHomeData={homeData} />}
        <div
          className={`main-content ${isExceptionPath ? 'no-page-margin' : ''}`}
        >
          {showFilter && <FilterSection />}
          {children}
        </div>
      </LayoutContext.Provider>
    </div>
  );
}

export default Layout;
