import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './OnsiteUpcomingSession.scss';
import { getTimeZone, OnsiteDateFormat } from '@utilities/common/Date';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@widgets/Typography';
import { IOnsiteMeetingResponse } from '@hooks/useOnsiteData/types';
import useTracking from '@hooks/useTracking';
import { EventActions, EventCategories, EventNames } from '@constants/analytics';
import Analytics from '@services/Analytics';
import SessionCard from '../../SessionManagement/SessionCard';
import OnsiteBookCancelPopUp from '../../OnsiteBookCancelPopUp/OnsiteBookCancelPopUp';

interface IOnsiteUpcomingSessionProps {
  meetingDetails: IOnsiteMeetingResponse['meetings'];
  phoneNumber?: string;
  onReschedule?: () => void;
}

export default function OnsiteUpcomingSessions({
  meetingDetails,
  phoneNumber,
  onReschedule,
}: IOnsiteUpcomingSessionProps) {
  const navigate = useNavigate();
  const { track } = useTracking();
  const [showOnsiteModal, setShowOnsiteModal] = React.useState<boolean>(false);

  const meetings = meetingDetails.sort((a, b) =>
    moment
      .unix(Number(a.scheduledStartTime))
      .diff(moment.unix(Number(b.scheduledStartTime))),
  );

  const counsellingStartTiming = React.useMemo(
    () =>
      `${moment
        .unix(Number(meetings[0].scheduledStartTime))
        .format(OnsiteDateFormat)} | ${moment
        .unix(Number(meetings[0].scheduledStartTime))
        .tz(getTimeZone())
        .format('hh:mm A z')} onwards`,
    [meetings],
  );

  return (
    <section className="onsite-upcoming-session-wrapper">
      <section className="header">
        <section className="left-content">
          <Typography size={24} weight="400">
            Upcoming Sessions
          </Typography>
        </section>
        <section
          className="right-content"
          role="button"
          tabIndex={0}
          onClick={() => navigate('/bookings')}
        >
          <Typography size={20} weight="500" withColor="#1C8BF1">
            View all
            <FontAwesomeIcon
              icon={faChevronRight}
              size="sm"
              style={{ paddingLeft: '5px' }}
            />
          </Typography>
        </section>
      </section>
      {meetings && meetings?.length >= 1 && (
        <SessionCard
          locationInfo={meetings[0]?.location}
          key={meetings[0].id}
          providerDetails={meetings[0].provider.profile}
          bookingType={meetings[0].providerRole}
          scheduledStartTime={meetings[0].scheduledStartTime}
          scheduledEndTime={meetings[0].scheduledEndTime}
          sessionId={meetings[0].id}
          kebabMenuOptions={[
            {
              label: 'Edit Session',
              onClick: () => setShowOnsiteModal(true),
              value: 'edit',
            },
          ]}
          handleSessionCardClick={() =>{
            track(EventNames.onsiteSessions, {
              eventAction: EventActions.click,
              eventCategory: EventCategories.viewDetails,
              eventLabel: 'view_details',
              userType: Analytics.getUserType(),
              eventSource: 'onsite_events_listing_page',
            });
            navigate(`/onsite-days/${meetings[0].onsiteId.toString()}`);
          }
            
          }
        />
      )}
      {showOnsiteModal && (
        <OnsiteBookCancelPopUp
          eventId={meetings[0].onsiteId.toString()}
          counsellingStartTiming={counsellingStartTiming}
          counsellingLocationInfo={`${meetings[0]?.location?.address}, ${meetings[0]?.location?.city}`}
          providerId={meetings[0].providerId}
          isRescheduleFlow
          meetingId={meetings[0].id}
          phoneNumber={phoneNumber as string}
          providerName={meetings[0].provider?.profile?.name as string}
          onclose={() => {
            onReschedule?.();
            setShowOnsiteModal(false);
          }}
        />
      )}
    </section>
  );
}
