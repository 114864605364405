/* eslint-disable */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './CounselingCard.scss';
import Typography from '@widgets/Typography';
import Button from '@widgets/Button';
import Banner from '@widgets/Banner';
import illustration from '../../../../assets/Img.svg';
import { ICounselingCardProps } from './types';
import IconText from '@components/IconText';
import calendarIcon from '../../../../assets/onsite-calendar.svg';

export default function CounselingCard({
  providerName,
  onClickHandler,
  slots,
  isSessionBooked = false,
  eventStartTime,
  eventEndTime,
  disabled,
  showCTA,
}: ICounselingCardProps) {
  const { t } = useTranslation();
  const getCtaLabel = React.useMemo(() =>{
    if(isSessionBooked){
      return 'Edit Session'
    }
    if(slots?.length === 0){
      return 'Explore more events'
    }
    return 'Book Now'
  },[isSessionBooked, slots])
  return (
    <section className="counseling-card-wrapper">
      <section className="content">
        <img src={illustration} alt="illustration" />
        <section className="right-wrapper">
          <section className="counseling-session">
            <Typography
              size={24}
              weight="600"
            >{`1:1 Counselling with ${providerName}`}</Typography>
            {isSessionBooked && (
              <IconText
                icon={calendarIcon}
                text={`${eventStartTime} - ${eventEndTime}`}
                fontColor="#1570EF"
                iconHeight="16px"
                iconWidth="16px"
                textFontSize={16}
              />
            )}
            <Typography
              size={14}
              lineHeight="160%"
              weight="400"
              withColor="#6c6d6e"
            >
              {t('COUNSELING_SUBTEXT')}
            </Typography>
          </section>
          {showCTA && <Button
            label={getCtaLabel}
            onClick={onClickHandler}
            variant={getCtaLabel === 'Edit Session'  ? 'secondary' : 'blue'}
            disabled={disabled}
          />}
        </section>
      </section>
      <section className="divider" />
      <section className="slots-wrapper">
        {slots && slots.length > 0 ? (
          <>
            <Typography size={16} weight="400" withColor="#6c6d6e">
              Available slots:
            </Typography>
            <section className="slots-container">
              {slots?.map((item) => (
                <section
                  className={`slots ${item.disabled ? 'disabled' : ''}`}
                  key={item.value}
                >
                  <Typography size={14} weight="400" withColor="#0A0C0E">
                    {item.label}
                  </Typography>
                </section>
              ))}
            </section>
          </>
        ) : (
          <Banner bannerText={t('NO_AVAILABLE_SLOTS')} />
        )}
      </section>
    </section>
  );
}
