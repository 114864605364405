import useAuth from '@hooks/useAuth';
import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import Loader from '@widgets/Loader';
import {
  getTikTokOrganizationIDforConsent,
  hasValidProfileData,
} from '@utilities/common/user';
import useProfileDetails from './view/screens/Provider/Home/hooks/useProfileDetails';
import { useConsentCheck } from './view/screens/OnboardingForm/hooks/useConsent';

export default function ConsentCheckRoutes() {
  const { user } = useAuth();
  const { data: profileData, isLoading: isLoadingProfile } = useProfileDetails({
    refetchOnWindowFocus: false,
  });
  const { data: consentStatus, isLoading: isCheckingConsent } = useConsentCheck(
    {
      email: user.emailOriginal,
      organisationId: getTikTokOrganizationIDforConsent(),
    },
    true,
  );
  const {
    currentUserData: { currentUserConfig },
  } = useCurrentUserContext();
  const hasUserConsented = () => consentStatus && !consentStatus.consent;

  const hasUserNotSelectedTeam = user.memberships.some(
    (membership) => membership.status === '4',
  );

  const isConsentCheck = React.useMemo(() => {
    if (
      profileData &&
      consentStatus &&
      user.isEmployee &&
      currentUserConfig?.isCare &&
      (!hasUserConsented() || !hasValidProfileData(profileData))
    ) {
      return false;
    }
    return true;
  }, [profileData, consentStatus, currentUserConfig]);

  if (isCheckingConsent || isLoadingProfile) {
    return <Loader />;
  }
  return isConsentCheck && !hasUserNotSelectedTeam ? (
    <Outlet />
  ) : (
    <Navigate to="/onboarding" />
  );
}
