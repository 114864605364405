import * as React from 'react';
import './Carousel.scss';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleChevronRight,
  faCircleChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import Typography from '@widgets/Typography';
import { ICarousel } from './types';

function Carousel({
  carouselItem,
  slidesToScroll = 1,
  slidesToShow = 1,
  title,
  id,
}: ICarousel) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + slidesToScroll >= carouselItem.length
        ? 0
        : prevIndex + slidesToScroll,
    );
  };
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - slidesToScroll < 0
        ? carouselItem.length - 1
        : prevIndex - slidesToScroll,
    );
  };

  const isRightArrowDisabled = React.useMemo(
    () => currentIndex + slidesToShow >= carouselItem.length,
    [currentIndex, carouselItem],
  );
  return (
    <article className="carousel-wrapper" id={id}>
      <section className="carousel-header">
        {title && (
          <Typography size={24} weight="500">
            {title}
          </Typography>
        )}
        <section className="carousel-controls">
          <motion.button
            className="control-item"
            whileHover={{ scale: currentIndex ? 1.1 : 1 }}
            whileTap={{ scale: currentIndex ? 0.9 : 1 }}
            onClick={handlePrevious}
            disabled={!currentIndex}
          >
            <FontAwesomeIcon
              icon={faCircleChevronLeft}
              className={`icons ${!currentIndex && 'disabledIcon'}`}
            />
          </motion.button>
          <motion.button
            className="control-item"
            whileHover={{ scale: isRightArrowDisabled ? 1 : 1.1 }}
            whileTap={{ scale: isRightArrowDisabled ? 1 : 0.9 }}
            onClick={handleNext}
            disabled={isRightArrowDisabled}
          >
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              className={`icons ${isRightArrowDisabled && 'disabledIcon'}`}
            />
          </motion.button>
        </section>
      </section>
      <section className="carousel-item">
        {carouselItem.slice(currentIndex, currentIndex + slidesToShow)}
      </section>
    </article>
  );
}

export default Carousel;
