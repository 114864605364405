import * as React from 'react';
import './Typography.scss';
import { CssSize } from '@widgets/ClickableCard/types';

export type FontWeights = '400' | '500' | '600' | '700';
export type FontColors =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'solarized'
  | 'light'
  | 'disabled'
  | 'trout'
  | 'blackRiverFall'
  | 'swamp'
  | 'squidInk';

interface ITypographyProps {
  children: React.ReactNode;
  size: number;
  color?: FontColors;
  weight: FontWeights;
  overFlowControl?: 'ellipsis' | 'clip';
  withColor?: string | null; // * to override theme colors for specific use cases
  breakWord?: 'break-all' | 'keep-all';
  textAlign?: 'left' | 'center' | 'right';
  withItalics?: boolean;
  withUnderline?: boolean;
  display?: 'inline' | 'block' | '-webkit-box';
  width?: string;
  lineHeight?: CssSize;
  additionalStyles?: React.CSSProperties; 
}

// TODO line height logic
// TODO display type -> inline, block, inline-block etc.
// TODO check performance whether the values need to memoized

function Typography({
  children,
  overFlowControl,
  withColor,
  textAlign,
  breakWord = 'keep-all',
  weight = '400',
  size = 14,
  color = 'primary',
  withItalics = false,
  withUnderline = false,
  display = 'block',
  width,
  lineHeight,
  additionalStyles,
}: ITypographyProps) {
  const buildStyle = () => {
    const styles: React.CSSProperties = {
      fontWeight: weight,
      fontSize: `${(window.innerWidth < 1000 ? size * 0.9 : size) / 16}rem`,
    };

    if (overFlowControl) {
      styles.textOverflow = overFlowControl;
      styles.overflow = 'hidden';
    }

    if (withColor) {
      styles.color = withColor;
    }

    if (breakWord) {
      styles.wordBreak = breakWord;
    }

    if (textAlign) {
      styles.textAlign = textAlign;
    }

    if (withItalics) {
      styles.fontStyle = 'italic';
    }

    if (withUnderline) {
      styles.textDecoration = 'underline';
    }

    if (display) {
      styles.display = display;
    }

    if (width) {
      styles.width = width;
    }
    if (lineHeight) {
      styles.lineHeight = lineHeight;
    }
    if (additionalStyles) {
      return { ...styles, ...additionalStyles };
    }
    return styles;
  };
  return (
    <span style={buildStyle()} className={`typography ${color}`}>
      {children}
    </span>
  );
}

export default Typography;
