import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Typography from '@widgets/Typography';
import { OnsiteSessionCancellationReasonsEnum } from '@constants/cancellation';
import ClickableCard from '@widgets/ClickableCard';
import Button from '@widgets/Button';
import './Reasons.scss';

interface ICancellationReasons {
  header?: string;
  subHeader?: string;
  handleContinueClick: (data: string) => void;
  onOtherReason: (value: string) => void;
}
interface ISelectedReason {
  reasonCode: string;
  reasonDesc: string;
}

function OnsiteCancellationReasons({
  header,
  subHeader,
  handleContinueClick,
  onOtherReason,
}: ICancellationReasons) {
  const { t } = useTranslation();

  const [selectedReason, setSelectedReason] = React.useState<ISelectedReason>({
    reasonCode: '',
    reasonDesc: '',
  });
  const [otherReasonValue, setOtherReasonValue] = React.useState<string>('');
  const handleReasonCLick = (data: ISelectedReason) =>
    setSelectedReason({
      reasonCode: data.reasonCode,
      reasonDesc: data.reasonDesc,
    });
  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onOtherReason(e.target.value);
    setOtherReasonValue(e.target.value);
  };
  const isSelectedReasonEmpty = Object.values(selectedReason).every(
    (item) => item === '',
  );

  return (
    <article className="cancellation-wrappper">
      <section className="cancellation-header">
        <Typography size={22} weight="600" withColor="#101828">
          {header}
        </Typography>
        <Typography size={16} color="secondary" weight="400">
          {subHeader}
        </Typography>
      </section>
      <section className="cancellation-body">
        {Object.keys(OnsiteSessionCancellationReasonsEnum).map((reason) => (
          <ClickableCard
            content={t(reason)}
            selected={
              selectedReason.reasonCode ===
              OnsiteSessionCancellationReasonsEnum[reason]
            }
            cardContentWidth="100%"
            onClick={() =>
              handleReasonCLick({
                reasonCode: OnsiteSessionCancellationReasonsEnum?.[reason],
                reasonDesc: reason,
              })
            }
            uniqueKey={reason}
            borderRadius="48px"
            height="48px"
            fontSize={16}
            key={OnsiteSessionCancellationReasonsEnum?.[reason]}
          />
        ))}
        {selectedReason.reasonCode ===
          OnsiteSessionCancellationReasonsEnum.CREDIT_REASON_OTHERS && (
          <motion.textarea
            onChange={onInputChange}
            placeholder={t('OTHERS_REASON_SUBTEXT')}
            className="other-reason-input"
            initial={{ y: -20, opacity: 0 }}
            value={otherReasonValue}
            animate={{
              y: 0,
              opacity: 1,
              transition: { duration: 0.4 },
            }}
            exit={{
              y: -20,
              opacity: 0,
              transition: { duration: 0.6 },
            }}
          />
        )}
        <Button
          label={t('CONTINUE')}
          variant="blue"
          onClick={() => handleContinueClick(selectedReason?.reasonCode)}
          width="100%"
          height="48px"
          fontSize={14}
          disabled={
            isSelectedReasonEmpty ||
            (selectedReason.reasonCode ===
              OnsiteSessionCancellationReasonsEnum.CREDIT_REASON_OTHERS &&
              !otherReasonValue.replace(/\s/g, '').length)
          }
        />
      </section>
    </article>
  );
}

export default OnsiteCancellationReasons;
