/* eslint-disable */
import * as React from 'react';
import Typography from '@widgets/Typography';
import './TextMessageItemV2.scss';

interface ITextMessageItemProps {
  message: string;
  source: string;
}

function TextMessageItem({ message, source }: ITextMessageItemProps) {
  function linkify(text: string) {
    let urlRegex =
      /\b((https?|ftp|file):\/\/|(www|ftp)\.)[-A-Z0-9+&@#\/%?=~_|$!:,.;]*[A-Z0-9+&@#\/%=~_|$]/ig;
    return text.replace(urlRegex, function (url) {
      return `<a href='${url}' target='_blank'> ${url} </a> `;
    });
  }

  return (
    <Typography
      weight="400"
      size={12}
      withColor={source === 'outbound' ? '#fff' : '#48515A'}
      breakWord="break-all"
    >
      {/* eslint-disable-next-line */}
      <div dangerouslySetInnerHTML={{ __html: linkify(message) }} className={source === 'outbound'? 'outbound-links' : 'inbound-links'}/> 
    </Typography>
  );
}

export default TextMessageItem;
