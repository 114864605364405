import * as React from 'react';
import './ChatFloatingButton.scss';
import useTracking from '@hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import { motion } from 'framer-motion';
import Typography from '@widgets/Typography';
import { getUnreadMessagesCountForFloatingChatButton } from '@utilities/common/Display';
import useLayout from '@utilities/hooks/useLayout';
import { CoachHome } from '@customTypes/response/IChat';
import { useChatBroker } from '../../broker/useChatBroker';
import messageIcon from '../../assets/message.svg';

function ChatFloatingButton({ coachHomeData }: { coachHomeData?: CoachHome }) {
  const { track } = useTracking();
  const { unreadCount, refetchConversationList, refetchLatestConversation } =
    useChatBroker();

  const { updateChatWindowSettings } = useLayout();

  React.useEffect(() => {
    refetchConversationList();
    refetchLatestConversation();
  }, [Object.keys(coachHomeData || {}).length]);

  return (
    <motion.div
      className="floating-chat-button"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={() => {
        updateChatWindowSettings({ show: true });
        track(EventNames.chatScreen, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.chatScreen,
          userType: Analytics.getUserType(),
        });
      }}
    >
      <div className="floating-contents-container">
        <img src={messageIcon} alt="messages" />
        <div className="message-dots">
          <div className="message-dot" />
          <div className="message-dot" />
          <div className="message-dot" />
        </div>
        {!!unreadCount && (
          <motion.div
            className="unread-messages-count"
            animate={{ scale: 1.1 }}
            transition={{ delay: 1, yoyo: Infinity }}
          >
            <Typography weight="600" size={10} color="light">
              {getUnreadMessagesCountForFloatingChatButton(unreadCount)}
            </Typography>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
}

export default ChatFloatingButton;
