import * as React from 'react';
import Typography from '@widgets/Typography';
import { useTranslation } from 'react-i18next';
import Popup from '@widgets/Popup';
import useAuth from '@hooks/useAuth';
import { tollFreeMsgs } from '@constants/sos';
import infoIcon from '../../../../../assets/info-circle.svg';
import './ChatHelplinePopup.scss';

interface ChatHelplinePopupProps {
  onClose: () => void;
}

function ChatHelplinePopup({ onClose }: ChatHelplinePopupProps) {
  const { t } = useTranslation();

  const { user } = useAuth();

  return (
    <Popup onClose={() => onClose()} allowBackdropClickClose>
      <div className="chat-helpline-container">
        <div className="chat-helpline-header">
          <Typography size={28} weight="400">
            {t('CONNECT_WITH_EAP')}
          </Typography>
        </div>
        <div className="chat-helpline-items">
          {user.config.eapHelpLine?.split(',').map((helpline: string) => {
            const [country, number] = helpline.split(':');
            return (
              <div className="chat-helpline-item" key={country + number}>
                <Typography size={24} weight="700">
                  {t(country)}
                </Typography>
                <Typography size={24} weight="400">
                  {number}
                </Typography>
                <div>
                  {number in tollFreeMsgs ? (
                    <section className="chat-toll-free-info-container">
                      <img src={infoIcon} alt="info-icon" />
                      <Typography size={14} weight="400" color="trout">
                        {t(tollFreeMsgs[number])}
                      </Typography>
                    </section>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Popup>
  );
}

export default ChatHelplinePopup;
