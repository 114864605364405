import { AllProvidersCountryId } from '@constants/provider';
import { FetchKeys } from '@constants/queries';
import { IProviderCountry } from '@customTypes/response/provider';
import { useFetch } from '@hooks/useFetch';
import { getProviderCountries as getProviderCountriesList } from '@services/provider';

function useGetProviderCountriesList(
  userId: string,
  params: { role: string[]; engagement: string },
  enabled: boolean = true,
) {
  return useFetch<IProviderCountry[] | undefined>(
    [FetchKeys.providerCountriesList, userId, params.role, params.engagement],
    () => getProviderCountriesList(userId, params),
    {
      select(data) {
        return [
          {
            countryId: AllProvidersCountryId,
            countryName: 'All Provider Countries',
          },
          ...(data ?? []),
        ];
      },
      refetchOnWindowFocus:false,
      enabled,
    },
  );
}

export default useGetProviderCountriesList;
