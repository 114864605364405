/* eslint-disable */
import _ from 'lodash';
import IrisClient from '../client/IrisClient';
import TwilioClient from '../client/TwilioClient';
import { MessageChannelTypes } from '../constants/CommonConstants';
import {
  IConversationsMetadata,
  ConversationListDataType,
  SelectedUserMessagesType,
  IConversations,
} from '../data-types/ClientTypes';
import { getConversationsDetails } from '../services/API/CommonConversation';
import {
  AllConversationListType,
  IMessageSendType,
  IOnNewMessage,
  ListByDateType,
} from '../data-types/ChatBrokerTypes';

export default class ChatBroker {
  private chatClient;

  public conversationList: IConversationsMetadata['userData'];

  private token: string;

  public unreadCount: number;

  public userMessages: SelectedUserMessagesType;

  private selectedConversationId: string;

  private _finalConversationList: AllConversationListType[];

  constructor() {
    this.chatClient = {
      [MessageChannelTypes.IRIS]: new IrisClient(),
      [MessageChannelTypes.TWILIO]: new TwilioClient(),
    };
    this.conversationList = [];
    this.token = '';
    this.unreadCount = 0;
    this.userMessages = {} as SelectedUserMessagesType;
    this.selectedConversationId = '';
    this._finalConversationList = [] as AllConversationListType[];
    this.getConversationList = this.getConversationList.bind(this);
    this.getAllConversations = this.getAllConversations.bind(this);
    this.getSelectedUserUnreadCount = this.getSelectedUserUnreadCount.bind(this);
    this.updateMessageConsumptionStatus = this.updateMessageConsumptionStatus.bind(this);
  }

  // merge last msg data with meta data along with sorting

  private _appendMessageToConversation(messageList: ConversationListDataType[]): AllConversationListType[] {
    this.unreadCount = 0;
    const combinedList = this.conversationList.map((conversation) => {
      const filteredMessageList = messageList.filter(
        (message) => conversation.conversationId === message.conversationId,
      );
      if (filteredMessageList.length === 0) {
        return [] as AllConversationListType[];
      }

      const filteredMessage = filteredMessageList[0];
      this.unreadCount += filteredMessage.unreadCount || 0;
      return {
        ...conversation,
        lastMessage: filteredMessage.lastMessageContent,
        lastMessageDate: filteredMessage.lastMsgCreatedAt,
        unreadCount: filteredMessage.unreadCount || 0,
        attachmentType:filteredMessage.attachmentType
      } as AllConversationListType;
    });
    const sortedList = combinedList
      .filter((item): item is AllConversationListType => !_.isEmpty(item))
      .sort((a, b) => Number(b.lastMessageDate) - Number(a.lastMessageDate));
    return sortedList;
  }

  // for separating metadata based on channel type

  private _createChannelWiseData() {
    const registeredChannel = Object.keys(this.chatClient);
    const channelConversationData = Object.fromEntries(
      registeredChannel.map((key) => {
        const tempArray: IConversationsMetadata['userData'] = [];
        return [key, tempArray];
      }),
    );
    this.conversationList.forEach((conversation) => {
      channelConversationData[conversation.channelType].push(conversation);
    });
    return channelConversationData;
  }

  //  initial conversation api call

  public async getConversationList() {
    const resp: IConversations = await getConversationsDetails();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.token = resp?.data['0']?.chatTokens['0'] || '';
    this.conversationList = Object.values(resp.data).reduce((result: any[], connect: any) => {
      result.push(
        ...connect.conversation.map((conversation: any) => ({
          channelId: conversation?.channelExternalId,
          conversationId: conversation?.conversationId,
          userId: conversation?.userId, // sender Id
          // friendlyName: conversation?.user?.friendlyName,
          providerName: connect.provider.name,
          providerImage: connect.provider.profileImage,
          providerLeave: connect.leave,
          providerRole: conversation?.providerRole,
          channelType: conversation?.channelType as MessageChannelTypes,
          providerId: conversation?.providerId,
        })),
      );
      return result;
    }, []);
    return resp;
  }

  // listening to new messages on socket & appending it to current message list

  public onSocketNewMessage(callBack: IOnNewMessage): void {
    Object.values(this.chatClient).forEach((client) => {
      client.onSocketNewMessage((message) => {
        if (!!this.selectedConversationId && message.conversationId === this.selectedConversationId) {
          const isMsgExist = !!this.userMessages.messages.filter(msg=>msg.id===message.id).length
          if(!isMsgExist){
          this.userMessages.messages = [message, ...this.userMessages.messages];
          }
        }
        callBack(message);
      });
    });
  }

  // getting latest messages of twilio & iris and then merge those list into 1 single list

  async getAllConversations(): Promise<ListByDateType> {
    const channelConversationData = this._createChannelWiseData();
    const promises = Object.entries(this.chatClient).map(async (item) => {
      const [channelType, client] = item;
      return client.getConversationList(channelConversationData[channelType]);
    });

    const data = await Promise.all(promises);
    const conversationList: ConversationListDataType[] = _.flatten(data);
    const finalConversationList = this._appendMessageToConversation(conversationList);

    this._finalConversationList = finalConversationList;
    return {
      active: finalConversationList,
      oldChat: [],
    };
  }

  // getting messages of selected user

  async getSelectedUserMessages(
    selectedConversationId: string,
    channelId: string,
    pageNo: number,
    channelType: MessageChannelTypes,
    refetch = false,
  ): Promise<SelectedUserMessagesType> {
    if (refetch) {
      return this.userMessages;
    }
    this.selectedConversationId = selectedConversationId;
    if (channelType === MessageChannelTypes.TWILIO) {
      this.selectedConversationId = channelId;
    }
    const resp = await this.chatClient?.[channelType]?.getSelectedUserMessages(this.selectedConversationId, pageNo);

    this.userMessages = resp;
    return resp;
  }

  // todo implementation pending for msg consumption status
  /* eslint-disable */
  public async updateMessageConsumptionStatus(userId: string, channelType: MessageChannelTypes) {
    await this.chatClient?.[channelType]?.updateMessageConsumptionStatus(userId);
    return userId;
  }

  public async sendMessage(contentToSend: IMessageSendType, conversationId: string, channelType: MessageChannelTypes) {
    const resp = await this.chatClient[channelType].sendMessage(contentToSend, conversationId);

    return resp;
  }

  public getSelectedUserUnreadCount(selectedConversationId: string): number {
    /* Get Unread count of selected user  */

    if (this._finalConversationList.length) {
      const [filter] = this._finalConversationList?.filter(
        (conversation) => conversation.conversationId === selectedConversationId,
      );
      return filter.unreadCount;
    }
    return 0;
  }
}
