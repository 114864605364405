import * as React from 'react';
import { EventActions, EventCategories, EventNames } from '@constants/analytics';
import Analytics from '@services/Analytics';
import useTracking from '@hooks/useTracking';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import './DocumentMessageItem.scss';
import Typography from '@widgets/Typography';
import Loader from '@widgets/Loader';
import TextMessageItem from '../TextMessageItemV2';
import pdfIcon from '../../assets/failedPDF.svg';

interface IDocumentmsgProps {
  docUrl: string;
  docName: string;
  body?: string;
  source: string;
}

function DocumentMessageItem({
  docUrl,
  docName,
  body,
  source,
}: IDocumentmsgProps) {
  const { track } = useTracking();
  const [numOfPages, setNumOfPages] = React.useState<number>();
  const ref = React.useRef<HTMLCanvasElement>(null);

  const handleDocClick = () => {
    track(EventNames.messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.clickAttachment,
      eventLabel: 'pdf',
      userType: Analytics.getUserType(),
    });
    window.open(docUrl, '_blank', 'noreferrer');
  };

  return (
    <section className={`document-wrapper ${source}`}>
      <figure className={`figure-wrapper ${source}`} onClick={handleDocClick}>
        <Document
          className="pdf-thumbnail"
          file={docUrl}
          onLoadSuccess={({ numPages }: { numPages: number }) =>
            setNumOfPages(numPages)
          }
          loading={
            <Loader
              useFullPage={false}
              dimension={{ height: 112, width: 180 } as DOMRect}
            />
          }
          error={<img className="fallback-pdf" src={pdfIcon} alt="pdf fallback icon"/>}
        >
          <Page
            width={180}
            canvasRef={ref}
            pageNumber={1}
            onLoadSuccess={() => {
              if (ref.current) {
                ref.current.style.height = '112px';
                ref.current.style.borderRadius = '8px 8px 0 0';
                ref.current.style.width = '190px';
              }
            }}
          />
        </Document>
        <section className="doc-details">
          <Typography
            size={10}
            weight="600"
            withColor={source === 'outbound' ? '#fff' : '#48515A'}
          >
            {docName}
          </Typography>
          {!!numOfPages && <Typography
            size={8}
            withColor={source === 'outbound' ? '#fff' : '#48515A'}
            weight="400"
          >
            {numOfPages} {numOfPages === 1 ? 'page' : 'pages'} · PDF
          </Typography>}
        </section>
      </figure>
      {!!body && (
        <section className="attached-msg">
          <TextMessageItem source={source} message={body} />
        </section>
      )}
    </section>
  );
}
export default DocumentMessageItem;
