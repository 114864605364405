import { isProduction } from '@utilities/common/Path';

export const DailyCoRoomUrls = {
  dev: 'https://alpha24.daily.co/',
  prod: 'https://intellect.daily.co/',
};

export function roomUrlFromPageUrl() {
  const match = window.location.search.match(/roomUrl=([^&]+)/i);
  return match && match[1] ? decodeURIComponent(match[1]) : null;
}

export function pageUrlFromRoomUrl(roomUrl?: string) {
  return (
    window.location.href.split('?')[0] + (roomUrl ? `?roomUrl=${encodeURIComponent(roomUrl)}` : '')
  );
}

export function getDailyCoUrlFromRoomId(roomId: string) {
  if (isProduction()) {
    return DailyCoRoomUrls.prod + roomId;
  }

  return DailyCoRoomUrls.dev + roomId;
}
