import { BookingEmployee } from '@customTypes/responder';
import { decodeWithBase64 } from './Encryption';

export const getEmployeeDataFromSearchParam = (encoded: string) => {
  try {
    const decodedEmployee = decodeWithBase64(decodeURI(encoded));
    const employeeData = JSON.parse(decodedEmployee) as BookingEmployee;
    if (employeeData.id && employeeData.email) {
      return employeeData;
    }
  } catch (e) {
    return false;
  }

  return false;
};
