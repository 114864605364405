import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';
import { IUser } from '@customTypes/response/IUser';
import { getItemFromLocalStorage } from '@utilities/common/Storage';
import { StorageItems } from '@constants/App';
import { isProduction } from './Path';

const APP_DSN =
  'https://b770bb7d98f9488bbb9aa9ed097800d9@o318413.ingest.sentry.io/4504479077761024';

export const initializeMonitoring = () => {
  const user = getItemFromLocalStorage(
    StorageItems.USER_INFO,
    'object',
  ) as IUser;
  const integrations: Integration[] = [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      maskAllInputs: true,
    }),
  ];
  if (user?.isResponder) {
    integrations.push(
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: 'system',
        isEmailRequired: true,
        isNameRequired: true,
      }),
    );
  }
  if (isProduction()) {
    Sentry.init({
      dsn: APP_DSN,
      integrations,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};

export const sendMonitoringEvent = (error: any) => {
  Sentry.captureException(error, (scope => {
    const user: IUser = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    ) as IUser;
    scope.setUser({ id: String(user.id) });
    scope.setExtras(error?.response?.data);
    return scope;
  }));
};
