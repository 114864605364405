import * as React from 'react';
import Typography from '@widgets/Typography';
import { FontWeights } from '@widgets/Typography/Typography';
import './IconText.scss';
import { CssSize } from '@widgets/ClickableCard/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { openExternalUrl } from '@utilities/common/Path';

interface IconTextProps {
  icon: string;
  text: string;
  className?: string;
  textFontSize?: number;
  fontColor?: string;
  textFontWeight?: FontWeights;
  iconHeight?: CssSize;
  iconWidth?: CssSize;
  variant?: 'with-background' | 'normal';
  redirectUrl?: string;
  redirectIconColor?: string;
  textWidth?: CssSize;
}

function IconText({
  icon,
  text,
  className,
  textFontSize = 14,
  fontColor,
  textFontWeight = '400',
  iconHeight = '20px',
  iconWidth = '20px',
  variant = 'normal',
  redirectUrl,
  redirectIconColor,
  textWidth,
}: IconTextProps) {
  return (
    <section className={`icon-text ${className}`}>
      <img
        src={icon}
        alt="icon"
        className={`icon ${variant}`}
        style={{ height: iconHeight, width: iconWidth }}
      />
      <Typography
        size={textFontSize}
        withColor={fontColor}
        weight={textFontWeight}
        overFlowControl="ellipsis"
        additionalStyles={{
          whiteSpace: 'nowrap',
          width: textWidth,
        }}
      >
        {text}
      </Typography>
      {redirectUrl && (
        <FontAwesomeIcon
          icon={faExternalLinkAlt}
          style={{ color: redirectIconColor, cursor: 'pointer' }}
          size="sm"
          onClick={() => openExternalUrl(redirectUrl)} // todo chk with BE if there will be any room redirection link also
        />
      )}
    </section>
  );
}

export default IconText;
