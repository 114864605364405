// * keeping a separate file for query keys to keep consistent queries and avoid cache duplications

export enum FetchKeys {
  providerMatchConfig = 'provider-match-config',
  providerList = 'provider-list',
  providerDetails = 'provider-details',
  userMeetingDetails = 'user-meeting',
  meetingJoinDetails = 'meeting-join',
  providerHome = 'provider-home',
  userDetails = 'user-details',
  profileDetails = 'profile-details',
  employeeDetails = 'employee-details',
  providerCountriesList = 'provider-countries-list',
  EmployeeConsentData = 'employee-consent-data',
  providerServices = 'provider-services',
  roomsList = 'rooms-list',
  organisationTeams = 'organisation-teams',
}
