import * as React from 'react';
import OnsiteSessionSchedule from '@components/OnsiteSessionSchedule';
import CancellationModal from '@components/OnsiteSessionSchedule/SessionCancellation';
import SuccessModal from '@components/OnsiteSessionSchedule/SuccessModal';
import { OnsiteModal } from '@components/OnsiteSessionSchedule/constants';
import { IOnsiteSessionScheduleForm } from '@components/OnsiteSessionSchedule/types';
import {
  useBookOnsiteEvent,
  useEditOnsiteSessions,
} from '@hooks/useOnsiteData/useOnsiteData';
import Loader from '@widgets/Loader';
import { EventActions, EventCategories, EventNames } from '@constants/analytics';
import Analytics from '@services/Analytics';
import useTracking from '@hooks/useTracking';

interface IOnsiteBookCancelPopUpProps {
  eventId: string;
  phoneNumber: string;
  counsellingLocationInfo: string;
  counsellingStartTiming: string;
  providerId?: string;
  isRescheduleFlow?: boolean;
  meetingId?: string;
  onclose: () => void;
  providerName: string;
  roomGuide?: string;
}

export default function OnsiteBookCancelPopUp({
  eventId,
  counsellingStartTiming,
  counsellingLocationInfo,
  providerId,
  isRescheduleFlow,
  meetingId,
  phoneNumber,
  onclose,
  providerName,
  roomGuide,
}: IOnsiteBookCancelPopUpProps) {
  const { track } = useTracking();
  const [currentOnsiteModal, setCurrentOnsiteModal] =
    React.useState<OnsiteModal>(OnsiteModal.bookingSession);
  const [upcomingSessionOn, setUpcomingSessionOn] = React.useState(null);

  const onClickSuccess = (onSuccessData: any) => {
    if (isRescheduleFlow) {
      setCurrentOnsiteModal(OnsiteModal.bookedSession);
      setUpcomingSessionOn(onSuccessData.meeting.scheduledStartTime);
    }
    setCurrentOnsiteModal(OnsiteModal.bookedSession);
    setUpcomingSessionOn(onSuccessData.meeting.scheduledStartTime);
  };
  const { rescheduleSession, isRescheduleSessionLoading } =
    useEditOnsiteSessions(onClickSuccess);
  const { scheduleOnsiteSession } = useBookOnsiteEvent(onClickSuccess);
  const handleSubmit = (data: IOnsiteSessionScheduleForm) => {
    track(
      isRescheduleFlow
        ? EventNames.rescheduleSession
        : EventNames.confirmSession,
      {
        eventAction: EventActions.click,
        eventCategory: isRescheduleFlow
          ? EventNames.rescheduleSession
          : EventCategories.cancelSession,
        eventLabel: isRescheduleFlow ? 'reschedule_session' : 'confirm_session',
        userType: Analytics.getUserType(),
        eventSource: 'onsite',
      },
    );
    if (isRescheduleFlow) {
      rescheduleSession({
        note: data?.additionalText,
        scheduledStartTime: data?.selectedSlot,
        meetingId,
      });
      return;
    }
    const bookingPayload = {
      scheduledStartTime: data?.selectedSlot,
      providerId,
      onsiteId: Number(eventId),
      note: data?.additionalText,
    };
    scheduleOnsiteSession(bookingPayload);
  };
  if (isRescheduleSessionLoading) {
    return <Loader />;
  }
  return (
    <section>
      {currentOnsiteModal === OnsiteModal.bookingSession && (
        <OnsiteSessionSchedule
          eventId={eventId as string}
          closeHandler={() => {setCurrentOnsiteModal(OnsiteModal.NoModal); onclose();}}
          eventLocation={counsellingLocationInfo}
          eventTimings={counsellingStartTiming}
          onSubmit={(data) => handleSubmit(data)}
          contactNumber={phoneNumber}
          isRescheduleFlow={isRescheduleFlow}
          handleCancelClick={() => {
            setCurrentOnsiteModal(OnsiteModal.cancellationModal);
            track(EventNames.cancelSession, {
              eventAction: EventActions.click,
              eventCategory: EventCategories.cancelSession,
              eventLabel: 'cancel_session',
              userType: Analytics.getUserType(),
              eventSource: 'onsite',
            });
          }}
          roomGuide={roomGuide}
        />
      )}
      {currentOnsiteModal === OnsiteModal.bookedSession && (
        <SuccessModal
          onClose={() => {
            setCurrentOnsiteModal(OnsiteModal.NoModal);
            onclose();
          }}
          upcomingSessionDetails={upcomingSessionOn}
          isReschedule={isRescheduleFlow}
        />
      )}
      {currentOnsiteModal === OnsiteModal.cancellationModal && (
        <CancellationModal
          onClose={() => {
            setCurrentOnsiteModal(OnsiteModal.NoModal);
            onclose();
          }}
          providerName={providerName}
          meetingId={meetingId as string}
        />
      )}
    </section>
  );
}
