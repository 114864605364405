import * as React from 'react';
import './SelectList.scss';
import Typography from '@widgets/Typography';
import { motion } from 'framer-motion';
import { buttonVariants, listVariants } from '@animations/variants';

interface SelectListProps {
  selectedValue: string;
  options: {
    label: string;
    value: string;
  }[];
  onClick: (value: string) => void;
  emptyLabel: {
    show: boolean;
    label: string | JSX.Element;
  };
}

function SelectList({
  selectedValue,
  options,
  onClick,
  emptyLabel,
}: SelectListProps) {

  if (emptyLabel.show) {
    return (
      <Typography size={12} weight="400" color="secondary">
        {emptyLabel.label}
      </Typography>
    );
  }

  return (
    <div className="select-list-container">
      {options.map(({ label, value }, index) => (
        <motion.div
          variants={{ ...listVariants, ...buttonVariants }}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          whileTap="tap"
          custom={{ index, speed: '0.1' }}
          transition={{ duration: 0.1 }}
          key={value}
          className={`select-list-item ${
            selectedValue === value ? 'selected' : ''
          }`}
          role="button"
          tabIndex={index}
          onClick={() => onClick(value)}
        >
          <Typography size={14} weight="400">
            {label}
          </Typography>
        </motion.div>
      ))}
    </div>
  );
}

export default SelectList;
