import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@widgets/Typography';
import {
  StyledImageSection,
  StyledImg,
  StyledNoConversationContainer,
} from './NoConversations.styled';
import chatIcon from '../../assets/chatIcon.png';

function NoConversations() {
  const { t } = useTranslation();
  return (
    <StyledNoConversationContainer>
      <StyledImageSection>
        <StyledImg src={chatIcon} alt="chat icon" />
        <Typography weight="700" color="primary" size={20} >
          {t('selectMessage')}
        </Typography>
      </StyledImageSection>
    </StyledNoConversationContainer>
  );
}

export default NoConversations;
