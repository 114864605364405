export const ClientTiers = {
  LIMITED: '0',
  UNLIMITED: '1',
};

export const RecurringSessions = {
  constants: {
    MAX_BOOKABLE_SESSIONS_COUNT: 4,
    ONE_TIME_SESSION_CREDIT_COUNT: 1,
  },
  availableRecurringSessionOptions: {
    ONE_TIME: '1',
    WEEKLY: '2',
    BIWEEKLY: '3',
  },
};

export const TikTokOrganizationID = {
  STAGING: 53,
  PROD: 434,
};

export const REASON_TYPE_USER_ID = 0;
