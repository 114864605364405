import * as React from 'react';
import Typography from '@widgets/Typography';
import noHelplineRecordImg from '@assets/noHelplineRecordsImg.svg';
import './NoHelplineRecords.scss';

interface IHelplineRecords {
  illustration?: string;
  heading: string;
  subHeading: string;
}

export default function NoHelplineRecords({
  illustration = noHelplineRecordImg,
  heading,
  subHeading,
}: IHelplineRecords) {
  return (
    <section className="no-helpline-wrapper">
      <section className="no-helpline-image-wrapper">
        <img src={illustration} alt="no-results" />
      </section>
      <section className="no-helpline-content-wrapper">
        <Typography size={24} weight="700" textAlign="center">
          {heading}
        </Typography>
        <Typography size={14} weight="400" textAlign="center" withColor="#1a263180">
          {/* eslint-disable-next-line */}
        <div className="decription-wrapper" dangerouslySetInnerHTML={{ __html: subHeading }} />
        </Typography>
      </section>
    </section>
  );
}
