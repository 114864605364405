import { F2FSessionRequest } from '@customTypes/request/provider';
import NetworkClient from '@services/Network';
import { beautifyUrl } from '@utilities/common/Path';
import Config from '../config';

const ErrorMessages = {
  generic: 'Unable to perform the meeting action, please try again!',
};

const client = new NetworkClient();

export const getMeetingDetails = async (
  meetingId: string,
  meetingChannel: string,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.meeting.meetingDetails, [meetingId]),
    { channel: Number(meetingChannel), platform: '3' },
  );
  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};

export const requestF2FSession = async (request: F2FSessionRequest) => {
  const response = await client.doPost(
    Config.api.meeting.requestF2FSession,
    request,
  );

  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};

export const getRoomsList = async (locationId: string, meetingId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.getRoomsList, [locationId]),
    undefined,
    { meetingId },
  );
  if (response && response.success) {
    return response.data?.rooms || [];
  }
  throw new Error(response?.data?.error?.message);
};
