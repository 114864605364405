import io, { Socket } from 'socket.io-client';
import { StorageItems } from '@constants/App';
import { getItemFromLocalStorage } from '@utilities/common/Storage';
import { MessageContentTypes } from '../constants/CommonConstants';
import Config from '../../../../shared/config';

export interface SocketMessage {
  conversationId: string;
  id: string;
  message: string;
  senderId: number;
  attachments: [{ content: string; type: MessageContentTypes }];
}

export default class IrisSocket {
  socket: Socket | undefined;

  constructor() {
    const authToken: any = getItemFromLocalStorage(StorageItems.USER_INFO, 'object');
    this.socket = io(Config.webSocket, {
      transports: ['websocket'],
      auth: {
        token: authToken.token,
      },
    });
  }

  public isSocketConnected() {
    if (this.socket !== undefined) {
      return true;
    }
    return false;
  }

  public addSocketListener = (listenerName: string, callbackFn: (message: SocketMessage) => void) => {
    this.socket!.on(listenerName, (message: SocketMessage) => {
      callbackFn(message);
    });
  };

  public emitToServer = (name: string, data: any) => {
    this.socket!.emit(name, data);
  };
}
