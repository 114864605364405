import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from './locales/en.json';
import spanishTranslations from './locales/es_MX.json';
import hindiTranslations from './locales/hi.json';
import indonesiaTranslations from './locales/id.json';
import japaneseTranslations from './locales/ja.json';
import koreanTranslations from './locales/ko.json';
import malaysianTranslations from './locales/ms.json';
import thaiTranslations from './locales/th.json';
import tagalogTranslations from './locales/tl.json';
import vietnameseTranslations from './locales/vi.json';
import yueTranslations from './locales/yue.json';
import chineseSimplifiedTranslations from './locales/zh_CN.json';
import hongKongChineseTranslations from './locales/zh_Hant_HK.json';
import taiwanChineseTranslations from './locales/zh_Hant_TW.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: englishTranslations,
    },
    esM: {
      translations: spanishTranslations,
    },
    hi: {
      translations: hindiTranslations,
    },
    id: {
      translations: indonesiaTranslations,
    },
    ja: {
      translations: japaneseTranslations,
    },
    ko: {
      translations: koreanTranslations,
    },
    ms: {
      translations: malaysianTranslations,
    },
    th: {
      translations: thaiTranslations,
    },
    tl: {
      translations: tagalogTranslations,
    },
    vi: {
      translations: vietnameseTranslations,
    },
    yue: {
      translations: yueTranslations,
    },
    zhC: {
      translations: chineseSimplifiedTranslations,
    },
    zhH: {
      translations: hongKongChineseTranslations,
    },
    zhT: {
      translations: taiwanChineseTranslations,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = [
  'en',
  'esM',
  'hi',
  'id',
  'ja',
  'ko',
  'ms',
  'th',
  'tl',
  'vi',
  'yue',
  'zhC',
  'zhH',
  'zhT',
];

export default i18n;
