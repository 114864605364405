import React from 'react';
import ReactDOM from 'react-dom';
import { FetchProvider } from '@hooks/useFetch';
import Analytics from '@services/Analytics';
import { initializeMonitoring } from '@utilities/common/monitoring';
import './index.css';
import { isProduction } from '@utilities/common/Path';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeLocalization, updateAppLanguage } from './i18n';

initializeLocalization();
initializeMonitoring();
updateAppLanguage();
Analytics.initialize();

if (isProduction()) {
  console.log = () => {};
  console.warn = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <FetchProvider>
      <App />
    </FetchProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
