import Typography, { FontColors } from '@widgets/Typography';
import * as React from 'react';
import './CheckBox.scss';

interface CheckBoxProps {
  isChecked?: boolean;
  onChange?: (isChecked: boolean) => void;
  label?: React.ReactElement | string;
  labelColor?: FontColors;
  isAsync?: boolean;
  disabled?: boolean;
}

function CheckBox({
  onChange,
  isChecked = false,
  label = '',
  labelColor = 'secondary',
  isAsync = true,
  disabled,
}: CheckBoxProps) {
  const onCheckBoxChange = () => {
    if (!isAsync) onChange?.(!isChecked);
  };

  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onCheckBoxChange}
        disabled={disabled}
      />
      {label && typeof label === 'string' ? (
        <Typography size={14} weight="400" color={labelColor}>
          {label}
        </Typography>
      ) : (
        <div>{label}</div>
      )}
    </div>
  );
}

export default CheckBox;
