export enum EventActions {
  click = 'click',
  popup = 'popup',
  load = 'load',
}

export enum EventNames {
  bookSession = 'book_session',
  confirmSession = 'confirm_session',
  cancelSessionRequest = 'cancel_session_request',
  cancelSessionConfirmed = 'cancel_session_confirmed',
  confirmSessionRequest = 'confirm_session_request',
  joinSession = 'join_session',
  unmatchRequest = 'unmatch_request',
  unmatchConfirm = 'unmatch_confirm',
  sessionDetails = 'session_details',
  chatScreen = 'chat_screen',
  sendMessage = 'send_message',
  closeChat = 'close_chat',
  userChatScreen = 'user_chat_screen',
  rescheduleSession = 'reschedule_session',
  cancelSession = 'cancel_session',
  close = 'close',
  messages = 'message',
  inSession = 'in-session',
  Login = 'login',
  personalInfo = 'personal_info',
  manageInfo = 'manage_info',
  onsiteSessions = 'onsite_sessions',
  editSession = 'edit_session',
  exploreEvents = 'explore_events',
  location = 'location',
}

export enum EventCategories {
  bookSession = 'book_session',
  confirmSession = 'confirm_session',
  cancelSessionRequest = 'cancel_session_request',
  cancelSessionConfirmed = 'cancel_session_confirmed',
  confirmSessionRequest = 'confirm_session_request',
  joinSession = 'join_session',
  unmatchRequest = 'unmatch_request',
  unmatchConfirm = 'unmatch_confirm',
  chatScreen = 'chat_screen',
  sendMessage = 'send_message',
  closeChat = 'close_chat',
  userChatScreen = 'user_chat_screen',
  rescheduleSession = 'reschedule_session',
  cancelSession = 'cancel_session',
  cancelSessionContinue = 'cancel_session_continue',
  doNotCancel = 'do_not_cancel',
  cancelSessionConfirm = 'cancel_session_confirm',
  confirmReschedule = 'confirm_reschedule',
  viewUserChat = 'view_user_chat',
  clickAttachment = 'click_attachment',
  hardwareSetup = 'hardware_setup',
  joinCall = 'join_call',
  backButton = 'back_button',
  meeting = 'meeting',
  meetingFinish = 'meeting_finish',
  login = 'login',
  personalInfo = 'personal_info',
  name = 'name',
  location = 'location',
  phoneNumber = 'phone_number',
  lineOfBusiness = 'line_of_business',
  tenure = 'tenure',
  age = 'age',
  next = 'next',
  emergencyContactName = 'emergency_contact_name',
  emergencyContactPhone = 'emergency_contact_phone',
  emergencyContactRelationship = 'emergency_contact_relationship',
  emergencyContactConsent = 'emergency_contact_consent',
  emergencyContactAdd = 'emergency_contact_add',
  emergencyContactRemove = 'emergency_contact_remove',
  emergencyContactSkip = 'emergency_contact_skip',
  manageInfo = 'manage_info',
  remove = 'remove',
  cancel = 'cancel',
  save = 'save',
  homePage = 'home_page',
  viewDetails = 'view_details',
  editSession = 'edit_session',
  exploreEvents = 'explore_events',
}

export const EventProperties = {
  eventAction: 'eventAction',
  eventCategory: 'eventCategory',
  eventLabel: 'eventLabel',
};

export const BookSessionSources = {
  providerProfile: 'provider_profile',
  providerListing: 'provider_listing',
} as const;
