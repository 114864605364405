import { FetchKeys } from '@constants/queries';
import { useFetch } from '@hooks/useFetch';
import { getMatchingConfig } from '@services/provider';
import { IProviderMatchConfig } from '@customTypes/response/IUser';

function useMatchConfig(enabled: boolean = true) {
  return useFetch<IProviderMatchConfig>(
    [FetchKeys.providerMatchConfig],
    () => getMatchingConfig(),
    { enabled, refetchOnWindowFocus:false },
  );
}

export default useMatchConfig;
