import * as React from 'react';
import { motion } from 'framer-motion';
import './ChatContactV2.scss';
import Typography from '@widgets/Typography';
import { getUnreadMessagesCountForFloatingChatButton } from '@utilities/common/Display';
import ChatAvatar from '../ChatAvatarV2';

export interface IChatContactProps {
  name: string;
  message: string;
  messagesUnread: number; // not going with boolean as to support number based status in the future
  profileImage?: string;
  messagedTime: string;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

function ChatContact({
  name,
  message,
  messagesUnread,
  profileImage,
  messagedTime,
  selected,
  onClick,
}:
IChatContactProps) {
  return (
    <motion.div
      className={`chat-contact-container ${selected ? 'selected' : ''}`}
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
    >
      <div className="left-pane">
        <ChatAvatar name={name} profileImage={profileImage} />
      </div>
      <div className="chat-main-content">
        <Typography color="primary" size={14} weight="600">
          {name}
        </Typography>
        <div className="message-container">
          <Typography size={12} weight="400" color="primary">
            {message}
          </Typography>
        </div>
      </div>
      <div className="chat-indication-container">
        <Typography color="solarized" weight="400" size={10}>
          {messagedTime}
        </Typography>
        <div
          className={`heartbeat-indicator ${
            messagesUnread > 0 ? 'not-consumed' : 'consumed'
          }`}
        >
          {/* {messagesUnread} */}
          {messagesUnread
            ? getUnreadMessagesCountForFloatingChatButton(messagesUnread)
            : ''}
        </div>
      </div>
    </motion.div>
  );
}

export default ChatContact;
