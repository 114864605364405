import { useFetch, useInfiniteFetch, useMutation } from '@hooks/useFetch';
import {
  bookOnsiteEvent,
  cancelOnsiteSessionService,
  fetchOnsiteEvents,
  fetchUpcomingOnsiteSession,
  getDeploymentDetails,
  getOnsiteLocations,
  getOnsiteRescheduleSlots,
  rescheduleOnsiteSession,
} from '@services/user';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getMeetingDetails } from '@services/meeting';
import { openNewTab } from '@utilities/common/Path';
import {
  IDeploymentDetailsRes,
  IOnsiteBookingRequest,
  IOnsiteCancelSessionRequest,
  IOnsiteEventsRes,
  IOnsiteLocationRes,
  IOnsiteMeetingResponse,
  IOnsiteRescheduleRequest,
  IOnsiteRescheduleSlots,
} from './types';
import { getDailyCoUrlFromRoomId } from '../../../view/screens/Room/utils';

function useOnsiteData(locationId?: number) {
  const { data: onsiteEvents, isLoading: isOnsiteEventsLoading } =
    useFetch<IOnsiteEventsRes>(
      ['onsiteEvents', locationId],
      () => fetchOnsiteEvents(locationId),
      {
        refetchOnWindowFocus: false,
      },
    );
  const { data: locationData, isLoading: isLocationsLoading } =
    useFetch<IOnsiteLocationRes>(['onsiteLocations'], getOnsiteLocations, {
      refetchOnWindowFocus: false,
    });

  return {
    onsiteEvents,
    isOnsiteEventsLoading,
    locationData,
    isLocationsLoading,
  };
}

export function useOnsiteDeployement(id: string) {
  const navigate = useNavigate();
  const {
    data: onsiteDeploymentData,
    isLoading: isOnsiteDeploymentDataLoading,
    refetch: refetchOnsiteDeploymentData,
  } = useFetch<IDeploymentDetailsRes>(
    ['onsiteDeploymentData', id],
    () => getDeploymentDetails(id),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        navigate('/onsite-days');
      },
    },
  );
  return {
    onsiteDeploymentData,
    isOnsiteDeploymentDataLoading,
    refetchOnsiteDeploymentData,
  };
}

export function useBookOnsiteEvent(onSuccess?: (data: any) => void) {
  const { mutate: scheduleOnsiteSession, isLoading: isBookOnsiteEventLoading } =
    useMutation((body: IOnsiteBookingRequest) => bookOnsiteEvent(body), {
      onSuccess,
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });

  return {
    scheduleOnsiteSession,
    isBookOnsiteEventLoading,
    onSuccess,
  };
}



export function useOnsiteSlots(eventId: string) {
  const { data: rescheduleSlots, isLoading: isRescheduleSlotsLoading } =
    useFetch<IOnsiteRescheduleSlots>(
      ['onsiteRescheduleSlotsData', eventId],
      () => getOnsiteRescheduleSlots(eventId),
      {
        refetchOnWindowFocus: false,
      },
    );
  return {
    rescheduleSlots,
    isRescheduleSlotsLoading,
  };
}

export function useEditOnsiteSessions(
  onRescheduleSuccess?: (data: any) => void,
  onCancellationSuccess?: (data: any) => void,
) {
  const { mutate: rescheduleSession, isLoading: isRescheduleSessionLoading } =
    useMutation(
      ({ note, scheduledStartTime, meetingId }: IOnsiteRescheduleRequest) =>
        rescheduleOnsiteSession({ note, scheduledStartTime, meetingId }),
      {
        onSuccess: onRescheduleSuccess,
        onError: (error: Error) => {
          toast.error(error.message);
        },
      },
    );

  const {
    mutate: cancelOnsiteSession,
    isLoading: isCancelOnsiteSessionLoading,
  } = useMutation(
    ({ type, otherReason, meetingId }: IOnsiteCancelSessionRequest) =>
      cancelOnsiteSessionService({ type, otherReason, meetingId }),
    {
      onSuccess: onCancellationSuccess,
      onError: (error: Error) => {
        toast.error(error.message);
      },
    },
  );

  return {
    rescheduleSession,
    isRescheduleSessionLoading,
    cancelOnsiteSession,
    isCancelOnsiteSessionLoading,
  };
}

export function useAllBookings(filters?: any[]) {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, refetch: refetchAllBookings } =
    useInfiniteFetch(
      ['allBookings', JSON.stringify(filters)],
      ({ pageParam = 1 }) => fetchUpcomingOnsiteSession({ page: pageParam, filters }),
      {
        getNextPageParam: (lastPage) => lastPage.page + 1 <= lastPage.pages ?  lastPage.page + 1 : undefined,
      },
    );
  const meetings: IOnsiteMeetingResponse['meetings'] = data?.pages.flatMap((page) => page.meetings) || [];

  return {
    meetings,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetchAllBookings,
  };
}

export function getMeetingJoinData() {
  const { mutate: getMeetingData, isLoading: isMeetingDataLoading } =
    useMutation(({
      meetingId,
      channel,
    }: {
      meetingId: string;
      channel: string;
    }) => getMeetingDetails(meetingId, channel), {
      onSuccess(data) {
        const { meeting } = data || {};
        const { locationRef } = meeting || {};
        openNewTab(`room/?roomUrl=${getDailyCoUrlFromRoomId(locationRef)}`);
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });

  return {
    getMeetingData,
    isMeetingDataLoading,
  };
}

export default useOnsiteData;
