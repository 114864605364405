import * as React from 'react';
import './MessageInputBox.scss';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import addIcon from '@assets/Add.svg';
import { byteToMegaByte } from '@components/Chat/utils/helper';
import sendIcon from '../../assets/send.svg';
import recordVoiceIcon from '../../assets/record-voice.svg';
import ChatVoiceRecorder from '../ChatVoiceRecorderV2';
import MessageInputAudioPreview from '../MessageInputAudioPreviewV2';
import AttachmentPreview from '../AttachmentPreview';
import { MessageSendType } from '../../constants/CommonConstants';
import { IMessageSendType } from '../../data-types/ChatBrokerTypes';

interface IMessageInputBoxProps {
  value: string;
  recordedVoice: Blob | null;
  onSendMessage: () => void;
  onTextValueChange: (value: string) => void;
  onAttachmentChange: (value: IMessageSendType | null) => void;
  onTypedTextKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onRecordAudio: (recordedAudio: IMessageSendType) => void;
	  file: IMessageSendType | null;
  textAreaRef?: React.MutableRefObject<null>;
}

enum MessageInputBoxMode {
  text = 'text',
  voice = 'voice',
}


function MessageInputBox({
  value,
  recordedVoice,
  file,
  onTextValueChange,
  onAttachmentChange,
  onSendMessage,
  onTypedTextKeyPress,
  onRecordAudio,
  textAreaRef,
}: IMessageInputBoxProps) {
  const { t } = useTranslation();
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const [recordedAudio, setRecordedAudio] = React.useState<Blob | null>(null);
  const [messageInputMode, setMessageInputMode] =
    React.useState<MessageInputBoxMode>(MessageInputBoxMode.text);

  const fileTypeRegex: RegExp = /^(application\/pdf|image\/.*)$/;
  const onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: newValue } = event.target;
    if (newValue !== '\n') {
      onTextValueChange?.(newValue);
    }
    if (textAreaRef && textAreaRef.current && value.length) {
      // @ts-ignore 
      // eslint-disable-next-line
      textAreaRef.current.style.height = '0px';
      // @ts-ignore
      const { scrollHeight } = textAreaRef.current;
      // @ts-ignore
      // eslint-disable-next-line
      textAreaRef.current.style.height = `${Math.min(scrollHeight, 100)}px`;
    }
  };

  const onSelectedFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.length && !fileTypeRegex.test(event.target.files[0].type) ) {
      toast.error(t('UNSUPPORTED_FILE_ERROR') as string);
      return;
    }
    if (
      event.target &&
      event.target.files &&
      event.target.files.length &&
      byteToMegaByte(event?.target?.files?.[0]?.size) <= 5
    ) {
      const attachmentFile = event.target.files[0];
      const fileType =
        attachmentFile.type === 'application/pdf'
          ? MessageSendType.PDF
          : MessageSendType.IMAGE;
      const data: IMessageSendType = {
        data: attachmentFile,
        type: fileType,
      };
      onAttachmentChange(data);
    } else {
      toast.error(t('FILE_SIZE_EXCEEDING_ERROR') as string);
    }
  };

  const onVoiceRecordingCancel = () => {
    setMessageInputMode(MessageInputBoxMode.text);
  };

  const onVoiceRecordingEnd = (voice: Blob) => {
    if (voice) {
      const recordedDataClone = voice.slice();
      setRecordedAudio(recordedDataClone);
      const data = {
        data: recordedDataClone,
        type: MessageSendType.AUDIO,
      };
      onRecordAudio(data);
    }
  };

  React.useEffect(() => {
    // Synchronize state of the outer audio updates with inner state.
    if (!recordedVoice) {
      setRecordedAudio(null);
    }
  }, [recordedVoice]);

  const sendBtnDisable = React.useMemo(
    () => !value && !file && !recordedVoice,
    [value, file, recordedVoice],
  );
  const onSendBtn = () => {
    if (sendBtnDisable) {
      return;
    }
    // @ts-ignore
    // eslint-disable-next-line
    textAreaRef.current.style.height = '40px';
    onSendMessage();
  };

  return (
    <div className="chat-controls-container">
      {recordedAudio && (
        <MessageInputAudioPreview
          onClose={() => setRecordedAudio(null)}
          audioStream={recordedAudio}
        />
      )}
      {file && (
        <AttachmentPreview
          attachmentFile={file}
          onClose={() => onAttachmentChange(null)}
        />
      )}
      <div
        className="message-textbox-container"
        onKeyDown={onTypedTextKeyPress}
        role="button"
        tabIndex={0}
      >
        <div className="message-input-box">
          {messageInputMode === MessageInputBoxMode.text && (
            <>
              <motion.img
                className="attachment"
                src={addIcon}
                alt="add attachment"
                whileHover={{ scale: 1.2 }}
                onClick={() => inputFileRef.current?.click()}
              />
              <textarea
                ref={textAreaRef}
                placeholder={t('Type a message')}
                onChange={onTextChange}
                value={value}
                className="message-input"
              />
              <img
                src={recordVoiceIcon}
                className="record-voice"
                alt="record voice"
                onClick={() => setMessageInputMode(MessageInputBoxMode.voice)}
              />
              <input
                type="file"
                accept="image/*, application/pdf"
                onChange={(e) => onSelectedFileChange(e)}
                ref={inputFileRef}
                onClick={(e) => {
                  // reset file upload value to trigger change on same file upload next time
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                style={{ display: 'none' }}
              />
              
              <motion.div
                className={`send-icon-container ${sendBtnDisable && 'disable'}`}
                onClick={onSendBtn}
                whileHover={{ scale: sendBtnDisable ? 1 : 1.1 }}
                whileTap={{ scale: sendBtnDisable ? 1 : 0.8 }}
              >
                <img src={sendIcon} alt="send message" />
              </motion.div>
            </>
          )}
          {messageInputMode === MessageInputBoxMode.voice && (
            <ChatVoiceRecorder
              onRecordingCancel={onVoiceRecordingCancel}
              onRecordingEnd={onVoiceRecordingEnd}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MessageInputBox;
