import * as React from 'react';
import { FilterContext } from './FilterContext';

export default function useFilterContext() {
  const context = React.useContext(FilterContext);
  if (!context) {
    throw new Error(
      'An error has occured while using the useFilterContext',
    );
  }
  return context;
}
