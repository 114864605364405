import { StorageItems } from '@constants/App';
import { UserWithRoles } from '@hooks/useAuth/useAuth';
import { getItemFromLocalStorage } from '@utilities/common/Storage';
import mixpanel from 'mixpanel-browser';
import { isProduction } from '../../../utilities/common/Path';

const projectKeys = {
  production: 'b48a1d8cfd268c9ce2a7ea1646736dfe',
  development: 'd93980ffe4384221600c6a92b7ac8b78',
  test: 'd93980ffe4384221600c6a92b7ac8b78',
};

class Analytics {
  static initialize() {
    mixpanel.init(isProduction() ? projectKeys.production : projectKeys.development, {
      debug: process.env.NODE_ENV !== 'production',
    });
  }

  static send(event: string, properties: Record<string, string>) {
    mixpanel.track(event, properties);
  }

  static getUserType() {
    const user = getItemFromLocalStorage(StorageItems.USER_INFO, 'object') as UserWithRoles;
    if (user.isResponder) {
      return 'responder';
    }
    if (user.isCareNavigator) {
      return 'careNavigator';
    }

    return 'employee';
  }
}

export default Analytics;
