import Config from '../../shared/config';
import { beautifyUrl } from './Path';

export const displayDateFormat = 'MMM DD, YYYY';

export const getImageUri = (imagePath: string) =>
  beautifyUrl(Config.api.media.getImage, [imagePath]);

export const getTruncatedMeetingId = (meetingId: string) =>
  `#${meetingId.substring(0, 6)}`;

export const getUnreadMessagesCountForFloatingChatButton = (count: number) => {
  // messages more than 9 will show as 9+
  if (count >= 10) return '9+';
  return count;
};

export const getKeyByValue = (
  object: { [key: string]: string },
  value: string,
) => (
  Object.keys(object).find((key) => object[key] === value)
);
