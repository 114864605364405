import * as React from 'react';
import moment from 'moment';
import Typography from '@widgets/Typography';
import {
  OldChatMessageFormat,
  RecentChatMessageFormat,
} from '@utilities/common/Date';
import { MessageResponseType } from '../../data-types/ClientTypes';
import AudioMessageItem from '../AudioMessageItemV2';
import ImageMessageItem from '../ImageMessageItemV2';
import TextMessageItem from '../TextMessageItemV2';
import './ChatMessage.scss';
import { getMessageContentType } from '../../utils/helper';
import {
  ChatSource,
  MessageContentTypeCssMap,
  MessageContentTypes,
} from '../../constants/CommonConstants';
import CtaMessageItem from '../CtaMessageItemV2';
import DocumentMessageItem from '../DocumentMessageItem';

export interface IChatMessage {
  message: MessageResponseType;
  source: ChatSource;
}

const getFormattedMessagedTime = (messagedDate: moment.Moment): string => {
  const currentDate = moment();
  const isSameDay = currentDate.startOf('day') <= messagedDate;
  const isYesterday =
    currentDate.subtract('1', 'day').startOf('day') <= messagedDate;
  if (isSameDay) {
    return messagedDate.format(RecentChatMessageFormat);
  }
  if (isYesterday) {
    return `Yesterday ${messagedDate.format(RecentChatMessageFormat)}`;
  }

  return messagedDate.format(OldChatMessageFormat);
};

function ChatMessage(
  { message, source }: IChatMessage,
  ref: React.ForwardedRef<HTMLDivElement> | null,
) {
  const messageContentType = getMessageContentType(message.attachments);
  const formattedTime = getFormattedMessagedTime(moment(message.createdAt));

  return (
    <div>
      <div className={`chat-message-container ${source}`} ref={ref}>
        <div
          className={`chat-contents ${source} ${MessageContentTypeCssMap[messageContentType]}`}
        >
          {messageContentType === MessageContentTypes.text && (
            <TextMessageItem message={message.message} source={source} />
          )}
          {messageContentType === MessageContentTypes.image && (
            <ImageMessageItem
              imageUrl={message.attachments[0].content}
              body={message.message}
              source={source}
            />
          )}
          {messageContentType === MessageContentTypes.audio && (
            <AudioMessageItem
              audioUrl={message.attachments[0].content}
              source={source}
              body={message.message}
            />
          )}
          {messageContentType === MessageContentTypes.pdf && (
            <DocumentMessageItem
              docUrl={message.attachments[0].content}
              docName="PDF document"
              body={message.message}
              source={source}
            />
          )}
          {messageContentType === MessageContentTypes.cta && (
            <CtaMessageItem
              message={message.message}
              ctaContent={message.attachments[0].content}
              source={source}
            />
          )}

          <div className="msg-timestamp">
            <Typography
              weight="400"
              withColor={source === 'outbound' ? '#FFF' : '#A3A8AD'}
              size={12}
            >
              {formattedTime}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(ChatMessage);
