import { StorageItems } from '@constants/App';
import NetworkClient from '@services/Network';
import { getEmployeeDataFromSearchParam } from '@utilities/common/Responder';
import { addItemToLocalStorage } from '@utilities/common/Storage';
import { beautifyUrl } from '@utilities/common/Path';
import Config from '../config';

const ErrorMessages = {
  generic: 'Unable to authenticate the user, please try again!',
};

const client = new NetworkClient();

export const loginAsResponder = async (
  emailId: string,
  password: string,
  user: string | null,
) => {
  const requestBody = { email: emailId, password };
  const response = await client.doPost(Config.api.auth.login, requestBody);

  if (user) {
    const userData = getEmployeeDataFromSearchParam(user);
    if (userData) {
      const { email, id, phoneNumber, sessionId } = userData;
      if (response.data && response.success) {
        const userWithRoles = {
          ...response.data,
          id,
          email,
          phoneNumber,
          sessionId,
          isEmployee: false,
          isResponder: response?.data?.addOns?.isResponder,
          isCareNavigator: response?.data?.addOns?.isCareNavigator,
        };
        addItemToLocalStorage(StorageItems.USER_INFO, userWithRoles);
        return true;
      }
    }
  }
  throw new Error(
    response.details || response.error?.message || ErrorMessages.generic,
  );
};

export const getUserDataForResponder = async (
  userId: string,
  email: string,
  token: string,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.responder.user, [], {
      userId,
      email,
    }),
    // @ts-ignore
    { Authorization: token },
  );
  if (response && response.success) {
    return response.data || {};
  }
  throw new Error(response.error?.message || ErrorMessages.generic);
};

