import * as React from 'react';
import { motion } from 'framer-motion';
import { ICardProps } from './types';
import Typography from '../Typography';
import './Card.scss';

function Card(
  {
    content,
    width = '100%',
    height,
    fontSize = 12,
    fontWeight = '400',
    backgroundColor,
    selected,
    variant = 'default',
    onClick = () => {},
    icon,
    toolTipText,
    uniqueKey,
    withColor,
    borderRadius = '8px',
    cardContentWidth = '80%',
    borderStyle = 'solid',
    minHeight = '54px',
    iconHeight = '20px',
    iconWidth = '20px',
  }: ICardProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const variantType = selected ? 'selected' : variant;
  const getStyles = () => {
    const styles: React.CSSProperties = {
      height,
      width,
      borderRadius,
      borderStyle,
      minHeight,
    };

    if (backgroundColor) {
      styles.backgroundColor = backgroundColor;
    }

    return styles;
  };

  return (
    <motion.button
      type="button"
      className={`card-container ${variantType}`}
      onClick={onClick}
      ref={ref}
      style={getStyles()}
    >
      <Typography
        size={fontSize}
        weight={fontWeight}
        width={cardContentWidth}
        withColor={withColor}
      >
        {content}
      </Typography>
      {icon && (
        <span>
          <img
            data-tooltip-id={
              toolTipText ? `toolTipUnsaved-${uniqueKey}` : 'noToolTIp'
            }
            height={iconHeight}
            width={iconWidth}
            src={icon}
            className="icon"
            alt="filter"
          />
        </span>
      )}
    </motion.button>
  );
}
export default React.forwardRef(Card);
