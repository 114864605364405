import * as React from 'react';
import { CurrentUserContext } from '@contexts/CurrrentUserContext';

export function useCurrentUserContext() {
  const context = React.useContext(CurrentUserContext);
  if (!context) {
    throw new Error(
      'An error has occured while using the useCurrentUserContext',
    );
  }
  return context;
}
