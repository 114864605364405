import { ProviderSlotAPIVersions } from '@customTypes/responder';
import { useFetch } from '@hooks/useFetch';
import { getProviderOpenSlots } from '@services/provider';

function useProviderOpenSlots<T>(
  providerId: string,
  role: string,
  locationId?: string,
  version?: ProviderSlotAPIVersions,
  credits?: string,
  enabled?: boolean,
) {
  return useFetch<T>(
    ['provider', 'slots', providerId, locationId],
    () => getProviderOpenSlots(providerId, role, locationId || null, version || 'v1', credits),
    {
      refetchOnWindowFocus: false,
      enabled: enabled || true,
    },
  );
}

export default useProviderOpenSlots;
