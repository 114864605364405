export enum CancellationReasonsEnum {
  TOO_LATE_TO_RESCHEDULE = '6',
  SCHEDULING_CONFLICT = '0',
  DO_NOT_REQUIRED = '8',
  NEW_CLINICIAN = '3',
  OTHERS = '4',
}

export const OnsiteSessionCancellationReasonsEnum : { [key: string]: string } = {
  CREDIT_REASON_LATERESCHEDULE : '6',
  CREDIT_REASON_PROVIDER_SCHEDULING_CONFLICT : '17',
  CREDIT_REASON_DONTNEED : '8',
  CREDIT_REASON_OTHERS : '4',
};

export enum CancellationModalContent {
  CANCELLATION_REASONS = 'cancellationReasons',
  CANCELLATION_CONFIRMATION = 'cancellationConfirmation',
}