// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StorageItems } from '../../../shared/constants/App';
import { IUser, IRoles } from '../../../shared/types/response/IUser';
import { refreshApp } from '../../common/Path';
import {
  addItemToLocalStorage,
  clearAppState,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from '../../common/Storage';

export type UserWithRoles = IUser & IRoles;

interface IUseAuthReturns {
  isLoggedIn: string;
  user: UserWithRoles;
  userToken: string;
  invalidateUser: () => void;
  overrideUserProperties: (props: Partial<Record<keyof UserWithRoles, any>>, clearData?:boolean) => void;
}

function clearCache() {
  const cacheItems = [StorageItems.USER_INFO, StorageItems.CHAT_TOKEN, StorageItems.TIME_ZONE];
  cacheItems.forEach((cache) => removeItemFromLocalStorage(cache));
}

function attachRoles(user: IUser): UserWithRoles {
  // eslint-disable-next-line no-param-reassign
  user.emailOriginal = user.email;
  if (user.email && user.email.endsWith('@bytedance.com.care')) {
    // eslint-disable-next-line no-param-reassign
    user.email = user.email.replace('@bytedance.com.care', '@bytedance.com');
  }
  if (user.emailOriginal && user.emailOriginal.endsWith('@bytedance.com')) {
    // eslint-disable-next-line no-param-reassign
    user.emailOriginal = user.email.replace('@bytedance.com', '@bytedance.com.care');
  }
  // * to override / attach any custom roles, we can do it from here
  return {
    ...user,
  };
}

function useAuth(): IUseAuthReturns {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  let user = getItemFromLocalStorage(
    StorageItems.USER_INFO,
    'object',
  ) as UserWithRoles;

  const userWithRoles = attachRoles(user);

  const isLoggedIn = user && user.id && user.token;

  const invalidateUser = () => {
    clearCache();
    // TODO improve routing logic after logout
    if (user.isResponder || user.isCareNavigator) {
      navigate('/responder-login');
    } else {
      navigate('/login');
    }
    // dispatch({ type: 'CLEAR_USER_DATA' });
    clearAppState();
    refreshApp(); // doing a refresh to clear the user sessions
  };

  const overrideUserProperties = (props: Partial<Record<keyof UserWithRoles, any>>, clearData:boolean = true) => {
    const overriddenUser = { ...user, ...props };
    console.log(clearData);
    // if (clearData) dispatch({ type: 'CLEAR_USER_DATA' });
    addItemToLocalStorage(StorageItems.USER_INFO, overriddenUser);
    user = overriddenUser;
  };

  return { isLoggedIn, user: userWithRoles, userToken: user.token, invalidateUser, overrideUserProperties };
}

export default useAuth;
