import * as React from 'react';
import './RecordingWave.scss';
import lodash from 'lodash';

function RecordingWave() {
  const noOfWaves = 15;

  return (
    <div className="recording-wave-container">
      <div className="recording-wave">
        {lodash.range(noOfWaves).map((wave) => (
          <span key={wave} />
        ))}
      </div>
    </div>
  );
}

export default RecordingWave;
