import React from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '@widgets/Popup';
import Typography from '@widgets/Typography';
import successIcon from '@assets/tickmark.svg';
import Button from '@widgets/Button';
import './RescheduleSession.scss';
import { IBookMeetingResponse } from '@customTypes/request/provider';

function ConfirmationPopup({
  rescheduledDateTime,
  location,
  handleOkay,
}: {
  rescheduledDateTime: string;
  location: IBookMeetingResponse['room'] | null;
  handleOkay: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Popup showCloseIcon={false} withBackdrop>
      <div className="reschedule-confirmation">
        <div>
          <img src={successIcon} alt="success" className="success-icon" />
        </div>
        <div>
          <Typography size={20} weight="500">
            {t('CONFIRM_RESCHEDULE_PROMPT')}
          </Typography>
        </div>
        <div>
          <Typography
            size={15}
            weight="400"
            withColor="#0a0c0e5c"
            textAlign="center"
          >
            {t('UPCOMING_SESSION_HEADER')}
          </Typography>
          <Typography size={16} weight="600">
            {rescheduledDateTime}
          </Typography>
        </div>
        {location ? (
          <div>
            <Typography
              size={16}
              weight="500"
              color="primary"
              textAlign="center"
            >
              Location: {location.name},
            </Typography>
            <Typography
              size={16}
              weight="500"
              color="primary"
              textAlign="center"
            >
              {location.location.name}
            </Typography>
          </div>
        ) : null}
        <div className="action-handler">
          <Button
            label={t('OKAY')}
            variant="blue"
            onClick={handleOkay}
            fontSize={14}
          />
        </div>
      </div>
    </Popup>
  );
}

export default ConfirmationPopup;
