import * as React from 'react';
import './LiveChatV2.scss';
import { EventActions, EventCategories, EventNames } from '@constants/analytics';
import useTracking from '@hooks/useTracking';
import Analytics from '@services/Analytics';
import { UseMutateFunction } from 'react-query';
import Loader from '@widgets/Loader';
import MediaViewer from '../MediaViewerV2';
import MessageInputBox from '../MessageInputBoxV2';
import ChatMessage from '../ChatMessageV2';
import ChatHeader from '../ChatHeader';
import { useChatBroker } from '../../broker/useChatBroker';
import MediaPreviewContext from '../../../../../shared/context/MediaPreviewContext';
import {
  AllConversationListType,
  IMessageSendType,
} from '../../data-types/ChatBrokerTypes';
import { SelectedUserMessagesType } from '../../data-types/ClientTypes';
import {
  MessageChannelTypes,
  MessageSendType,
} from '../../constants/CommonConstants';
import useInfiniteScroll, {
  scrollToBottom,
} from '../../utils/hooks/useInfiniteScroll';
import NoConversations from '../NoConversation';


export interface LiveChatV2Props {
  userMetaData: AllConversationListType;
  selectedUserMsg: SelectedUserMessagesType;
  isLoading: boolean;
  pageNoRef: React.MutableRefObject<number>;
  getUnReadCount: (selectedConversationId: string) => number;
  updateMessageConsumptionInfo: ({
    userId,
    channelType,
  }: {
    userId: string;
    channelType: MessageChannelTypes;
  }) => void;
  isNextMessageLoaded: React.MutableRefObject<boolean>;
  getSelectedUserMessages: UseMutateFunction<
  SelectedUserMessagesType,
  unknown,
  {
    selectedConversationId: string;
    channelId: string;
    pageNo: number;
    channelType: MessageChannelTypes;
  },
  unknown
  >;
}

function LiveChat({
  userMetaData,
  selectedUserMsg,
  getUnReadCount, // eslint-disable-line
  pageNoRef,
  isNextMessageLoaded,
  getSelectedUserMessages,
  updateMessageConsumptionInfo,
  isLoading, // eslint-disable-line
}: LiveChatV2Props) {
  const { sendMessage } = useChatBroker();
  const messageRefList = React.useRef<HTMLDivElement[]>([]);
  const bottomRef = React.useRef<HTMLDivElement>(null);
  const prevMessageLength = React.useRef(0);
  const textAreaRef = React.useRef(null);
  const { track } = useTracking();

  function onIntersect() {
    if (!selectedUserMsg.messages?.length || !selectedUserMsg.isNext || isLoading) {
      return;
    }
    pageNoRef.current += 1; // eslint-disable-line
    getSelectedUserMessages({
      selectedConversationId: userMetaData.conversationId,
      channelId: userMetaData.channelId,
      pageNo: pageNoRef.current,
      channelType: userMetaData.channelType,
    });
  }

  React.useLayoutEffect(() => {
    if (isNextMessageLoaded.current) {
      const newMessageLength = selectedUserMsg?.messages?.length;
      const currentViewIndex = newMessageLength - prevMessageLength.current;
      messageRefList.current[currentViewIndex]?.scrollIntoView(true);
      prevMessageLength.current = newMessageLength;
      isNextMessageLoaded.current = false; // eslint-disable-line
    }
  }, [isNextMessageLoaded, selectedUserMsg?.messages?.length]);

  const { chatContainerRef, topMessageElementRef } = useInfiniteScroll(
    onIntersect,
    [userMetaData],
    [userMetaData, selectedUserMsg.messages],
  );

  const [selectedMediaFile, setSelectedMediaFile] = React.useState<
  string | null
  >(null);
  const [typedMessage, setTypedMessage] = React.useState<string>('');
  const [selectedAttachment, setSelectedAttachment] =
    React.useState<IMessageSendType | null>(null);
  const parentRef = React.useRef<HTMLElement>(null);
  

  const onMediaSelect = React.useCallback((media: string | null) => {
    setSelectedMediaFile(media);
  }, []);

  const mediaPreviewContextValue = React.useMemo(
    () => ({ showPreview: onMediaSelect }),
    [onMediaSelect],
  );

  const onSend = () => {
    if (selectedAttachment) {
      sendMessage({
        contentToSend: { ...selectedAttachment, body: typedMessage },
        conversationId: userMetaData.conversationId,
        channelType: userMetaData.channelType,
      });
      setSelectedAttachment(null);
      setTypedMessage('');
      track(EventNames.sendMessage, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.sendMessage,
        eventLabel: selectedAttachment.type.toLowerCase(),
        userType: Analytics.getUserType(),
      });
    } else if (typedMessage) {
      const data: IMessageSendType = {
        data: typedMessage,
        type: MessageSendType.TEXT,
      };
      sendMessage({
        contentToSend: data,
        conversationId: userMetaData.conversationId,
        channelType: userMetaData.channelType,
      });
      setTypedMessage('');
      track(EventNames.sendMessage, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.sendMessage,
        eventLabel: 'text',
        userType: Analytics.getUserType(),
      });
    }
  };

  React.useLayoutEffect(() => {
    if (selectedUserMsg.messages?.length) {
      const lastMessage = selectedUserMsg.messages[0];
      if (lastMessage && userMetaData?.providerId === lastMessage.senderId) {
        updateMessageConsumptionInfo({
          userId: userMetaData.conversationId,
          channelType: userMetaData.channelType,
        });
      }
      if (
        pageNoRef.current === 1 ||
        userMetaData?.providerId !== lastMessage.senderId
      ) {
        scrollToBottom(chatContainerRef, 'auto');
      }
    }
  }, [
    selectedUserMsg?.messages,
  ]);

  const onTypedTextKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    /* press enter -> to send a message
    press enter + shift -> add new line */
    if ((e.code === 'Enter' || e.code === 'NumpadEnter') && !e.shiftKey && !e.nativeEvent.isComposing) {
      onSend();
      // @ts-ignore
      // eslint-disable-next-line
      textAreaRef.current.style.height = '40px';
    }
  };



  const selectedUserMsgRev = selectedUserMsg.messages?.slice().reverse();

  const getMessageListContainerHeight = (
    maxHeight: number,
    offset?: number,
  ) => {
    let heightToSub = Number(
      // @ts-ignore
      textAreaRef?.current?.style?.height?.slice(0, -2) || 0,
    );
    if (offset && heightToSub) {
      heightToSub -= offset;
    }
    return `${maxHeight - heightToSub}px`;
  };
  return (
    <MediaPreviewContext.Provider value={mediaPreviewContextValue}>
      {selectedMediaFile && <MediaViewer mediaUrl={selectedMediaFile} />}
      <section className="live-chat-container" ref={parentRef}>
        <ChatHeader
          userMetaData={userMetaData}
          isUserInfoVisible={JSON.stringify(userMetaData) === '{}'}
        />
        {isLoading && pageNoRef.current === 1 && (
          <Loader
            useFullPage={false}
            dimension={parentRef?.current?.getBoundingClientRect()}
          />
        )}
        {JSON.stringify(userMetaData) === '{}' ? (
          <NoConversations />
        ) : (
          <>
            <div
              className="chat-messages-container"
              ref={chatContainerRef}
              style={{ height: getMessageListContainerHeight(376, 40) }}
            >
              <section ref={topMessageElementRef} />
              {(selectedUserMsg.conversationId ===
                userMetaData.conversationId ||
                selectedUserMsg.conversationId === userMetaData.channelId) &&
                selectedUserMsgRev?.map((msg, index) => (
                  <ChatMessage
                    key={msg.id}
                    message={msg}
                    source={
                      msg.senderId === userMetaData.userId
                        ? 'outbound'
                        : 'inbound'
                    }
                    ref={(el) => {
                      messageRefList.current[index] = el as HTMLDivElement;
                    }}
                  />
                ))}
              <section ref={bottomRef} />
            </div>
            <MessageInputBox
              textAreaRef={textAreaRef}
              onAttachmentChange={setSelectedAttachment}
              file={
                selectedAttachment?.type === MessageSendType.IMAGE ||
                selectedAttachment?.type === MessageSendType.PDF
                  ? selectedAttachment
                  : null
              }
              value={typedMessage}
              onTextValueChange={(value) => setTypedMessage(value)}
              onTypedTextKeyPress={onTypedTextKeyPress}
              onSendMessage={onSend}
              onRecordAudio={setSelectedAttachment}
              recordedVoice={
                selectedAttachment?.type === MessageSendType.AUDIO
                  ? (selectedAttachment.data as File)
                  : null
              }
            />
          </>
        )}
      </section>
    </MediaPreviewContext.Provider>
  );
}

export default LiveChat;
