export enum MeetingStatus {
  scheduled = '0',
  inprogress = '1',
  completed = '2',
  cancelled = '3',
  noshow = '4',
  empty = '5',
  lastmincancelled = '6',
  providernoshow = '7',
  usernoshow = '8',
  requesttoschedule = '9',
  requestcancelled = '10',
}

export enum OnEventSiteStatus {
  DONE = 'done',
  DRAFT = 'draft',
  LIVE = 'live',
  CANCELLED = 'cancelled',
}
