import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@widgets/Typography';
import successIcon from '@assets/cancellation-confirmation.png';
import Button from '@widgets/Button';
import './SessionCancelPopup.scss';

function Success({ handleOkay }: { handleOkay: () => void }) {
  const { t } = useTranslation();
  return (
    <div className="cancellation-confirmation">
      <div>
        <img src={successIcon} alt="success" className="success-icon" />
      </div>
      <div className="sub-text">
        <Typography size={20} weight="600">
          {t('CANCELLATION_CONFIRMATION')}
        </Typography>
      </div>
      <div className="action-handler">
        <Button
          label={t('OKAY')}
          variant="blue"
          onClick={handleOkay}
          fontSize={14}
        />
      </div>
    </div>
  );
}

export default Success;
