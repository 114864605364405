import NetworkClient from '@services/Network';
import { beautifyUrl } from '@utilities/common/Path';
import Config from '../../../../../shared/config';
import { ConsumedResponseType } from '../../data-types/ClientTypes';

const client = new NetworkClient();

export const errorMessage = {
  generic: 'Unable to load the conversations data, please try again!',
};

export const getConversationsDetails = async () => {
  const response = await client.doGet(Config.api.user.coachHome);
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.data?.error?.message || errorMessage.generic);
};

// updates server messages Consumed status

export const setChatMessagesConsumed = async (userId: string): Promise<ConsumedResponseType> =>
  client.doPost(beautifyUrl(Config.api.chat.setChatMessageConsumed, [userId]), {});
