import { AxiosRequestConfig } from 'axios';

const onRequestFulfilled = (request: AxiosRequestConfig<any>) => {
  console.log('%c Request: ', 'background: #00f; color: #fff', request);

  return request;
};

const onRequestRejected = (error: any) => Promise.reject(error);

export { onRequestFulfilled, onRequestRejected };


