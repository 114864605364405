import {
  FetchNextPageOptions,
  GetNextPageParamFunction,
  InfiniteData,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
} from 'react-query';

interface IUseInfiniteFetchOptions {
  initialData?: any;
  keepPreviousData?: boolean;
  onSuccess?: (data: any) => void;
  onError?: (data: any) => void;
  getNextPageParam?: GetNextPageParamFunction<any>;
}

interface IIUseInfiniteFetchReturns {
  isLoading: boolean;
  data: InfiniteData<any> | undefined;
  hasNextPage: boolean | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<unknown, unknown>>;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  refetch: () => void;
}

function useInfiniteFetch(
  queryId: (string | number)[],
  query: any,
  options: IUseInfiniteFetchOptions = {},
): IIUseInfiniteFetchReturns {
  const { getNextPageParam, initialData } = options;
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(queryId, query, {
    getNextPageParam,
    initialData,
  });

  return {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  };
}

export default useInfiniteFetch;
