import { FetchKeys } from '@constants/queries';
import { IProviderProfile } from '@customTypes/response/provider';
import { useFetch } from '@hooks/useFetch';
import { getProviderList } from '@services/provider';

export interface IProviderParam {
  engagement: string;
  providerRole: string;
  providerSelectedCountry?: number[];
  goals: Array<string>;
  languages: Array<string>;
  userId: string;
  enabled: boolean;
}
export function useProviderData({
  engagement,
  providerRole,
  providerSelectedCountry,
  goals,
  languages,
  userId,
  enabled = true,
}: IProviderParam) {
  const {
    data: providerData,
    isLoading: isProviderDataLoading,
    refetch: refetchProviderList,
  } = useFetch<IProviderProfile[]>(
    [
      FetchKeys.providerList,
      engagement,
      providerRole,
      providerSelectedCountry,
      goals,
      languages,
    ],
    () =>
      getProviderList(userId, {
        // timezone,
        engagement,
        role: providerRole,
        countryId: providerSelectedCountry,
        goals,
        languages,
      }),
    {
      refetchOnWindowFocus: false,
      enabled,
    },
  );

  return {
    providerData: providerData?.map((item) => ({
      ...item,
      provider: { ...item.provider, leave: (item as any).leave },
    })),
    isProviderDataLoading,
    refetchProviderList,
  };
}
