import * as React from 'react';
import './Backdrop.scss';

interface IBackdrop {
  mode?: 'glass' | 'dark'
  onClick?: React.MouseEventHandler<HTMLDivElement>,
}

function Backdrop({ mode = 'dark', onClick = () => {} }: IBackdrop) {
  return (
    <div className={`backdrop-container ${mode}`} onClick={onClick} role="region" />
  );
}

export default Backdrop;