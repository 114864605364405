import * as React from 'react';
import { IFilterDataType } from '../../../screens/Provider/Home/ProviderList/types';

export interface IFilterContextData {
  filterData: IFilterDataType;
  isProfile:boolean;
  isRoleFilterDisabled:boolean;
  updateFilterData: (userData: Partial<Omit<IFilterContextData, 'updateFilterData'>>) => void;
  headerRef:React.RefObject<HTMLDivElement>;
}


export const FilterContext = React.createContext<IFilterContextData>(
  {} as IFilterContextData,
);
