import NetworkClient from '@services/Network';
import { IMessageSendType, IOnNewMessage } from '../data-types/ChatBrokerTypes';
import { ConversationListDataType, IConversationsMetadata, SelectedUserMessagesType } from '../data-types/ClientTypes';

abstract class BaseClient {
  protected apiClient;

  constructor() {
    this.apiClient = new NetworkClient();
  }

  abstract onSocketNewMessage(senderID: IOnNewMessage): void;

  // used for fetching a list containing the last message along with metadata of all users

  abstract getConversationList(
    conversationList: IConversationsMetadata['userData'],
  ): Promise<ConversationListDataType[]>;

  // used for fetching the messages of a specified user

  abstract getSelectedUserMessages(conversationId: string, page: number): Promise<SelectedUserMessagesType>;

  // used for sending the messages

  abstract sendMessage(contentToSend: IMessageSendType, conversationId?: string): Promise<string>;

  abstract updateMessageConsumptionStatus(conversationId: string): Promise<boolean>;
}

export default BaseClient;
