import { useFetch } from '@hooks/useFetch';
import { getUserSelfAuthenticationData } from '@services/user';
import { getItemFromLocalStorage } from '@utilities/common/Storage';
import { StorageItems } from '@constants/App';
import { IUser } from '@customTypes/response/IUser';
import { getUserDataForResponder } from '@services/responder';

export default function useGetUserDetails(token: string, enabled:boolean) {
  const userLocalData: any = getItemFromLocalStorage(
    StorageItems.USER_INFO,
    'object',
  ) as IUser;
  let getUserDetailAction = () => getUserSelfAuthenticationData(token);
  if (userLocalData?.isResponder || userLocalData?.isCareNavigator) {
    getUserDetailAction = () => getUserDataForResponder(
      userLocalData.id,
      userLocalData.email,
      token,
    );
  }
  const { data: clientData, isLoading: clientDataLoading } = useFetch<any>(
    ['userDetailsAction', userLocalData],
    getUserDetailAction,
    {
      refetchOnWindowFocus: false,
      enabled,
    },
  );
  return {
    clientData,
    clientDataLoading,
  };
}
