import { Client as TwilioChatClient, Conversation, Message, Paginator } from '@twilio/conversations';
import {
  AttachmentMetaDataTypes,
  ConversationListDataType,
  IConversations,
  IConversationsMetadata,
  MessageResponseType,
  SelectedUserMessagesType,
} from '../data-types/ClientTypes';
import BaseClient from './BaseClient';
import { getConversationsDetails } from '../services/API/CommonConversation';
import {
  MessageChannelTypes,
  MessageContentTypes,
  MessageSendType,
  TwilioMessageType,
} from '../constants/CommonConstants';
import { IMessageSendType, IOnNewMessage } from '../data-types/ChatBrokerTypes';
import { delay } from '../utils/helper';

export default class TwilioClient extends BaseClient {
  client: TwilioChatClient | null;

  private twilioToken: string;

  public allConversationList: ConversationListDataType[] = [];

  public noOfMessagesPerPage: number;

  private userMessages: SelectedUserMessagesType;

  private userChannelObj: Paginator<Message>;

  private currentUserObject: Conversation | null;

  private onNewMessage: IOnNewMessage;

  constructor() {
    super();
    this.client = null;
    this.twilioToken = '';
    this.noOfMessagesPerPage = 10;
    this.userMessages = {
      isNext: true,
      messages: [],
      conversationId: '',
    } as SelectedUserMessagesType;
    this.userChannelObj = {} as Paginator<Message>;
    this.currentUserObject = null;
    this.onNewMessage = () => {};
    this.getToken = this.getToken.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.getClient = this.getClient.bind(this);
    this.getUserLastMessage = this.getUserLastMessage.bind(this);
    this.connectionStatus = this.connectionStatus.bind(this);
    this.onSocketNewMessage = this.onSocketNewMessage.bind(this);
  }

  async getToken(): Promise<string> {
    const resp: IConversations = await getConversationsDetails();
    this.twilioToken = Object.values(resp?.data).reduce(
      (res, val) => (Object.keys(val.chatTokens).length ? val.chatTokens[0] : res || ''),
      '',
    );
    return this.twilioToken;
  }

  // updates the token if it gets expired
  refreshToken = async () => {
    const newToken = await this.getToken();
    this.client?.updateToken(newToken);
  };

  // listening to socket for new message
  onSocketNewMessage(callBack: IOnNewMessage) {
    this.onNewMessage = callBack;
  }

  // initialize twilio client
  getClient = async (): Promise<TwilioChatClient | null> => {
    if (this.client) return this.client;
    const newToken = await this.getToken();
    if (newToken) {
      this.client = new TwilioChatClient(newToken);
      this.client.addListener('tokenAboutToExpire', this.refreshToken);
      this.client.addListener('tokenExpired', this.refreshToken);
      this.client.addListener('messageAdded', async (message) => {
        const msg = await this.createMessageFormat(message);
        const newMsg = { ...msg, conversationId: message.conversation.sid };
        this.onNewMessage(newMsg);
      });
      return this.client;
    }

    return null;
  };

  // checking client connection state
  async connectionStatus(): Promise<string> {
    if (this.client?.connectionState === 'connected') {
      return this.client.connectionState;
    }
    const connectionPromise = new Promise<string>((resolve, reject) => {
      this.client!.on('connectionStateChanged', () => {
        if (this.client?.connectionState === 'connected') {
          resolve(this.client.connectionState);
        } else if (this.client?.connectionState === 'error' || this.client?.connectionState === 'denied') {
          reject(this.client.connectionState);
        }
      });
    });
    return connectionPromise;
  }

  // fetching last message of twilio users
  async getUserLastMessage(conversationId: string, channelId: string): Promise<ConversationListDataType> {
    const currentUserObject = await this.client!.getConversationBySid(channelId);
    let unConsumedMessageCount = await currentUserObject.getUnreadMessagesCount(); // get no. of unread messages
    const initialChannelMessages: Paginator<Message> = await currentUserObject.getMessages(1);
    if (unConsumedMessageCount === null) {
      // hacky solution as twilio requires consumption horizon to set
      await currentUserObject?.updateLastReadMessageIndex(0);
      unConsumedMessageCount = await currentUserObject.getUnreadMessagesCount();
    }
    return {
      conversationId,
      unreadCount: unConsumedMessageCount || 0,
      lastMessageContent: initialChannelMessages.items[0]?.body || '',
      lastMsgCreatedAt: initialChannelMessages.items[0].dateCreated,
      channelType: MessageChannelTypes.TWILIO,
    };
  }

  // fetches twilio conversation list
  async getConversationList(conversationList: IConversationsMetadata['userData']): Promise<ConversationListDataType[]> {
    if (!this.client) {
      await this.getClient();
    }
    if (!this.client) {
      return [];
    }
    await this.connectionStatus();
    if (this.currentUserObject) {
      await delay(500);
    }
    const promises = conversationList.map((conversation) =>
      this.getUserLastMessage(conversation?.conversationId, conversation.channelId),
    );
    const allData = await Promise.all(promises.map((p) => p.catch((e) => e)));
    const validData = allData.filter((data) => !(data instanceof Error));
    if (allData.length !== validData.length) {
      console.warn('Error in twilio', { allData, conversationList });
    }
    this.allConversationList = validData;
    return validData;
  }

  // creates a form data accepted by twilio for sending messages
  // eslint-disable-next-line class-methods-use-this
  private async createMessageFormat(message: Message): Promise<MessageResponseType> {
    const attachments = [] as AttachmentMetaDataTypes[];
    if (message.type === TwilioMessageType.MEDIA) {
      const [media] = message.attachedMedia || [];
      attachments.push({
        type: media.contentType.includes('image') ? MessageContentTypes.image : MessageContentTypes.audio,
        content: (await media.getContentTemporaryUrl()) || '',
      });
    } else if (message.type === TwilioMessageType.TEXT && JSON.stringify(message.attributes) !== '{}') {
      const content = JSON.stringify(message.attributes);
      attachments.push({
        type:
          JSON.parse(content)?.messageType === 'assignment' ? MessageContentTypes.assignment : MessageContentTypes.cta,
        content,
      });
    }
    return {
      createdAt: message.dateCreated!,
      id: message.sid,
      senderId: Number(message.author),
      message: message.body || '',
      attachments,
      deleted: false,
    };
  }

  // fetches messages of selected twilio user

  async getSelectedUserMessages(conversationId: string, page: number): Promise<SelectedUserMessagesType> {
    if (!this.client) {
      await this.getClient();
    }
    await this.connectionStatus();
    this.currentUserObject = await this.client!.getConversationBySid(conversationId);

    if (page === 1) {
      this.userMessages = {
        isNext: true,
        messages: [],
        conversationId,
      } as SelectedUserMessagesType;
      this.userChannelObj = await this.currentUserObject.getMessages(this.noOfMessagesPerPage);
    } else {
      if (!this.userChannelObj.hasPrevPage) {
        return this.userMessages;
      }
      this.userChannelObj = await this.userChannelObj.prevPage();
    }
    const promises = this.userChannelObj.items.map(async (message) => this.createMessageFormat(message));
    const newMsg = (await Promise.all(promises)).reverse();
    this.userMessages.messages.push(...newMsg);
    this.userMessages.isNext = this.userChannelObj.hasPrevPage;

    return this.userMessages;
  }

  // send twilio messages

  async sendMessage(contentToSend: IMessageSendType): Promise<string> {
    if (!this.currentUserObject) {
      return 'error';
    }
    let resp = null;
    if (contentToSend.type === MessageSendType.TEXT) {
      resp = await this.currentUserObject!.sendMessage(contentToSend.data as string);
    } else if (contentToSend.type === MessageSendType.IMAGE) {
      const formData = new FormData();
      formData.append('file', contentToSend.data);
      resp = await this.currentUserObject!.sendMessage(formData);
    } else if (contentToSend.type === MessageSendType.AUDIO) {
      // eslint-disable-next-line
      resp = await this.currentUserObject.sendMessage({
        contentType: 'audio/x-wav',
        media: contentToSend.data,
      });
    }
    await this.currentUserObject.setAllMessagesRead();
    return this.twilioToken;
  }

  async updateMessageConsumptionStatus(
    // eslint-disable-next-line
    conversationId: string
  ): Promise<boolean> {
    if (!this.currentUserObject) {
      return false;
    }
    const resp = await this.currentUserObject.setAllMessagesRead();
    return !resp;
  }
}
