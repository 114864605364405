import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './CancellationModal.scss';
import Popup from '@widgets/Popup';
import { CancellationModalContent } from '@constants/cancellation';
import NoDataPlaceholder from '@components/NoDataPlaceholder';
import { useEditOnsiteSessions } from '@hooks/useOnsiteData/useOnsiteData';
import { EventActions, EventCategories, EventNames } from '@constants/analytics';
import Analytics from '@services/Analytics';
import useTracking from '@hooks/useTracking';
import OnsiteCancellationReasons from './Reasons';
import cancelConfirmIcon from '../../../../assets/cancelConfirmationIllustration.svg';

export default function CancellationModal({
  onClose,
  providerName,
  meetingId,
}: {
  onClose: () => void;
  providerName: string;
  meetingId: string;
}) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const otherReasonRef = React.useRef<string>('');

  const [currentModalName, setCurrentModalName] = React.useState<string>(
    CancellationModalContent.CANCELLATION_REASONS,
  );
  const onCancelSuccess = () => {
    setCurrentModalName(CancellationModalContent.CANCELLATION_CONFIRMATION);
  };

  const { cancelOnsiteSession } =
    useEditOnsiteSessions(undefined, onCancelSuccess);

  const handleOtherReasonChange = (value: string) => { otherReasonRef.current = value; };

  const handleCancellationReasonContinue = ({
    reasonCode,
    otherReason,
  }: {
    reasonCode: string;
    otherReason: string;
  }) => {
    track(EventNames.cancelSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.cancelSessionConfirm,
      eventLabel: 'cancel_session_confirm',
      userType: Analytics.getUserType(),
      eventValue: reasonCode,
      eventSource: 'onsite',
    });
    cancelOnsiteSession({
      type: reasonCode,
      otherReason,
      meetingId,
    });
  };

  const currentModalContent = (modalName: string) => {
    switch (modalName) {
      case CancellationModalContent.CANCELLATION_REASONS:
        return (
          <OnsiteCancellationReasons
            handleContinueClick={(reasonCode: string) =>
              handleCancellationReasonContinue({
                otherReason: otherReasonRef.current,
                reasonCode,
              })
            }
            onOtherReason={handleOtherReasonChange}
            header={t('CANCEL_SESSION')}
            subHeader={t('CANCEL_REASON_SUBHEADER')}
          />
        );

      case CancellationModalContent.CANCELLATION_CONFIRMATION:
        return (
          <NoDataPlaceholder
            illustration={cancelConfirmIcon}
            headerText={`We’ve cancelled your session with ${providerName}`}
            subHeaderText=""
            onClick={() => onClose()}
          />
        );

      default:
        return 'not implemented';
    }
  };
  return (
    <Popup className="cancellation-modal-wrapper" onClose={onClose}>
      {currentModalContent(currentModalName)}
    </Popup>
  );
}
