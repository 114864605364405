import { FetchKeys } from '@constants/queries';
import { IUser } from '@customTypes/response/IUser';
import { useFetch } from '@hooks/useFetch';
import { getUserDetail } from '@services/user';

export function useUserDetail(
  userToken: string,
  {
    onSuccess,
    onError,
    enabled = true,
  }: {
    onSuccess?: (data: IUser) => void;
    onError?: (e: unknown) => void;
    enabled?: boolean;
  },
) {
  return useFetch<IUser>(
    [FetchKeys.userDetails],
    () => getUserDetail(userToken || ''),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      enabled,
    },
  );
}
