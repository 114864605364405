import * as React from 'react';
import moment from 'moment';
import Button from '@widgets/Button';
import Tags from '@widgets/Tags';
import Typography from '@widgets/Typography';
import { getTimeZone } from '@utilities/common/Date';
import { ProviderRoles } from '@constants/provider';
import KebabMenu from '@widgets/KebabMenu';
import './Sessioncard.scss';
import { getImageUri } from '@utilities/common/Path';
import UnorderedList from '@widgets/UnorderedList';
import calendarIcon from '../../../../assets/onsite-calendar.svg';
import locationIcon from '../../../../assets/location-pin.svg';
import clockIcon from '../../MeetingProfile/assets/clock.svg';

export interface ISessionCard {
  providerDetails: {
    name: string;
    profileImage: string;
  };
  bookingType: string;
  scheduledStartTime: string;
  scheduledEndTime: string;
  locationInfo: {
    roomGuide: string;
    room: string;
  };
  kebabMenuOptions: {
    label: string;
    onClick: (id:string) => void;
    value: string;
    disabled?: boolean;
  }[];
  btnLabel?: string;
  sessionId: string;
  handleSessionCardClick: (data?:any) => void;
}

export default function SessionCard({
  providerDetails,
  bookingType,
  scheduledStartTime,
  scheduledEndTime,
  locationInfo,
  kebabMenuOptions,
  btnLabel = 'View Details',
  sessionId,
  handleSessionCardClick,
}: ISessionCard) {
  const { name, profileImage } = providerDetails;
  const medifiedKababMenuOptions = kebabMenuOptions.map((option) => ({
    ...option,
    onClick: () => option.onClick(sessionId),
  }));
  
  const detailListItems: { label: string; icon: string; redirectURL?: string }[] = [
    {
      label: moment.unix(Number(scheduledStartTime)).format('MMM DD, YYYY'),
      icon: calendarIcon,
    },
    {
      label: `${moment
        .unix(Number(scheduledStartTime))
        .format('hh:mm A')} - ${moment
        .unix(Number(scheduledEndTime))
        .format('hh:mm A')} ${moment
        .unix(Number(scheduledStartTime))
        .tz(getTimeZone())
        .format('z')}`,
      icon: clockIcon,
    },
  ];

  if (JSON.stringify(locationInfo) !== '{}') {
    detailListItems.push({
      label: locationInfo.room,
      icon: locationIcon,
      redirectURL: locationInfo?.roomGuide,
    });
  }
  return (
    <section className="booking-item-wrapper">
      <section className="booking-item">
        <section className="booking-card-header">
          <Typography size={16} weight="500">
            {`1:1 Counselling with ${name}`}
          </Typography>
          <Tags
            tags={
              bookingType === ProviderRoles.ONSITE
                ? 'Onsite Day'
                : 'Virtual Counselling'
            }
            variant="pill"
            backgroundColor={
              bookingType === ProviderRoles.ONSITE ? '#E2F5F4' : '#F5F0E2'
            }
            textColor={
              bookingType === ProviderRoles.ONSITE ? '#4D756E' : '#756A4D'
            }
          />
        </section>
        <UnorderedList items={detailListItems} />
        <section className="provider-info">
          <img
            className="profile"
            src={getImageUri(profileImage)}
            alt="john doe"
          />
          <Typography size={10} weight="500">
            {name}
          </Typography>
        </section>

        {bookingType !== ProviderRoles.ONSITE && (
          <Typography size={10} weight="400" withColor="#828B9C">
            Your clinician might be wrapping up a session prior to yours. If
            they have yet to join the session within 5 minutes, kindly contact
            tiktok@intellect.co immediately.
          </Typography>
        )}
      </section>
      <section className="control-items">
        <Button
          label={btnLabel}
          variant="secondary"
          onClick={handleSessionCardClick}
          // width={window.innerWidth <= 413 ? '100%' : ''}
        />

        <KebabMenu iconPosition="vertical" options={medifiedKababMenuOptions} />
      </section>
    </section>
  );
}
