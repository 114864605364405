import React from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import Loader from '@widgets/Loader';
import { addItemToLocalStorage } from '@utilities/common/Storage';
import { StorageItems } from '@constants/App';
import Typography from '@widgets/Typography';
import SOS from './SOS';
import SOSHelpline from './Helpline';
import Layout from './Layout';
import { useUserDetail } from './hooks/useUserDetail';
import { updateAppLanguage } from '../../../i18n';

function SOSHome() {
  const [searchParams] = useSearchParams();

  const userToken = searchParams.get('token');
  const [organisationConfig, setOrganisationConfig] = React.useState<any>({});
  React.useEffect(() => {
    const locale = searchParams.get('locale');
    if (locale) {
      addItemToLocalStorage(StorageItems.APP_LANGUAGE, locale);
      updateAppLanguage();
    }
  }, []);

  const { isLoading } = useUserDetail(userToken || '', {
    onSuccess(data) {
      setOrganisationConfig({
        organisationId: data.memberships[0]?.organisationTeam.organisationId,
        ...data.config,
      });
    },
    onError: () => {
      setOrganisationConfig({});
    },
  });
  if (!userToken) {
    return <Typography size={20} weight="700" textAlign="center">
      Unable to authenticate user
    </Typography>;
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Layout>
      <Routes>
        <Route
          path="/helpline"
          element={
            <SOSHelpline
              userToken={userToken || ''}
              isIntellectEAP={organisationConfig.helplineCTA !== 'company'}
              country={searchParams.get('country')}
            />
          }
        />
        <Route
          path="/"
          element={
            <SOS
              isIntellectEAP={organisationConfig.helplineCTA !== 'company'}
              userToken={userToken || ''}
              country={searchParams.get('country')}
              locale={searchParams.get('locale')}
              source={searchParams.get('source')}
            />
          }
        />
      </Routes>
    </Layout>
  );
}
export default SOSHome;
