import { FetchKeys } from '@constants/queries';
import { IProviderService } from '@customTypes/response/provider';
import { PromiseCallbacks, useFetch } from '@hooks/useFetch';
import { getProviderServices } from '@services/provider';

function useProviderServices(providerId: string, { onSuccess }: PromiseCallbacks) {
  return useFetch<IProviderService>(
    [FetchKeys.providerServices, providerId],
    () => getProviderServices(providerId),
    {
      onSuccess,
      refetchOnWindowFocus: false,
    },
  );
}

export default useProviderServices;
