import * as React from 'react';
import './ProviderDetails.scss';
import { IProviderDetails } from '@hooks/useOnsiteData/types';
import { getImageUri } from '@utilities/common/Path';
import Tags from '@widgets/Tags';
import Typography from '@widgets/Typography';

export interface IProviderDetailsProps {
  provider?: IProviderDetails;
  providerExpertise?: Array<string>;
  providerOverview?: string;
}

export default function ProviderDetails({
  provider,
  providerExpertise,
  providerOverview,
}: IProviderDetailsProps) {
  return (
    <section className="coach-details-wrapper">
      <Typography size={24} weight="500">
        {`About ${provider?.name}`}
      </Typography>
      <section className="coach-details">
        <img
          src={getImageUri(provider?.profileImage as string)}
          alt="coach-profile-pic"
        />
        <section className="coach-info">
          <Typography size={14} weight="400" withColor="#6c6d6e">
            {providerOverview}
          </Typography>
          {!!providerExpertise?.length && (
            <section className="coach-specialisation">
              <Typography size={16} weight="500">
                Specialisations
              </Typography>
              <Tags
                tags={providerExpertise}
                variant="pill"
                backgroundColor="#E3F1FF"
                textColor="#6c6d6e"
                textSize={14}
                height="28px"
                fontWeight="400"
              />
            </section>
          )}
        </section>
      </section>
    </section>
  );
}
