import { IVariables } from '@customTypes/response/IVariables';

export const ConfigurationVariables: { [key: string]: IVariables } = {
  care: {
    isCare: true,
    showUserLocationFilter: true,
    showMeetingTypeFilter: true,
    providerRoleDefault: '1',
  },
  demo: {
    isCare: false,
    showUserLocationFilter: false,
    showMeetingTypeFilter: false,
    providerRoleDefault: '0',
    faqs: [
      {
        title: 'What is executive coaching?',
        message: 'Here at Intellect, we believe that coaching is for anyone and everyone. <br />Executive coaching in particular is designed to enhance the leadership skills and executive functioning of motivated, achievement-oriented professionals looking to excel in their current or potential job roles.<br />The coaching sessions provide a confidential and safe space for Executives to plan and manage expectations, mitigate risks, refine communication and challenge their thoughts and actions.',
      },
      {
        title: 'Are the services provided free of charge?',
        message: 'Yes, with a maximum of 3 complimentary executive coaching sessions redeemable until 31 May 2023.',
      },
      {
        title: 'Can I reschedule my coaching session?',
        message: 'Reach out to us at <a href="mailto:executive@intellect.co">executive@intellect.co</a> (at least 24 hours prior) to your confirmed session and we will make the necessary arrangements.',
      },
      {
        title: 'Can I change my coach?',
        message: 'Most definitely! We believe that it’s important to work with a coach that is best suited for you. If you feel that the coach is not the right fit, you can choose a different coach immediately on <a href="https://care.intellect.co">care.intellect.co</a><br />> Go to  <a href="https://care.intellect.co">care.intellect.co</a> and login with your email address and access code.<br />> View your coach’s details and upcoming appointment (if any).<br />> Select “unmatch” to change coaches.',
      },
      {
        title: 'Can I access the coaching session via Zoom?',
        message: 'No. The platform to join the coaching session will be on <a href="https://care.intellect.co">care.intellect.co</a>. Please access the coaching session via the link in your confirmation email.',
      },
      {
        title: 'Can I give access to my partner/dependent/friend/colleague to attend the coaching session instead?',
        message: 'Unfortunately not. As part of our partnership with WorkWell Leaders and their “Be Well” program, we wanted to extend this executive coaching opportunity exclusively to only attendees of the Gala Dinner.<br />That said, if your colleague/report/HR team wants to trial the Intellect platform for potential use in the company and/or wishes to find out more about Intellect in any way, please connect them with us. We would be happy to share more about our solution and discuss how we might extend complimentary access codes to the app.',
      },
      {
        title: 'Who should I speak to arrange a demo or learn more about Intellect? / How do I refer Intellect to my HR team/wellbeing team?',
        message: 'Please reach out to <a href="mailto:executive@intellect.co">executive@intellect.co</a>. We would be happy to share more about our solution and discuss how we might extend complimentary access codes to other members of your team.',
      },
      {
        title: 'Is this what the Intellect platform looks like?',
        message: 'This executive coaching experience is parallel to our core solution.<br />Intellect’s enterprise solution is accessible on an award-winning app, an EAP microsite, and via a helpline with trained responders. These are some of the other features available:<br /><br />● Self-care tools with guided sessions and journals localised in 13 Asian languages<br />● 1-to-1 coaching, counselling, and clinical sessions<br />● 24/7 helpline and triaging process available in 17 countries<br />● On-demand webinars<br />● Dedicated client success representative with an onboarding and engagement plan<br /><br />For more information, contact us at <a href="mailto:executive@intellect.co">executive@intellect.co</a>',
      },
    ],
  },
};
