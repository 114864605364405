import * as React from 'react';
import Typography from '@widgets/Typography';
import './DateBadge.scss';

interface DateBadgeProps {
  date: string;
  month: string;
}

export default function DateBadge({ date, month }: DateBadgeProps) {
  return (
    <section className="date-badge">
      <section className="month">
        <Typography size={14} weight="500" withColor="#fff">
          {month}
        </Typography>
      </section>
      <section className="date">
        <Typography size={27} weight="600">
          {date}
        </Typography>
      </section>
    </section>
  );
}
