import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CssSize } from '@widgets/ClickableCard/types';
import Typography from '../Typography';
import './SelectBox.scss';
import chevronIcon from './assets/placeholder.svg';
import tickIcon from './assets/tick.svg';
import useOutsideClickHandler from '../../../utilities/hooks/useOutsideClickHandler';

interface ISelectBox {
  id?: string;
  values: { label: string; value: string }[];
  onChange?: (value: string) => void;
  selectedValue: string | null;
  disabled?: boolean;
  fontSize?: number;
  fullWidth?: boolean;
  placeholder?: string;
  minWidth?: CssSize,
}

function SelectBox({
  id,
  values,
  onChange,
  selectedValue,
  disabled = false,
  fontSize = 12,
  fullWidth = false,
  placeholder = '',
  minWidth,
}: ISelectBox) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [showItems, setShowItems] = React.useState<boolean>(false);

  useOutsideClickHandler(parentRef, () => setShowItems(false));

  const onDropdownItemClick = (value: string) => {
    setShowItems(false);
    onChange?.(value);
  };

  const getStyles = () => {
    const styles: React.CSSProperties = {
      minWidth,
    };
    return styles;
  };

  return (
    <motion.div
      ref={parentRef}
      className={`select-box ${disabled ? 'disabled' : ''}`}
      style={fullWidth ? { width: '100%' } : getStyles()}
    >
      <div
        id={id}
        className={`placeholder-container ${disabled ? 'disabled' : ''}`}
        role="button"
        tabIndex={0}
        onClick={() => {
          if (!disabled) {
            setShowItems(true);
          }
        }}
      >
        {!disabled && (
          <img className="chevron" src={chevronIcon} alt="select" />
        )}
        <div className="select-placeholder">
          {selectedValue ? (
            <Typography size={fontSize} weight="400">
              {values.find(({ value }) => value === selectedValue)?.label}
            </Typography>
          ) : (
            <Typography size={fontSize} weight="400" color="secondary">
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
      <AnimatePresence>
        {showItems && (
          <motion.div
            className="v2-dropdown"
            style={fullWidth ? { width: '100%' } : getStyles()}
            initial={{ opacity: 0, top: '-70px' }}
            animate={{ opacity: 1, top: '50px' }}
            exit={{ opacity: 0, top: '-70px' }}
            transition={{ duration: 0.4 }}
          >
            {values.map(({ label, value }, index) => {
              const selected = value === selectedValue;
              return (
                <motion.div
                  id={`option-${label.replaceAll(' ', '')}`}
                  className={`dropdown-item ${selected ? 'selected' : ''}`}
                  role="button"
                  tabIndex={index}
                  key={value}
                  onClick={() => onDropdownItemClick(value)}
                  initial={{ backgroundColor: '#fff' }}
                  whileHover={{ backgroundColor: 'rgba(233, 236, 238, 0.5)' }}
                  animate={
                    selected ? { backgroundColor: 'rgb(233, 236, 238)' } : {}
                  }
                  transition={{ duration: 0.5 }}
                >
                  <Typography size={fontSize} weight="400">
                    {label}
                  </Typography>
                  {selected && (
                    <motion.img
                      src={tickIcon}
                      alt="selected"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                    />
                  )}
                </motion.div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default SelectBox;
