import * as React from 'react';
import './DropDown.scss';
import { motion } from 'framer-motion';
import { IMenuItem } from '@widgets/ContextMenu/ContextMenu';
import bottomIcon from '../../../assets/bottom.svg';
import Typography from '../Typography';
import ContextMenu from '../ContextMenu';

interface IDropDown {
  icon?: string;
  initialValue?: IMenuItem;
  heading: string;
  values: Array<IMenuItem>;
  menuHeading?: string;
  onChange: (menuItem: IMenuItem) => void;
  className?: string;
}

function DropDown({
  icon,
  initialValue,
  values,
  onChange,
  heading,
  menuHeading,
  className = '',
}: IDropDown) {
  // const [value, setValue] = React.useState<string>(
  //   (): string => initialValue || values[0],
  // );
  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const onMenuItemChange = (itemValue: IMenuItem) => {
    if (itemValue) {
      // setValue(itemValue);
      onChange(itemValue);
      setShowMenu(false);
    }
  };

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  return (
    <div className={`dropdown ${className}`}>
      <motion.div
        className="action"
        onClick={toggleMenu}
        role="button"
        tabIndex={0}
        whileTap={{ scale: 0.9 }}
      >
        {icon && <img src={icon} alt="icon" />}
        <Typography weight="600" size={14}>
          {heading}
        </Typography>
        <img src={bottomIcon} alt="more" />
      </motion.div>
      <ContextMenu
        menuItems={values}
        onChange={onMenuItemChange}
        initialMenuItem={initialValue}
        show={showMenu}
        heading={menuHeading}
        position={{ top: '90%', right: '0%' }}
      />
    </div>
  );
}

export default DropDown;
