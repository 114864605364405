import styled from 'styled-components';

export const StyledNoConversationContainer = styled.section`
  flex-direction: column;
  margin: auto;
  align-items: center;
  display: flex;
  align-items: center;
	justify-content: center;
  width: 80%;
`;

export const StyledImageSection = styled.section`
  width: 95%;
	text-align: center;
`;

export const StyledImg = styled.img`
  margin: auto;
  padding-bottom: 20px;
	width: 120px;
	height: 120px;
`;
