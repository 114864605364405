import useTracking from '@hooks/useTracking';
import Analytics from '@services/Analytics';
import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from './utilities/hooks/useAuth';

function ProtectedRoutes() {
  const { isLoggedIn, user: userDetails } = useAuth();
  const redirectUrl = window.location.pathname;
  const { user } = useTracking();

  const setupUserProfileForAnalytics = () => {
    const { id, createdAt, updatedAt } = userDetails;
    user.initialize(id);
    user.setProperties({
      userId: id,
      created: createdAt,
      updated: updatedAt,
      userType: Analytics.getUserType(),
    });
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      setupUserProfileForAnalytics();
    }
  }, []);

  return isLoggedIn ? <Outlet /> : <Navigate to={`/login?redirectUrl=${redirectUrl}`} />;
}

export default ProtectedRoutes;
