import { Variants } from 'framer-motion';

type ComingInModes = 'top' | 'bottom';

export const buttonVariants: Variants = {
  hover: { scale: 1.1 },
  tap: { scale: 0.8 },
};

export enum ButtonVariantTypes {
  tap = 'tap',
  hover = 'hover',
}

export const listVariants: Variants = {
  hidden: {
    opacity: 0,
    y: '-10px',
  },
  visible: (index) => ({
    opacity: 1,
    y: '0',
    transition: {
      delay: index * 0.3,
    },
  }),
} as const;

export const fade: Variants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  out: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const listItemSlidingFromRight: Variants = {
  hidden: {
    opacity: 0,
    x: '100px',
  },
  visible: (index) => ({
    opacity: 1,
    x: '0',
    transition: {
      delay: index * 0.3,
      duration: 0.5,
    },
  }),
} as const;

export const comingIn: Variants = {
  hidden: (mode: ComingInModes) => ({
    opacity: 0,
    y: mode === 'bottom' ? '-20px' : '20px',
    transition: {
      duration: 0.5,
    },
  }),
  visible: () => ({
    opacity: 1,
    y: '0px',
    transition: {
      duration: 0.5,
    },
  }),
  out: (mode: ComingInModes) => ({
    opacity: 0,
    y: mode === 'bottom' ? '-20px' : '20px',
    transition: {
      duration: 0.5,
    },
  }),
};
