import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './CtaMessageItem.scss';
import Button from '@widgets/Button';
import Typography from '@widgets/Typography';
import { VideoRoomSDKs } from '@constants/App';
import useLayout from '@hooks/useLayout';
import toast from 'react-hot-toast';
import Loader from '@widgets/Loader';
import { openNewTab } from '@utilities/common/Path';
import moment from 'moment';
import useMeeting from '../../../../screens/Room/hooks/useMeeting';
import { getDailyCoUrlFromRoomId } from '../../../../screens/Room/utils';

interface IAssignmentMessageItemProps {
  ctaContent: string;
  message: string;
  source: string;
}

function CtaMessageItem({
  ctaContent,
  message,
  source,
}: IAssignmentMessageItemProps) {
  // Initial Values
  const { t } = useTranslation();
  // Functions
  // * Function to get the image uri
  const parsedMessage = JSON.parse(ctaContent);
  const { messageType, meetingId, type } = parsedMessage || {};
  const { updateChatWindowSettings } = useLayout();
  const { isLoading: isLoadingMeetingJoinData, refetch: fetchMeetingJoinData } =
    useMeeting(
      meetingId,
      VideoRoomSDKs.dailyCo,
      false,
      {
        onSuccess: (meetingData) => {
          openNewTab(
            `room/?roomUrl=${getDailyCoUrlFromRoomId(
              meetingData?.meeting?.locationRef,
            )}`,
            'ctaMeeting',
          );
          updateChatWindowSettings({ show: false });
        },
        onError: (error: Error) => {
          toast(error.message);
        },
      },
      ['ctaMessage'],
    );
  const joinSession = () => {
    if (type === VideoRoomSDKs.dailyCo) {
      fetchMeetingJoinData();
    } else {
      openNewTab(`rooms/${type}/${meetingId}`, 'ctaMeeting');
      updateChatWindowSettings({ show: false });
    }
  };
  return (
    <div
      className={`cta-assignment-message italic rounded-md ${
        source === 'outbound' ? 'text-white' : 'text-white'
      }`}
    >
      {isLoadingMeetingJoinData && <Loader useFullPage withBackdrop /> }
      <div className="assignment-msg-contents">
        <Typography
          weight="400"
          size={12}
          withItalics
          withColor={source === 'outbound' ? '#fff' : '#48515A'}
        >
          {message}
        </Typography>
      </div>
      {messageType === 'join' && (
        <Button
          width="100%"
          variant={source === 'outbound' ? 'blue' : 'primary'}
          label={t('joinSession')}
          onClick={() => joinSession()}
          disabled={moment().unix() > parsedMessage?.validity}
        />
      )}
    </div>
  );
}

export default CtaMessageItem;
