import * as React from 'react';
import './AudioMessagePlayer.scss';
import WaveSurfer from 'wavesurfer.js';
import { motion } from 'framer-motion';
import { buttonVariants, ButtonVariantTypes } from '@constants/variants';
import { useTranslation } from 'react-i18next';
import playIcon from '../../assets/play.svg';
import playDarkIcon from '../../assets/play-dark.svg';
import pauseDarkIcon from '../../assets/pause-dark.svg';
import pauseIcon from '../../assets/pause.svg';
import recordIcon from '../../assets/record-voice-white.svg';
import recordIconDark from '../../assets/record-voice-dark.svg';
import Typography from '../../../../widgets/Typography';
import { ChatSource } from '../../constants/CommonConstants';
import { getVoiceNoteElapsedTime } from '../../utils/helper';

interface IAudioMessagePlayerProps {
  url: string;
  source: ChatSource;
}

const styles = {
  waveColor: {
    inbound: '#1A2631',
    outbound: '#ffffff',
  },
  cursorColor: {
    inbound: '#e9eaeb',
    outbound: '#3c566f',
  },
  progressColor: {
    inbound: '#48515a',
    outbound: '#808080',
  },
  playIcon: {
    inbound: playDarkIcon,
    outbound: playIcon,
  },
  pauseIcon: {
    inbound: pauseDarkIcon,
    outbound: pauseIcon,
  },
  recordIcon: {
    inbound: recordIconDark,
    outbound: recordIcon,
  },
  fontColor: {
    inbound: 'primary',
    outbound: 'light',
  },
};

function AudioMessagePlayer({ url, source }: IAudioMessagePlayerProps) {
  const { t } = useTranslation();
  const waveSurfer = React.useRef<WaveSurfer>();
  const waveSurferContainer = React.useRef<HTMLDivElement>(null);
  const [elapsedTimeSeconds, setElapsedTimeSeconds] = React.useState<number>(0);
  const [isAudioPlaying, setAudioPlaying] = React.useState<boolean>(false);

  const onPlayPauseClick = () => {
    setAudioPlaying((prev) => !prev);
  };

  // expensive DOM operation, don't wanna re-render for every elapsedTimeSeconds state updates.
  const audioWaveForm = React.useMemo(() => <div className="wave-surfer" ref={waveSurferContainer} />, []);

  React.useEffect(() => {
    if (waveSurferContainer.current) {
      waveSurfer.current = WaveSurfer.create({
        container: waveSurferContainer.current,
        waveColor: styles.waveColor[source],
        progressColor: styles.progressColor[source],
        cursorColor: styles.cursorColor[source],
        barWidth: 4,
        barRadius: 4,
        barGap: 4,
        barMinHeight: 5,
        hideScrollbar: true,
        interact: false,
        loopSelection: false,
        responsive: true,
        height: 40,
      });
    }
  }, [source]);

  React.useEffect(() => {
    if (url) {
      waveSurfer.current?.load(url);
      waveSurfer.current?.on('audioprocess', () => {
        if (waveSurfer.current?.isPlaying()) {
          const currentTime = waveSurfer.current.getCurrentTime();
          setElapsedTimeSeconds(currentTime);
        }
      });
      waveSurfer.current?.on('ready', () => {
        const duration = waveSurfer.current?.getDuration();
        setElapsedTimeSeconds(duration || 0);
      });
    }
  }, [url]);

  React.useEffect(() => {
    if (isAudioPlaying) {
      waveSurfer.current?.play();
    } else {
      waveSurfer.current?.pause();
    }
  }, [isAudioPlaying]);

  return (
    <div className="audio-message-player">
      <div className="left-controls">
        <motion.img
          src={isAudioPlaying ? styles.pauseIcon[source] : styles.playIcon[source]}
          alt={isAudioPlaying ? 'pause' : 'play'}
          variants={buttonVariants}
          whileTap={ButtonVariantTypes.tap}
          whileHover={ButtonVariantTypes.hover}
          onClick={onPlayPauseClick}
        />
      </div>
      <div className="voice-info">
        <div className="info-item">
          <Typography color={source === 'inbound' ? 'primary' : 'light'} weight="600" size={12}>
            {t('voiceMsg')}
          </Typography>
          <img src={styles.recordIcon[source]} alt="voice note" />
        </div>
        <div className="info-item">
          {audioWaveForm}
          <Typography color={source === 'inbound' ? 'primary' : 'light'} weight="400" size={10}>
            {getVoiceNoteElapsedTime(elapsedTimeSeconds)}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default AudioMessagePlayer;
