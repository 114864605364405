import Config from '../../shared/config';
import { validUrlRegex } from './Validators';

export const getCurrentPath = (): string => window.location.pathname; // TODO check RRv6 APIs that supports classes
export const productionHostname = 'care.intellect.co';
export const beautifyUrl = (url: string, replacements: string[], queryParams?: any): string => {
  let beautifiedUrl = url;
  for (let i = 0; i < replacements.length; i += 1) {
    beautifiedUrl = beautifiedUrl.replace('{?}', replacements[i]);
  }
  if (queryParams) {
    const query = Object.keys(queryParams).map((key: string) => `${key}=${encodeURIComponent(queryParams[key])}`).join('&');
    beautifiedUrl = `${beautifiedUrl}?${query}`;
  }
  return beautifiedUrl;
};

export const gotoRoute = (path: string) => {
  window.location.href = path;
};

export const refreshApp = () => {
  window.location.reload();
};

export const openNewTab = (path: string, target?:string) => {
  setTimeout(() => {
    window.open(`${window.location.origin}/${path}`, target);
  });
};

export const isProduction = () => window.location.hostname === productionHostname;
export const getImageUri = (imagePath: string) => beautifyUrl(Config.api.media.getImage, [imagePath]);

export const openExternalUrl = (url: string) => {
  window.open(
    validUrlRegex.test(url)
      ? url
      : `https://${url}`,
    '_blank',
    'noopener,noreferrer',
  );
};
