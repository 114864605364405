import moment from 'moment';
import { round } from 'lodash';
import { MessageContentTypes } from '../constants/CommonConstants';
import { AttachmentMetaDataTypes } from '../data-types/ClientTypes';
import { AllConversationListType } from '../data-types/ChatBrokerTypes';

export function separateChatsByDayGap(
  chatArr: AllConversationListType[],
  dayGap: number,
  startIndex: number,
  endIndex: number,
): number {
  const midIndex = Math.floor(startIndex + (endIndex - startIndex) / 2);
  const midValue = moment(chatArr[midIndex].lastMessageDate);
  if (endIndex === midIndex) {
    return midIndex + 1;
  }
  if (midIndex === 0) {
    return 0;
  }

  let lowIndex = startIndex;
  let highIndex = endIndex;
  if (moment().diff(midValue, 'days') === dayGap) {
    return midIndex + 1;
  }
  if (moment().diff(midValue, 'days') < dayGap) {
    lowIndex = midIndex + 1;
  } else {
    highIndex = midIndex - 1;
  }

  return separateChatsByDayGap(chatArr, dayGap, lowIndex, highIndex);
}

export const getMessageContentType = (attachmentData: AttachmentMetaDataTypes[]) => {
  const [attachmentType] = attachmentData;
  if (!attachmentType) return MessageContentTypes.text;
  return attachmentType.type;
};

export function delay(time: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, time);
  });
}

export const getVoiceNoteElapsedTime = (elapsedSeconds: number) => moment.utc(elapsedSeconds * 1000).format('mm:ss');

export const byteToMegaByte = (byte: number) => round(byte / (1024 * 1024), 2);