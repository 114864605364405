import React from 'react';
import { IProviderMeeting } from '@customTypes/response/meeting';
import Popup from '@widgets/Popup';
import Loader from '@widgets/Loader';
import { REASON_TYPE_USER_ID } from '@constants/user';
import useTracking from '@hooks/useTracking';
import {
  BookSessionSources,
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import { ProviderRoles } from '@constants/provider';
import toast from 'react-hot-toast';
import useAuth from '@hooks/useAuth';
import { CancellationPopupContentType } from './Confirmed.type';
import CancelationWarningModal from './CancellationWarningModal';
import Success from './Success';
import WarningModalV2 from './WarningModalV2';
import useCancellation from '../hooks/useCancellation';
import CancellationReasons from './Reasons';
import './SessionCancelPopup.scss';

interface ICancelConfirmedSession {
  onClose: (canRefetch: boolean, shouldTrack?: boolean) => void;
  meetingData: IProviderMeeting & {
    type: 'cancel' | 'reschedule' | 'update-room';
  };
  isWithin24hrs: boolean;
}

function CancelConfirmedSession({
  onClose,
  meetingData,
  isWithin24hrs,
}: ICancelConfirmedSession) {
  const [selectedReason, setSelectedReason] = React.useState<{
    type: string;
    otherReason: string;
    canCancel: boolean;
    index?: string;
  }>({ type: '', otherReason: '', canCancel: false });
  const { track } = useTracking();
  const [contentType, setContentType] =
    React.useState<keyof CancellationPopupContentType>('warning');
  const handleShowNext = (type: keyof CancellationPopupContentType) => {
    setContentType(type);
  };
  const { user } = useAuth();

  const {
    currentUserData: { userData: clientData },
  } = useCurrentUserContext();

  const { mutate: cancelMeeting, isLoading: cancelationLoadingState } =
    useCancellation({
      onSuccess: () => {
        handleShowNext('success');
      },
      onError: (e) => {
        toast(e?.message || 'Unable to cancel the meeting');
      },
    });

  const handleCancel = () => {
    if (selectedReason?.type.length) {
      track(EventNames.cancelSession, {
        eventLabel: Analytics.getUserType(),
        eventCategory: EventCategories.cancelSessionConfirm,
        eventAction: EventActions.click,
        eventValue: `cancel_session_${selectedReason.index}`,
        source: BookSessionSources.providerProfile,
        eventSource: 'virtual',
      });
      const reason = {
        type: selectedReason.type,
        otherReason: selectedReason.otherReason,
      };
      cancelMeeting({
        meetingId: meetingData.id,
        userId:
          user.isCareNavigator &&
          meetingData.providerRole === ProviderRoles.INTELLECTCLINICOFFSITE
            ? clientData.id
            : null,
        reason,
      });
    }
  };

  const handleCancelWithIn24hrs = () => {
    setSelectedReason((prev) => ({ ...prev, canCancel: true }));
  };

  const handleCancelImmediately = (payload: {
    type: string;
    otherReason: string;
    index: string;
  }) => {
    setSelectedReason(() => ({ ...payload, canCancel: !isWithin24hrs }));
    if (isWithin24hrs) handleShowNext('within24Hrs');
  };

  const popupContent: CancellationPopupContentType = {
    warning: (
      <CancelationWarningModal
        showNextPopup={handleShowNext}
        onClose={onClose}
      />
    ),
    reason: (
      <CancellationReasons
        userType={REASON_TYPE_USER_ID}
        handleCancel={handleCancelImmediately}
      />
    ),
    success: <Success handleOkay={() => onClose(true, false)} />,
    within24Hrs: (
      <WarningModalV2
        handleClose={() => onClose(false, false)}
        handleCancel={handleCancelWithIn24hrs}
      />
    ),
  };

  React.useEffect(() => {
    if (selectedReason.canCancel) {
      handleCancel();
    }
  }, [selectedReason]);
  if (cancelationLoadingState) {
    return <Loader useFullPage withBackdrop />;
  }
  return (
    <Popup
      className="confirmed-cancellation-popup"
      showCloseIcon
      withBackdrop
      onClose={() => onClose(contentType.includes('success'), true)}
    >
      <div className="cancelled-confirmed-session-wrapper">
        {popupContent[contentType]}
      </div>
    </Popup>
  );
}

export default CancelConfirmedSession;
