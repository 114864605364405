import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './Header.scss';
import DropDown from '@widgets/DropDown';
import { IMenuItem } from '@widgets/ContextMenu/ContextMenu';
import useTracking from '@hooks/useTracking';
import Analytics from '@services/Analytics';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Typography from '../../widgets/Typography';
import useAuth from '../../../utilities/hooks/useAuth';
import profileAvatarIcon from '../../../assets/profile-avatar.svg';
import avatarIcon from '../../../assets/avatar2.svg';
import logoutIcon from '../../../assets/logout.svg';

const dropdownMenuItems: IMenuItem[] = [
  {
    id: '1',
    icon: avatarIcon,
    value: 'Manage my information',
  },
  {
    id: '2',
    icon: logoutIcon,
    value: 'Logout',
    fontColor: 'error',
  },
];

function Header() {
  const { user, invalidateUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { track } = useTracking();

  const { name, email, isEmployee } = user;
  const [isToggleOpen, setIsToggleOpen] = React.useState(false);

  const handleToggleOpen = () => {
    setIsToggleOpen(!isToggleOpen);
  };
  const onChange = (data: IMenuItem) => {
    switch (data.id) {
      case '1':
        navigate('/manage-profile');
        break;
      case '2':
        invalidateUser();
        break;
      default:
        console.log('data', data.id);
    }
    setIsToggleOpen(false);
  };
  const handleNavLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, any>,
  ) => {
    const eventAction = 'click';
    const navAnalytics: { [key: string]: string } = {
      '/onsite-days': 'onsite_sessions',
      '/bookings': 'all_bookings',
      '/': 'virtual_sessions',
    };
    const eventCategory = navAnalytics[event.currentTarget.pathname];
    track(eventCategory, {
      eventAction,
      eventCategory,
      eventLabel: eventCategory,
      userType: Analytics.getUserType(),
    });
    setIsToggleOpen(false);
  };

  return (
    <div className="header-container">
      <div
        className="hamburgerMenuBtn"
        role="button"
        tabIndex={0}
        onClick={handleToggleOpen}
      >
        <FontAwesomeIcon className="hamburgerMenu" icon={faBars} size="xl" />
      </div>
      <nav className={`navbar-container ${isToggleOpen ? 'open' : ''}`}>
        <div className="navbar-left-items">
          <NavLink to="/" className="nav-item" onClick={handleNavLinkClick}>
            <Typography color="squidInk" size={16} weight="500">
              {t('CARE')}
            </Typography>
          </NavLink>
          {isEmployee && (
            <>
              <NavLink
                to="/onsite-days"
                className="nav-item"
                onClick={handleNavLinkClick}
              >
                <Typography color="squidInk" size={16} weight="500">
                  {t('EVENTS')}
                </Typography>
              </NavLink>
              <NavLink
                to="/bookings"
                className="nav-item"
                onClick={handleNavLinkClick}
              >
                <Typography color="squidInk" size={16} weight="500">
                  {t('BOOKINGS_HISTORY')}
                </Typography>
              </NavLink>
            </>
          )}
        </div>

        {name && isEmployee && location.pathname !== '/onboarding' ? (
          <DropDown
            heading={name}
            values={dropdownMenuItems}
            onChange={onChange}
            icon={profileAvatarIcon}
            className="profile-dropdown"
          />
        ) : (
          <div
            className="userinfo-container"
            role="button"
            tabIndex={0}
            onClick={() => {}}
          >
            <div className="header-profile-image-container">
              <img src={profileAvatarIcon} alt="profile" />
            </div>
            <Typography weight="600" size={16} color="solarized">
              {!isEmployee ? name : email}
            </Typography>
            <div
              style={{ marginLeft: '1rem' }}
              role="button"
              tabIndex={0}
              onClick={() => invalidateUser()}
            >
              <Typography weight="500" size={14} color="error">
                Logout
              </Typography>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}

export default Header;
