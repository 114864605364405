/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOnsiteSlots } from '@hooks/useOnsiteData/useOnsiteData';
import Popup from '@widgets/Popup';
import Loader from '@widgets/Loader';
import { getTimeZone } from '@utilities/common/Date';
import moment from 'moment';
import './OnsiteSessionSchedule.scss';
import Typography from '@widgets/Typography';
import IconText from '@components/IconText';
import PhoneInput from 'react-phone-input-2';
import TextArea from '@widgets/TextArea';
import SelectList from '@widgets/SelectList';
import Banner from '@widgets/Banner';
import locationIcon from '../../../assets/location-pin.svg';
import dateIcon from '../../../assets/onsite-calendar.svg';
import { IOnsiteSessionSchedule, IOnsiteSessionScheduleForm } from './types';
import alertIcon from '../../../assets/alert.svg';

export default function OnsiteSessionSchedule({
  closeHandler,
  eventLocation,
  eventTimings,
  onSubmit,
  contactNumber,
  isRescheduleFlow = false,
  handleCancelClick,
  eventId,
  roomGuide,
}: IOnsiteSessionSchedule) {
  const { t } = useTranslation();
  const { rescheduleSlots, isRescheduleSlotsLoading } = useOnsiteSlots(eventId);

  const slots = React.useMemo(
    () =>
      rescheduleSlots?.slots?.map((slot) => ({
        label: moment
          .utc(slot.startTimeUtc * 1000)
          .tz(getTimeZone())
          .format('hh:mm A'),
        value: slot.startTimeUtc.toString(),
        disabled: !slot.active,
      })),
    [rescheduleSlots],
  );

  const {
    control,
    formState: { isValid },
    // watch,
    handleSubmit,
  } = useForm<IOnsiteSessionScheduleForm>({
    mode: 'onChange',
    defaultValues: {
      contactNumber,
      additionalText: '',
      selectedSlot: '',
    },
  });
  const availableSlots = React.useMemo(
    () => slots?.filter((slot) => !slot?.disabled),
    [slots],
  );
  return (
    <Popup
      withBackdrop
      onClose={() => closeHandler(false)}
      className="onsite-schedule-modal"
    >
      <section className="onsite-schedule-wrapper">
        <section className="onsite-session-schedule-header">
          <Typography size={20} weight="500" withColor="#1A2631">
            Book your spot
          </Typography>
        </section>
        <section className="onsite-location-timing-wrapper">
          <Typography size={14} weight="500" withColor="#1A2631">
            When & Where
          </Typography>
          <section className="onsite-location-timing">
            <IconText
              icon={dateIcon}
              text={eventTimings}
              textFontSize={14}
              textFontWeight="400"
              fontColor="#6C6D6E"
              className="customized-icon"
              iconWidth="16px"
              iconHeight="16px"
            />
            <IconText
              icon={locationIcon}
              text={eventLocation}
              textFontSize={14}
              textFontWeight="400"
              fontColor="#6C6D6E"
              className="customized-icon"
              iconWidth="16px"
              iconHeight="16px"
              redirectUrl={roomGuide}
            />
          </section>
        </section>
        <section className="form-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <section className="form-content">
              <label className="form-label" htmlFor="phoneNumber">
                Phone Number
              </label>
              <Controller
                name="contactNumber"
                control={control}
                rules={{ minLength: 5 }}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <PhoneInput
                    inputClass="onsite-phone-input"
                    country="sg"
                    specialLabel=""
                    placeholder="Enter here"
                    inputProps={{
                      name: 'contact',
                      required: true,
                      autoFocus: false,
                    }}
                    {...field}
                    disabled
                  />
                )}
              />
              <IconText
                icon={alertIcon}
                iconHeight="13.5px"
                iconWidth="13.5px"
                text={t('PHN_NUM_DISCLAIMER')}
                fontColor="#6C6D6E"
                textFontSize={12}
                textFontWeight="400"
                textWidth="100%"
              />
            </section>
            <section className="form-content">
              <label className="form-label" htmlFor="additionalText">
                What would you like to talk about
              </label>
              <Controller
                name="additionalText"
                control={control}
                rules={{ required: false }}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextArea
                    value={field.value}
                    onChange={(val) => field.onChange(val.trimStart())}
                  />
                )}
              />
            </section>
            <section className="form-content">
              {isRescheduleSlotsLoading && <Loader />}
              {!!availableSlots?.length && (<label className="form-label" htmlFor="sessionSlot">
                Select a slot for 1-1 counseling session
              </label>)}
              <Controller
                name="selectedSlot"
                control={control}
                rules={{
                  required: true,
                  validate: (value) =>
                    !!availableSlots?.length && availableSlots.filter((slot) => slot.value === value)
                      .length > 0,
                }}
                render={({ field: { ref, ...field } }) => (
                  <SelectList
                    {...field}
                    options={availableSlots || []}
                    selectedValue={field.value}
                    onClick={(slot) => field.onChange(slot)}
                    emptyLabel={{
                      show: !availableSlots?.length,
                      label: (
                        <Banner bannerText={t('NO_AVAILABLE_SLOTS')} />
                      ),
                    }}
                  />
                )}
              />
            </section>
            <section className="btn-container">
              {!!availableSlots?.length && <input
                className="submit-cta"
                value={isRescheduleFlow ? 'Reschedule session' : 'Confirm'}
                type="submit"
                disabled={!isValid}
              />}
              {isRescheduleFlow && (
                <input
                  className="cancel-cta"
                  onClick={handleCancelClick}
                  type="Cancel"
                  value="Cancel"
                />
              )}
            </section>
          </form>
        </section>
      </section>
    </Popup>
  );
}
