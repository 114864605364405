import * as React from 'react';
import { sortBy } from 'lodash';
import useTracking from '@hooks/useTracking';
import OnsiteCardLayout from '@components/OnsiteCardLayout';
import calendar from '@assets/onsite-calendar.svg';
import locationPin from '@assets/location-pin.svg';
import Typography from '@widgets/Typography';
import moment from 'moment';
import './OnsiteEventHome.scss';
import { useNavigate } from 'react-router-dom';
import useOnsiteData, {
  useAllBookings,
} from '@hooks/useOnsiteData/useOnsiteData';
import SelectBox from '@widgets/SelectBox';
import { ProviderRoles } from '@constants/provider';
import { getOnsiteEventTimings } from '@utilities/common/Date';
import { getImageUri } from '@utilities/common/Path';
import NoDataPlaceholder from '@components/NoDataPlaceholder';
import Loader from '@widgets/Loader';
import { MeetingStatus, OnEventSiteStatus } from '@constants/meetingStatus';
import { EventActions, EventCategories, EventNames } from '@constants/analytics';
import Analytics from '@services/Analytics';
import useProfileDetails from '../Provider/Home/hooks/useProfileDetails';
import OnsiteUpcomingSessions from './OnsiteUpcomingSession';


export default function OnsiteEventHome() {
  const { track } = useTracking();
  const { data: profileData, isLoading: isLoadingProfile } = useProfileDetails({
    refetchOnWindowFocus: false,
  });
  const [onsiteLocValue, setOnsiteLocValue] = React.useState('All');

  const { misc, phoneNumber } = profileData || {};
  const navigate = useNavigate();
  const {
    onsiteEvents,
    isOnsiteEventsLoading,
    locationData,
    isLocationsLoading,
  } = useOnsiteData(
    onsiteLocValue !== 'All' ? Number(onsiteLocValue) : undefined,
  );
  const filters = [
    {
      field: 'status',
      value: [MeetingStatus.scheduled],
    },
    {
      field: 'role',
      value: [ProviderRoles.ONSITE],
    },
  ];
  const { meetings, isLoading, refetchAllBookings } = useAllBookings(filters);

  const onsiteLocationsOptions = React.useMemo(() => {
    const onsiteLocationNames: { label: string; value: string }[] = [];

    if (locationData?.locations?.length) {
      locationData?.locations.forEach((loc) => {
        onsiteLocationNames.push({
          label: loc.city,
          value: loc.id.toString(),
        });
      });
    }
    return [
      {
        label: 'All Locations',
        value: 'All',
      },
      ...sortBy(onsiteLocationNames, (val) => val.label),
    ];
  }, [locationData?.locations]);

  React.useEffect(() => {
    if (misc && misc.region) {
      const defualtLocation =
        onsiteLocationsOptions.find(
          (loc) => loc.label === misc.region.split(',')?.[0],
        )?.value || 'All';
      setOnsiteLocValue(defualtLocation);
    }
  }, [misc, locationData]);

  const activeOnsiteEvents = React.useMemo(
    () => onsiteEvents?.deployments.filter((event) => event.status === OnEventSiteStatus.LIVE),
    [onsiteEvents],
  );
  if (
    isOnsiteEventsLoading ||
    isLocationsLoading ||
    isLoadingProfile ||
    isLoading
  ) {
    return <Loader useFullPage withBackdrop />;
  }
  return (
    <article className="onsite-event-home">
      {meetings && meetings?.length >= 1 && (
        <OnsiteUpcomingSessions
          meetingDetails={meetings}
          phoneNumber={phoneNumber}
          onReschedule={refetchAllBookings}
        />
      )}
      <section className="header-onsite">
        <section className="left-content">
          <Typography size={24} weight="400">
            Discover onsite days near you 
          </Typography>
        </section>
        <section className="right-content">
          <SelectBox
            values={onsiteLocationsOptions}
            onChange={(val) => { 
              setOnsiteLocValue(val);
              track( EventNames.location, {
                eventAction: EventActions.click,
                eventCategory: EventCategories.location,
                eventLabel: 'location',
                userType: Analytics.getUserType(),
                eventSource: 'onsite',
                eventValue: onsiteLocationsOptions?.find(({ value }) => value === val)?.label as string,
              });

            }}
            selectedValue={onsiteLocValue}
            minWidth="150px"
          />
        </section>
      </section>
      {activeOnsiteEvents && activeOnsiteEvents.length >= 1 ? (
        <section className="grid-container">
          {activeOnsiteEvents.map(
            ({
              location,
              provider,
              events,
              fromDate,
              id,
              fromTime,
            }) => (
              <OnsiteCardLayout
                isDeployementCard
                cardTitle={`Onsite Day - ${location?.city}`}
                dateIcon={calendar}
                locationIcon={locationPin}
                onClick={() => navigate(`/onsite-days/${id}`)}
                eventLocation={location}
                eventTimings={getOnsiteEventTimings(fromDate, fromTime)}
                eventDate={moment(fromDate).format('DD')}
                eventMonth={moment(fromDate).format('MMM')}
                providerAvatar={getImageUri(provider?.profileImage as string)}
                providerName={provider?.name}
                providerSpeciality={provider?.expertise?.join(', ')}
                events={events}
                isLoading={
                  isOnsiteEventsLoading ||
                  isLocationsLoading ||
                  isLoadingProfile
                }
              />
            ),
          )}
        </section>
      ) : (
        <NoDataPlaceholder />
      )}
    </article>
  );
}
