export const defaultStyles = {
  primaryColor: '#1a2631',
  secondaryColor: '636c73',
  while: {
    pureWhite: '#fff',
  },
  gray: {
    400: '#aaaaaa',
    500: '#667085',
  },
};
