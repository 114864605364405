import * as React from 'react';
import './AttachmentPreview.scss';
import { motion } from 'framer-motion';
import Typography from '@widgets/Typography';
import { IMessageSendType } from '@components/Chat/data-types/ChatBrokerTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { byteToMegaByte } from '@components/Chat/utils/helper';
import ImageMessageItem from '../ImageMessageItemV2';
import { MessageSendType } from '../../constants/CommonConstants';
import pdfIcon from '../../assets/pdf-icon.svg';

interface AttachmentPreviewProps {
  attachmentFile: IMessageSendType;
  onClose: () => void;
}
function AttachmentPreview({
  attachmentFile,
  onClose,
}: AttachmentPreviewProps) {
  const file = attachmentFile.data as File;
  const url =
    attachmentFile.type === MessageSendType.IMAGE
      ? URL.createObjectURL(file)
      : pdfIcon;
  return (
    <motion.div
      className="attachment-preview"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="attachment-container">
        <ImageMessageItem
          imageUrl={url}
          disabled={attachmentFile.type !== MessageSendType.IMAGE}
          height="48px"
          width="48px"
        />
        {attachmentFile.type !== MessageSendType.IMAGE && (
          <div className="file-detail">
            <Typography weight="600" size={12}>
              {file.name}
            </Typography>
            <Typography size={12} weight="600">{byteToMegaByte(file.size)} MB</Typography>
          </div>
        )}
        <div
          className="discard-preview"
          onClick={onClose}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={{ color: '#767D83' }}
            size="sm"
          />
        </div>
      </div>
    </motion.div>
  );
}

export default AttachmentPreview;
