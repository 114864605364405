import * as React from 'react';
import Button from '@widgets/Button';
import Typography from '@widgets/Typography';
import './NoDataPlaceholder.scss';
import searchIllustration from '../../../assets/SearchIllustration.svg';

export interface INoDataPlaceHolderProps {
  headerText?: string;
  subHeaderText?: string;
  illustration?: string;
  onClick?: () => void;
}
export default function NoDataPlaceholder({
  headerText = 'No events happening here!',
  subHeaderText = 'Oops! There are no onsite days scheduled for your location at the moment.',
  illustration = searchIllustration,
  onClick,
}: INoDataPlaceHolderProps) {
  return (
    <section className="no-data-wrapper">
      <section className="illustration-container">
        <img src={illustration} alt="no-data-placeholder" />
      </section>
      <section className="no-data-body">
        <Typography size={24} weight="600">
          {headerText}
        </Typography>
        <Typography size={16} weight="400" withColor="#828B9C">
          {subHeaderText}
        </Typography>
      </section>
      {onClick && (
        <section className="no-data-footer">
          <Button
            label="Okay"
            onClick={onClick}
            width="100%"
          />
        </section>
      )}
    </section>
  );
}
