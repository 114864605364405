import * as React from 'react';
import Typography from '@widgets/Typography';
import alertIcon from '../../../assets/alert.svg';
import './Banner.scss';

interface IBannerProps {
  bannerText: string;
  bannerIcon?: string;
  bannerVariant?: 'error' | 'info'; // todo if new variants to be added in future
}
function Banner({
  bannerText,
  bannerIcon = alertIcon,
  bannerVariant = 'info',
}: IBannerProps) {
  return (
    <section className={`banner-container ${bannerVariant}`}>
      <img src={bannerIcon} alt="alert-icon" className="alert-icon" />
      <Typography size={14} weight="400" withColor="#3F2B0D">
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: bannerText }} />
      </Typography>
    </section>
  );
}

export default Banner;
