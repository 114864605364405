import * as React from 'react';
import './ContextMenu.scss';
// import { motion } from 'framer-motion';
import Typography, { FontColors } from '../Typography';

interface IContextMenuPosition {
  top: string;
  right: string;
  bottom?: string;
  left?: string;
}

export interface IMenuItem {
  id: string;
  icon?: string;
  value: string;
  fontColor?: FontColors;
}

interface IContextMenu {
  heading?: string;
  menuItems: Array<IMenuItem>;
  onChange: (menuItem: IMenuItem) => void;
  initialMenuItem?: IMenuItem;
  position: IContextMenuPosition;
  show: boolean;
  borderVisible?: boolean;
}

function ContextMenu({
  heading,
  menuItems,
  onChange,
  initialMenuItem,
  position,
  show,
  borderVisible = false,
}: IContextMenu) {
  const [selectedItem, setSelectedItem] = React.useState<IMenuItem>(
    () => initialMenuItem || menuItems[0],
  );
  const { top, right } = position;

  return (
    <div
      className={`context-menu ${show ? 'show' : ''}`}
      style={{ top, right }}
    >
      {heading && (
        <div className="heading">
          <Typography weight="500" size={16}>
            {heading}
          </Typography>
        </div>
      )}
      <div className="menu-items">
        {menuItems.map((item, index) => (
          <div
            className={`menu-item ${item === selectedItem ? 'selected' : ''}`}
            tabIndex={index}
            key={item.id}
            onClick={() => {
              setSelectedItem(item);
              onChange(item);
            }}
            role="button"
          >
            {borderVisible && (
              <div
                className={`selection-indicator ${
                  item === selectedItem ? 'selected' : ''
                }`}
              />
            )}
            {item?.icon && (
              <img className="menu-item-icon" src={item.icon} alt="icon" />
            )}
            <Typography size={16} weight="400" color={item.fontColor}>
              {item.value}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ContextMenu;
