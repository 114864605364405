import * as React from 'react';
import './Popup.scss';
import { motion } from 'framer-motion';
import closeIcon from '../../../assets/close.svg';
import Backdrop from '../Backdrop';

interface IPopupProps {
  children: React.ReactNode;
  onClose?: React.MouseEventHandler;
  withBackdrop?: boolean;
  showCloseIcon?: boolean;
  className?: string;
  allowBackdropClickClose?: boolean;
}

function Popup({
  children,
  onClose = () => {},
  withBackdrop = true,
  showCloseIcon = true,
  className,
  allowBackdropClickClose = false,
}: IPopupProps) {
  return (
    <>
      {withBackdrop && (
        <Backdrop onClick={allowBackdropClickClose ? onClose : undefined} />
      )}
      <motion.div
        className={`popup-container ${className}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="popup-modal"
      >
        {showCloseIcon && (
          <img
            className="close-icon"
            src={closeIcon}
            alt="close"
            onClick={onClose}
          />
        )}
        {children}
      </motion.div>
    </>
  );
}

export default Popup;
