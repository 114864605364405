import * as React from 'react';
import moment from 'moment';
import Popup from '@widgets/Popup';
import Button from '@widgets/Button';
import Typography from '@widgets/Typography';
import successIcon from '../../../../assets/SuccessIllustration.svg';
import './SuccessModal.scss';

export default function SuccessModal({
  onClose,
  upcomingSessionDetails,
  isReschedule = false,
}: {
  onClose: () => void;
  upcomingSessionDetails: string | null;
  isReschedule?: boolean;
}) {
  const upcomingSessionMoment = moment.unix(Number(upcomingSessionDetails));
  return (
    <Popup className="success-modal-wrapper" onClose={onClose}>
      <section className="success-modal-content">
        <section className="success-modal-body">
          <img src={successIcon} alt="success-icon" className="illustration" />

          <section className="confirmation-text">
            <Typography size={20} weight="500" withColor="#1A2631">
              {isReschedule ? 'Session rescheduled!' : 'You’re all set!'}
            </Typography>
          </section>
          <section className="upcoming-session-info">
            <Typography size={16} weight="600" withColor="#6c6d6e">
              Upcoming session on:
            </Typography>
            <Typography size={24} weight="600">
              {upcomingSessionMoment.format('ddd, MMM D [at] h:mma')}
            </Typography>
          </section>
        </section>
        <Button label="Okay" onClick={onClose} variant="blue" width="65%" />
      </section>
    </Popup>
  );
}
