import * as React from 'react';
import { useTranslation } from 'react-i18next';
import chevronDownIcon from '../../../../../assets/chevron-down.svg';
import ChatHelplinePopup from './ChatHelplinePopup';

export function HowToUseChat() {
  const [expanded, setExpanded] = React.useState(true);
  const [showChatHelplinePopup, setShowChatHelplinePopup] =
    React.useState(false);

  const { t } = useTranslation();
  return (
    <div>
      <div className="how-to-use-chat">
        <div
          role="button"
          tabIndex={0}
          className="title-content"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="title">{t('HOW_TO_USE_CHAT')}</div>
          <img
            src={chevronDownIcon}
            alt="expand"
            style={{
              transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </div>
        <div className={`expanded-content${expanded ? ' open' : ''}`}>
          <ul>
            <li>{t('DISCLAIMER_ONE')}</li>
            <li>
              {t('DISCLAIMER_TWO')}
            </li>
            <li>
              {t('DISCLAIMER_TWO_PART_ONE')}{' '}
              <span
                className="local-helpline-link"
                role="button"
                tabIndex={0}
                onClick={() => setShowChatHelplinePopup(true)}
              >
                {t('DISCLAIMER_TWO_PART_TWO')}
              </span>{' '}
              {t('DISCLAIMER_TWO_PART_THREE')}{' '}
              <a
                target="_blank"
                className="local-helpline-link"
                href="https://intellect.co/mental-health-hotlines"
                rel="noreferrer"
              >
                {t('DISCLAIMER_TWO_PART_FOUR')}
              </a>
            </li>
          </ul>
        </div>
      </div>
      {showChatHelplinePopup ? (
        <ChatHelplinePopup onClose={() => setShowChatHelplinePopup(false)} />
      ) : null}
    </div>
  );
}
