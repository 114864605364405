import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Typography from '@widgets/Typography';
import Button from '@widgets/Button';
import ClickableCard from '@widgets/ClickableCard';
import Loader from '@widgets/Loader';
import { CancellationReasonsEnum } from '@constants/cancellation';
import toast from 'react-hot-toast';
import { getCurrentLocale } from '../../../../i18n';
import { useCancellationReasons } from '../hooks/useCancellation';
import './SessionCancelPopup.scss';

function CancellationReasons({
  handleCancel,
  userType,
}: {
  handleCancel: (value: {
    type: string;
    otherReason: string;
    index: string;
  }) => void;
  userType: number;
}) {
  const { t } = useTranslation();
  const reasonOptRef = React.useRef<HTMLElement>();
  const reasonOptionsDiv = React.useCallback((node: any) => {
    if (node !== null) {
      reasonOptRef.current = node;
    }
  }, []);
  const [selectedReason, setSelectedReason] = React.useState<{
    type: string;
    otherReason: string;
    index: string;
  }>({
    type: '',
    otherReason: '',
    index: '',
  });
  const {
    data: reasons,
    isLoading: isReasonsLoading,
    isFetching: isReasonsFetching,
  } = useCancellationReasons({
    onError: (e) => {
      toast(e?.message);
    },
    locale: getCurrentLocale(),
    userType,
  });
  const onContinue = () => {
    handleCancel(selectedReason);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedReason((prev) => ({ ...prev, otherReason: e.target.value }));
    e.stopPropagation();
  };

  // moving the scroll to the text box
  React.useEffect(() => {
    if (
      selectedReason.type !== CancellationReasonsEnum.OTHERS &&
      selectedReason.otherReason.length
    ) {
      setSelectedReason((prev) => ({ ...prev, otherReason: '' }));
    } else if (
      selectedReason.type === CancellationReasonsEnum.OTHERS &&
      reasonOptRef.current
    ) {
      reasonOptRef.current.scrollTop =
        reasonOptRef.current.getBoundingClientRect().height - 138;
    }
  }, [selectedReason]);

  if (isReasonsFetching || isReasonsLoading) {
    return (
      <div className="cancellation-reasons-modal loader">
        <Loader useFullPage withBackdrop />
      </div>
    );
  }

  const isDisabled =
    (selectedReason.type === CancellationReasonsEnum.OTHERS &&
      (!selectedReason.otherReason.length ||
        !selectedReason.otherReason.replaceAll(' ', '').length)) ||
    !selectedReason.type.length;
  return (
    <div className="cancellation-reasons-modal">
      <div className="header">
        <Typography size={21} weight="600">
          {t('CANCEL_SESSION')}
        </Typography>
      </div>
      <div className="header">
        <Typography
          size={16}
          weight="400"
          withColor="#0a0c0e5c"
          textAlign="center"
        >
          {t('CANCEL_REASON_SUBHEADER')}
        </Typography>
      </div>
      <div className="reasons" ref={reasonOptionsDiv}>
        {reasons?.map(({ reason, type }) => (
          <ClickableCard
            content={t(reason)}
            selected={selectedReason.type.includes(type)}
            cardContentWidth="100%"
            onClick={(e) => {
              setSelectedReason((prev) => ({ ...prev, type, index: reason }));
              e.stopPropagation();
            }}
            uniqueKey={reason}
            borderRadius="48px"
            height="30px"
            fontSize={16}
            key={reason}
            minHeight="48px"
            width="469px"
          />
        ))}
        {selectedReason.type === CancellationReasonsEnum.OTHERS && (
          <motion.textarea
            onChange={onInputChange}
            placeholder={t('OTHERS_REASON_SUBTEXT')}
            className="other-reasons"
            initial={{ y: -20, opacity: 0 }}
            value={selectedReason.otherReason}
            rows={3}
            animate={{
              y: 0,
              opacity: 1,
              transition: { duration: 0.4 },
            }}
            exit={{
              y: -20,
              opacity: 0,
              transition: { duration: 0.6 },
            }}
          />
        )}
      </div>

      <div className="action-handler">
        <Button
          label={t('CONTINUE')}
          variant="blue"
          onClick={onContinue}
          fontSize={14}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}

export default CancellationReasons;
