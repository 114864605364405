import * as React from 'react';
import Typography from '@widgets/Typography';
import './SessionSelectButton.scss';

function SessionSelectButton({
  buttonName,
  onClick,
  selectedDuration,
  currentElement,
  disabled = false,
}: {
  selectedDuration: string;
  buttonName: string;
  onClick: () => void;
  currentElement: string;
  disabled?: boolean;
}) {
  return (
    <button
      onClick={onClick}
      type="button"
      className="session-button py-2"
      style={{
        backgroundColor:
          selectedDuration === currentElement
            ? 'rgba(134, 162, 188, 0.1)'
            : 'white',
      }}
      disabled = {disabled}
    >
      <Typography size={14} weight="600" color="secondary" textAlign="center">
        {buttonName}
      </Typography>
    </button>
  );
}

export default SessionSelectButton;
