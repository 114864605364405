import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@widgets/Typography';
import Button from '@widgets/Button';
import { BookSessionSources, EventActions, EventCategories, EventNames } from '@constants/analytics';
import useTracking from '@hooks/useTracking';
import Analytics from '@services/Analytics';
import { CancellationPopupContentType } from './Confirmed.type';

interface ICancelWarningModal {
  showNextPopup: (type: keyof CancellationPopupContentType) => void;
  onClose: (canRefetch: boolean, shouldTrack?: boolean) => void;
}

function CancelationWarningModal({ showNextPopup, onClose }: ICancelWarningModal) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const handleTrack = (evc: EventCategories) => {
    track(EventNames.cancelSession, {
      eventCategory: evc,
      eventAction: EventActions.click,
      eventLabel: Analytics.getUserType(),
      source: BookSessionSources.providerProfile,
      eventSource: 'virtual',
    });
  };
  return (
    <article className="cancellation-warning-modal">
      <div className="header">
        <Typography size={24} weight="600">
          {t('CANCELLATION_WARNING_HEADER')}
        </Typography>
      </div>
      <div className="warning-actions">
        <Button 
          variant="blue"
          label={t('YES_CANCEL')}
          onClick={() => {
            handleTrack(EventCategories.cancelSessionContinue);
            showNextPopup('reason');
          }}
          fontSize={14}
          width="100%"
          height="40px"
        />
        <Button
          variant="secondary"
          label={t('NO_CANCEL')}
          onClick={() => {
            handleTrack(EventCategories.doNotCancel);
            onClose(false, false);
          }}
          fontSize={14}
          width="100%"
          height="40px"
        />
      </div>
    </article>
  );

}

export default CancelationWarningModal;