import * as React from 'react';
import { CurrentUserContext, IUserDetails } from '@contexts/CurrrentUserContext';
import { ConfigurationVariables } from '@constants/variables';
import useGetUserDetails from '@hooks/useGetUserData/useGetUserData';
import useAuth from '@hooks/useAuth';

export interface ICurrentUserContextProvider {
  children: React.ReactNode;
}
export function CurrentUserContextProvider({
  children,
}: ICurrentUserContextProvider) {
  const [currentUserData, setCurrentUserData] = React.useState<IUserDetails>({
    isLoading: true,
    userData: {},
    currentUserConfig: ConfigurationVariables.care,
  });
  const { user, isLoggedIn } = useAuth();
  const { clientData, clientDataLoading } = useGetUserDetails(user.token, !!isLoggedIn);

  const updateUserData = (userData: Partial<IUserDetails>) => {
    setCurrentUserData((prev) => ({
      ...prev,
      ...userData,
    }));
  };

  React.useLayoutEffect(() => {
    updateUserData({
      userData: {
        ...clientData,
        emailOriginal: clientData?.email,
        email: clientData?.email.endsWith('@bytedance.com.care') ? clientData.email.replace('@bytedance.com.care', '@bytedance.com') : clientData?.email,
      },
      isLoading: clientDataLoading,
      currentUserConfig: clientData?.config?.MAVERICK_CONFIG
        ? ConfigurationVariables[clientData.config.MAVERICK_CONFIG]
        : ConfigurationVariables.care,
    });
  }, [clientData, clientDataLoading]);

  const CurrentUserContextValues = React.useMemo(
    () => ({
      updateUserData,
      currentUserData,
    }),
    [currentUserData, updateUserData],
  );

  return (
    <CurrentUserContext.Provider value={CurrentUserContextValues}>
      {children}
    </CurrentUserContext.Provider>
  );
}
