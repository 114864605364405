import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useTracking from '@hooks/useTracking';
import { EventActions, EventCategories, EventNames } from '@constants/analytics';
import Analytics from '@services/Analytics';
import './ImageMessageItem.scss';
import useMediaPreview from '@hooks/useMediaPreview';
import Loader from '@widgets/Loader';
import { CssSize } from '@widgets/ClickableCard/types';
import toast from 'react-hot-toast';
import TextMessageItem from '../TextMessageItemV2';

interface IImageMessageItemProps {
  imageUrl: string;
  disabled?: boolean;
  height?: CssSize;
  width?: CssSize;
  body?:string
  source?:string
}

function ImageMessageItem({
  imageUrl,
  disabled,
  height,
  width,
  body,
  source,
}: IImageMessageItemProps) {
  const { track } = useTracking();
  const { t } = useTranslation();
  const [imageFullyLoaded, setImageFullyLoaded] =
    React.useState<boolean>(false);

  const imageRef = React.useRef<HTMLImageElement>(null);
  const { showPreview } = useMediaPreview();

  const onImageLoaded = () => {
    setImageFullyLoaded(true);
  };
  const handleChatImageClick = () => {
    if (!disabled) {
      showPreview(imageUrl);
      track(EventNames.messages, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.clickAttachment,
        eventLabel: 'image',
        userType: Analytics.getUserType(),
      });
    }
  };

  const onError = ()=>{
    setImageFullyLoaded(true);
    toast.error(t('INTERNET_DISCONNECTED_ERROR') as string);
  };

  const loaderDimension = {
    height: height || '200px',
    width: width || '200px',
  } as unknown as DOMRect;

  return (
    <div className="image-content">
      {!imageFullyLoaded && (
        <Loader
          useFullPage={false}
          withBackdrop={false}
          dimension={loaderDimension}
        />
      )}
      <img
        src={imageUrl}
        alt="message"
        ref={imageRef}
        onLoad={onImageLoaded}
        onError={onError}
        style={
          imageFullyLoaded
            ? { visibility: 'visible', height, width }
            : { visibility: 'hidden' }
        }
        onClick={handleChatImageClick}
      />
      {!!body && source && (
        <section className="attached-msg">
          <TextMessageItem source={source} message={body} />
        </section>
      )}
    </div>
  );
}

export default ImageMessageItem;
