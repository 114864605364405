import * as React from 'react';
import Typography from '@widgets/Typography';
import Button from '@widgets/Button/Button';
import { useTranslation } from 'react-i18next';
import './Helpline.scss';
import { useFetch } from '@hooks/useFetch';
import { FetchKeys } from '@constants/queries';
import { IUser } from '@customTypes/response/IUser';
import Loader from '@widgets/Loader';
import { getUserDetail } from '@services/user';
import { tollFreeMsgs } from '@constants/sos';
import infoIcon from '../../../../assets/info-circle.svg';
import NoHelplineRecords from '../NoHelplineRecords';

interface ISOSHelplineProps {
  userToken: string;
  isIntellectEAP: boolean;
  country: string | null;
}

function SOSHelpline({ userToken, isIntellectEAP, country }: ISOSHelplineProps) {
  const { t } = useTranslation();


  const { isLoading, data: helplines } = useFetch<any>(
    [FetchKeys.userDetails],
    () => getUserDetail(userToken),
    {
      refetchOnWindowFocus: false,
      select: (userData: IUser) =>
        userData.config.eapHelpLine
          ? userData.config.eapHelpLine.split(',').map((item: string) => ({
            title: [item.split(':')[0]?.trim()],
            cta: item.split(':')[1],
          }))
          : [],
    },
  );

  const countryBasedHelplines =
    (!!country &&
      helplines?.filter(
        (item: any) =>
          item.title[0]?.toLowerCase() === country.toLocaleLowerCase(),
      )) ||
    helplines;

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="helpline-container">
      {isIntellectEAP && (
        <div className="helpline-header">
          <Typography size={20} weight="700" textAlign="center">
            {t('CONNECT_WITH_EAP')}
          </Typography>
        </div>
      )}
      {!!country && !countryBasedHelplines.length ? (
        <NoHelplineRecords
          heading={t('NO_HELPLINE_RECORDS_HEADER')}
          subHeading={t('NO_HELPLINE_RECORDS_SUBHEADER')}
        />
      ) : (
        <div className="helpline-items">
          {countryBasedHelplines.map((item: any) => (
            <div className="helpline-item" key={item.title}>
              <Typography size={16} weight="600" textAlign="center">
                {t(item.title)}
              </Typography>
              <Button
                height="40px"
                width="100%"
                fontSize={16}
                fontWeight="700"
                onClick={() => {
                  window.open(`tel:${item.cta}`);
                }}
                label={item.cta}
              />
              {item.cta in tollFreeMsgs ? (
                <section className="toll-free-info-container">
                  <img src={infoIcon} alt="info-icon" />
                  <Typography size={14} weight="400" color="trout">
                    {t(tollFreeMsgs[item.cta])}
                  </Typography>
                </section>
              ) : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SOSHelpline;
