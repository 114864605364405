import { IRescheduleRequest } from '@customTypes/request/provider';
import { useMutation } from 'react-query';
import {
  rescheduleSession,
  rescheduleSessionAsCareNavigator,
} from '@services/provider';

interface RescheduleReqType {
  onSuccess: () => void;
  onError: (e: Error) => void;
}
function useReschedule({ onError, onSuccess }: RescheduleReqType) {
  const {
    isSuccess: isRescheduleSuccess,
    isLoading: isRescheduleLoading,
    mutate: reschedule,
    data: response,
    isError: isRescheduleError,
  } = useMutation(
    ({
      userId,
      meetingId,
      rescheduleData,
    }: {
      userId: string | null;
      meetingId: string;
      rescheduleData: IRescheduleRequest;
    }) => {
      
      if (userId) {
        return rescheduleSessionAsCareNavigator(
          rescheduleData,
          userId,
          meetingId,
        );
      }
      return rescheduleSession(rescheduleData, meetingId);
    },
    {
      onSuccess,
      onError,
    },
  );

  return {
    isRescheduleLoading,
    isRescheduleSuccess,
    reschedule,
    response,
    isRescheduleError,
  };
}

export default useReschedule;
