import { AxiosResponse } from 'axios';
import { sendMonitoringEvent } from '@utilities/common/monitoring';

const onResponseFulfilled = (response: AxiosResponse) => {
  console.log('%c Response: ', 'background: #0f0; color: #fff', response);

  return response;
};

const onResponseRejected = (error: any) => {
  sendMonitoringEvent(error);
  if (error.response && error.response.status === 401) {
    // Cookie.remove('user');
    // store.dispatch(UserActions.setLogoutSucess());
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    // store.dispatch(
    //   AppActions.setErrorMessage(error.response.data.error.message)
    // );
  }
  console.log('%c Error: ', 'background: #f00; color: #fff', error.response);
  if (error.response.status === 403) {
    // removeItemFromLocalStorage(StorageItems.USER_INFO);
    // gotoRoute('/login');
    // console.log('logout');
  }
  return Promise.reject(error.response);
};

export { onResponseFulfilled, onResponseRejected };
