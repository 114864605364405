import { TimezoneByCountry } from '@constants/timezones';

export const getCountryFromTimezone = (timezone: string) => {
  const country = TimezoneByCountry.find(({ timezones }) =>
    timezones.includes(timezone),
  );

  return country;
};


export const getCountryDataFromCountryCode = (code: string) =>
  TimezoneByCountry.find((c) => c.code === code);
