import * as React from 'react';
import { motion } from 'framer-motion';

import './Button.scss';

import Typography from '../Typography';
import { FontWeights } from '../Typography/Typography';

interface IButtonProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  variant?:
  | 'primary'
  | 'secondary'
  | 'raised'
  | 'caution'
  | 'blue'
  | 'transparent'
  | 'label';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  icon?: string;
  width?: string;
  height?: string;
  fontSize?: number;
  fontWeight?: FontWeights;
}

function Button(
  {
    label,
    icon,
    type = 'button',
    variant = 'primary',
    fontSize = 12,
    onClick = () => {},
    disabled = false,
    width = '130px',
    height = '2.5rem',
    fontWeight = '600',
  }: IButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const variantType = disabled ? 'disabled' : variant;

  return (
    <motion.button
      type={type || 'button'}
      id={label?.replaceAll(' ', '').replaceAll('*', '')}
      whileHover={{ scale: disabled ? 1 : 1.05 }}
      whileTap={{ scale: disabled ? 1 : 0.97 }}
      className={`button-container ${variantType}`}
      style={{ minWidth: width, height }}
      onClick={disabled ? () => {} : onClick}
      disabled={disabled}
      ref={ref}
    >
      {variant === 'raised' && <img src={icon} className="icon" alt="filter" />}
      <Typography
        size={fontSize}
        weight={fontWeight}
        color={
          variant === 'primary' || variant === 'blue' ? 'light' : 'primary'
        }
      >
        {label}
      </Typography>
    </motion.button>
  );
}

export default React.forwardRef(Button);
