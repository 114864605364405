import * as React from 'react';
import Typography, { FontColors } from '@widgets/Typography';
import './UnorderedList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { openExternalUrl } from '@utilities/common/Path';

interface UnorderedListProps {
  items: {
    icon?: string;
    label: string;
    redirectURL?: string;
  }[];
  labelColor?: FontColors;
}

function UnorderedList({ items, labelColor = 'primary' }: UnorderedListProps) {
  return (
    <div className="unordered-list">
      <ul>
        {items.map(({ label, icon, redirectURL }) => (
          <li>
            {icon && <img src={icon} alt="" />}
            <Typography size={12} weight="400" color={labelColor}>
              {label}
            </Typography>
            {redirectURL && (
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ color: '#636C73', marginLeft: '8px' }}
                size="sm"
                onClick={() => openExternalUrl(redirectURL)}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UnorderedList;
