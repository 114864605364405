import * as React from 'react';
import './SOS.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { beautifyUrl } from '@utilities/common/Path';
import Typography from '@widgets/Typography';
import Button from '@widgets/Button/Button';
import handshake from '../../../assets/handshake.png';

interface SOSProps {
  sosTitle?: string;
  isIntellectEAP?: boolean;
  userToken: string;
  country: string | null;
  locale: string | null;
  source: string | null;
}

function SOS({
  sosTitle,
  isIntellectEAP,
  userToken,
  country,
  locale,
  source,
}: SOSProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="sosContainer">
      <img src={handshake} className="sos-banner" alt="Handshake" />
      <div className="title-container">
        <Typography size={20} weight="700" textAlign="center">
          {t('NEED_TO_TALK')}
        </Typography>
        <Typography size={20} weight="700" textAlign="center">
          {t('GET_IMMEDIATE_HELP')}
        </Typography>
      </div>
      <div className="sos-description">
        <Typography
          size={14}
          weight="400"
          withColor="#7D8489"
          textAlign="center"
        >
          If you have suicidal thoughts or feel like you’re at breaking point,
          get immediate support from our 24/7 helplines
        </Typography>
      </div>
      <div className="sos-action-block">
        {!sosTitle && (
          <Typography size={12} weight="400" withColor="#7D8489">
            {t('FREE')}
          </Typography>
        )}
        <Button
          width="100%"
          variant="transparent"
          onClick={() =>
            navigate(
              beautifyUrl('/sos/helpline', [], {
                token: userToken,
                ...(country && { country }),
                ...(locale && { locale }),
                ...(source && { source }),
              }),
            )
          }
          label={
            !isIntellectEAP
              ? t('YOUR_COMPANY_EAP')
              : t('INTELLECT_HELPLINE_TITLE')
          }
        />
        <Button
          width="100%"
          variant="transparent"
          onClick={() => {
            window.open('https://intellect.co/mental-health-hotlines/');
          }}
          label={sosTitle || t('LOCAL_HOT_LINES')}
        />
        <div className="bottom-divider" />
      </div>
    </div>
  );
}

export default SOS;
