import * as React from 'react';
import './AudioMessageItem.scss';
import AudioMessagePlayer from '../AudioMessagePlayerV2';
import { ChatSource } from '../../constants/CommonConstants';
import TextMessageItem from '../TextMessageItemV2';

interface IAudioMessageItemProps {
  audioUrl: string;
  source: ChatSource;
  body?: string;
}

function AudioMessageItem({ audioUrl, source, body }: IAudioMessageItemProps) {
  return (
    <div className="audio-content">
      <AudioMessagePlayer source={source} url={audioUrl} />
      {!!body && <section className="attached-msg">
        <TextMessageItem source={source} message={body}/>
      </section>}
    </div>
  );
}

export default AudioMessageItem;
