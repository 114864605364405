import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { newMessageNotificationSound } from '@utilities/common/audio';
import useAuth from '@hooks/useAuth';
import toast from 'react-hot-toast';
import ChatBroker from './ChatBroker';
import { IMessageSendType } from '../data-types/ChatBrokerTypes';
import { MessageChannelTypes } from '../constants/CommonConstants';


const ChatBrokerRef = new ChatBroker();

export function useChatBroker() {
  const { isLoggedIn, user } = useAuth();
  const { t } = useTranslation();
  const { data:allList, isLoading: enabled, refetch:refetchConversationList } = useQuery(['conversationList'], ChatBrokerRef.getConversationList, {
    enabled: !!isLoggedIn,
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
  });
  const isNextMessageLoaded = React.useRef(false);
  const {
    isLoading,
    data,
    refetch: refetchLatestConversation,
  } = useQuery(['conversationListManipulated', Object.keys(allList?.data || {}).length], ChatBrokerRef.getAllConversations, {
    enabled: !enabled,
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
  });

  const { mutate: updateMsgConsumptionStatus } = useMutation(
    ({ userId, channelType }: { userId: string; channelType: MessageChannelTypes }) =>
      ChatBrokerRef.updateMessageConsumptionStatus(userId, channelType),
    {
      onSuccess: () => {
        refetchLatestConversation();
      },
    },
  );

  const { mutate: sendMessage } = useMutation(
    ({
      contentToSend,
      conversationId,
      channelType,
    }: {
      contentToSend: IMessageSendType;
      conversationId: string;
      channelType: MessageChannelTypes;
    }) => ChatBrokerRef.sendMessage(contentToSend, conversationId, channelType),
    {
      onError: () => {
        toast.error(t('INTERNET_DISCONNECTED_ERROR') as string);
      },
    },
  );

  // fetches individual user messages

  const { mutate: getSelectedUserMessages, isLoading: loading } = useMutation(
    ({
      selectedConversationId,
      channelId,
      pageNo,
      channelType,
      refetch,
    }: {
      selectedConversationId: string;
      channelId: string;
      pageNo: number;
      channelType: MessageChannelTypes;
      refetch?: boolean;
    }) => ChatBrokerRef.getSelectedUserMessages(selectedConversationId, channelId, pageNo, channelType, refetch),
    {
      onSuccess: (_, variables) => {
        if (!variables?.refetch) isNextMessageLoaded.current = true;
      },
    },
  );

  React.useEffect(() => {
    if (isLoggedIn) {
      ChatBrokerRef.onSocketNewMessage((message) => {
        refetchLatestConversation();
        getSelectedUserMessages({
          selectedConversationId: '',
          channelId: '',
          pageNo: 1,
          channelType: MessageChannelTypes.TWILIO,
          refetch: true,
        });
        if (!(message.senderId.toString() === 'system' || message.senderId.toString() === user.id)) {
          newMessageNotificationSound.play();
        }
      });
    }
  }, [user, isLoggedIn, refetchLatestConversation, getSelectedUserMessages]);

  return {
    allConversationList: data,
    refetchConversationList,
    refetchLatestConversation,
    isLoading: isLoading || enabled,
    unreadCount: ChatBrokerRef.unreadCount,
    liveChatData: {
      getSelectedUserMessages,
      isLoading: loading,
      updateMsgConsumptionStatus,
      selectedUserData: ChatBrokerRef.userMessages,
      getSelectedUserUnreadCount: ChatBrokerRef.getSelectedUserUnreadCount,
      isNextMessageLoaded,
    },
    sendMessage,
  };
}
