/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import Typography from '../Typography';
import chevronDownIcon from '../../../assets/chevron-down.svg';
import './MultiSelect.scss';
import CheckBox from '../CheckBox';
import useOutsideClickHandler from '../../../utilities/hooks/useOutsideClickHandler';
import TextBox from '../TextBox';
import searchIcon from '../../../assets/search.svg';

interface MultiSelectProps {
  placeholder: string;
  options: {
    label: string;
    key: string;
  }[];
  onClose: (selectedValues: string[]) => void;
  initialValues?: string[];
  maxSelection?: number;
  // conditionally update selectedItems
  updateOnSelect?: (newValue: string, selected: string[]) => string[];
  showLabelsOnSelect?: boolean;
  enableSelectboxSearch?: boolean;
  dropDownWidth?: string;
  selectboxSearchPlacholder?: string;
  disabled?: boolean;
}

function MultiSelect({
  options,
  placeholder,
  onClose,
  initialValues,
  maxSelection,
  updateOnSelect,
  showLabelsOnSelect = false,
  enableSelectboxSearch = false,
  dropDownWidth = '240px',
  selectboxSearchPlacholder = '',
  disabled = true,
}: MultiSelectProps) {
  const { t } = useTranslation();
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [showItems, setShowItems] = React.useState<boolean>(false);
  const [selectedItems, setSelectedItems] = React.useState<string[]>(
    () => initialValues || [],
  );
  const [searchText, setSearchText] = React.useState<string>('');

  const filteredOption = React.useMemo(
    () =>
      options.filter((option) =>
        option.label.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [searchText, options],
  );

  const onItemSelect = (isChecked: boolean, value: string) => {
    if (isChecked) {
      if (!selectedItems.includes(value)) {
        if (updateOnSelect) {
          setSelectedItems(updateOnSelect(value, selectedItems));
        } else if (maxSelection && selectedItems.length >= maxSelection) {
          toast(`Only ${maxSelection} Allowed.`);
        } else {
          setSelectedItems((prev) => [...prev, value]);
        }
      }
    } else {
      setSelectedItems((prev) => prev.filter((item) => item !== value));
    }
  };

  useOutsideClickHandler(parentRef, () => setShowItems(false));

  React.useEffect(() => {
    if (!showItems) {
      onClose(selectedItems);
    }
  }, [showItems]);

  const getSelectedItemsLabel = () =>
    filteredOption
      .reduce<Array<string>>((val, currVal) => {
        if (selectedItems.includes(currVal.key)) val.push(currVal.label);
        return val;
      }, [])
      .join(', ');

  return (
    <motion.div
      className="multi-select-container"
      ref={parentRef}
      whileHover={{ border: '1px solid #263E58' }}
      animate={showItems ? { border: '1px solid #263E58' } : {}}
    >
      <div
        role="button"
        tabIndex={0}
        className={`placeholder-container ${
          showLabelsOnSelect ? 'wrapSelectedOpt' : ''
        }`}
        onClick={() => setShowItems((prev) => !prev)}
      >
        <Typography size={12} weight="400" overFlowControl="ellipsis">
          {!selectedItems.length
            ? placeholder
            : (showLabelsOnSelect && getSelectedItemsLabel()) ||
              t('filterSelected', { count: selectedItems.length })}
        </Typography>
        <img src={chevronDownIcon} alt="more" />
      </div>
      <AnimatePresence>
        {showItems && (
          <motion.div
            className="select-list"
            initial={{ opacity: 0, translateY: '-70px' }}
            animate={{ opacity: 1, translateY: '45px' }}
            exit={{ opacity: 0, translateY: '-70px' }}
            transition={{ duration: 0.4 }}
            style={{ width: dropDownWidth }}
          >
            {enableSelectboxSearch && (
              <section className="search-wrapper">
                <TextBox
                  variant="box-border"
                  placeholder={t(selectboxSearchPlacholder)}
                  icon={searchIcon}
                  value={searchText}
                  disabled={disabled}
                  onChange={(e) => setSearchText(e.target.value)}
                  animate={false}
                />
              </section>
            )}
            <section className="options-wrapper">
              {filteredOption.map(({ label, key }) => (
                <div className="select-list-item" key={key}>
                  <CheckBox
                    isAsync={false}
                    isChecked={selectedItems.includes(key)}
                    onChange={(isChecked) => onItemSelect(isChecked, key)}
                  />
                  <Typography size={12} weight="400" color="secondary">
                    {t(label)}
                  </Typography>
                </div>
              ))}
            </section>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default MultiSelect;
