import { FetchKeys } from '@constants/queries';
import { IMeetingInfo } from '@customTypes/response/meeting';
import { useFetch } from '@hooks/useFetch';
import { getMeetingDetails } from '@services/meeting';

function useMeeting(
  meetingId: string,
  channel: string,
  enabled: boolean,
  {
    onSuccess,
    onError,
  }: { onSuccess: (meetingInfo: IMeetingInfo) => void; onError: (error: Error) => void },
  deps: Array<string> = [],
) {
  return useFetch<IMeetingInfo>(
    [FetchKeys.meetingJoinDetails, meetingId, ...deps],
    () => getMeetingDetails(meetingId, channel),
    {
      enabled,
      onSuccess,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: (error) => onError(error as Error),
    },
  );
}

export default useMeeting;
