import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Popup from '@widgets/Popup';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import Typography from '@widgets/Typography';
import {
  clinicalSessionDurations,
  coachingSessionDurations,
  ProviderRoles,
} from '@constants/provider';
import {
  AvailableSessionDurations,
  IProviderOpenSlotsFull,
  IProviderProfile,
} from '@customTypes/response/provider';
import DatePicker from '@widgets/DatePicker';
import Loader from '@widgets/Loader';
import { getTimeZoneAbbreviation } from '@utilities/common/Date';
import SelectList from '@widgets/SelectList';
import { WeekdayEnum } from '@constants/App';
import Button from '@widgets/Button';
import SelectBox from '@widgets/SelectBox';
import useAuth from '@hooks/useAuth';
import { IProviderMeeting } from '@customTypes/response/meeting';
import useTracking from '@hooks/useTracking';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import {
  BookSessionSources,
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import useProviderOpenSlots from '../hooks/useProviderOpenSlots';
import useReschedule from '../hooks/useReschedule';
import ConfirmationPopup from './ConfirmationPopup';
import SessionSelectButton from './SessionSelectButton';
import './RescheduleSession.scss';
import '../SessionSchedulePopup.scss';
import useProviderLeaveDays from '../hooks/useProviderLeaveDays';
import useProviderServices from '../hooks/useProviderServices';

interface IRescheduleSession {
  onClose: (canRefetch?: boolean, shouldTrack?: boolean) => void;
  role: string;
  providerData: IProviderProfile;
  meetingData: IProviderMeeting & { type?: string };
}
function RescheduleSession({
  onClose,
  role,
  providerData,
  meetingData,
}: IRescheduleSession) {
  const navigate = useNavigate();
  const [selectedDuration, setSelectedDuration] =
    React.useState<AvailableSessionDurations | null>(null);
  const [showSuccessPopup, setShowSuccessPopup] =
    React.useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<string>('');
  const [selectedSlot, setSelectedSlot] = React.useState<string>('');
  const [locationId, setLocationId] = React.useState<string>(meetingData.room?.locationId || '');

  const { isLoading: isSlotsLoading, data: providerOpenSlots } =
    useProviderOpenSlots<IProviderOpenSlotsFull>(
      providerData.providerId.toString(),
      role,
      locationId,
    );
  const { user } = useAuth();

  const {
    currentUserData: { userData: clientData },
  } = useCurrentUserContext();

  const { isLoading: isLeaveDaysLoading, data: providerLeaveDays } =
    useProviderLeaveDays(providerData.providerId.toString());

  const { isLoading: isProviderServicesLoading, data: providerServices } =
    useProviderServices(providerData.providerId.toString(), {
      onSuccess: () => {},
    });

  const { t } = useTranslation();
  const { track } = useTracking();
  const { isRescheduleLoading, isRescheduleSuccess, reschedule, response } =
    useReschedule({
      onError: () => {
        toast('Unable to reschedule the meeting');
      },
      onSuccess: () => {
        setShowSuccessPopup(true);
      },
    });
  const getAvailableSlotsDates = () => {
    if (
      providerOpenSlots &&
      selectedDuration &&
      providerOpenSlots[selectedDuration]
    ) {
      return providerOpenSlots[selectedDuration].map(
        (slot) => slot.displayDate,
      );
    }

    return [];
  };

  const getAvailableSlots = () => {
    let slots: { label: string; value: string }[] = [];
    if (
      providerOpenSlots &&
      selectedDuration &&
      providerOpenSlots[selectedDuration]
    ) {
      const availableSlots = providerOpenSlots[selectedDuration];
      slots = availableSlots
        .filter((slot) => slot.displayDate === selectedDate)
        .map(({ displayTime, startTimeUtc }) => ({
          label: displayTime,
          value: startTimeUtc.toString(),
        }));
    }

    return slots;
  };

  const slots = React.useMemo(
    () => getAvailableSlots(),
    [selectedDuration, selectedDate, locationId],
  );

  const handleReschedule = () => {
    track(EventNames.rescheduleSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.confirmReschedule,
      eventLabel: Analytics.getUserType(),
      source: BookSessionSources.providerProfile,
      eventSource: 'virtual',
    });
    reschedule({
      meetingId: meetingData.id,
      userId:
        user.isCareNavigator && role === ProviderRoles.INTELLECTCLINICOFFSITE
          ? clientData.id
          : null,
      rescheduleData: {
        scheduledStartTime: selectedSlot,
        duration: selectedDuration as string,
        role,
        ...(user.isCareNavigator &&
        role === ProviderRoles.INTELLECTCLINICOFFSITE
          ? { locationId }
          : {}),
      },
    });
  };
  const handleOkay = React.useCallback(() => {
    setShowSuccessPopup(false);
    onClose(true, false);
    navigate('/');
  }, []);

  React.useEffect(() => {
    if (role !== '0' && clinicalSessionDurations.length) {
      setSelectedDuration(clinicalSessionDurations[0].value);
    }
  }, [role, clinicalSessionDurations]);

  React.useEffect(() => {
    if (selectedDate.length && selectedSlot.length) {
      setSelectedSlot('');
    }
  }, [selectedDate]);

  const durationList =
    role === '0' ? coachingSessionDurations : clinicalSessionDurations;

  if (isRescheduleSuccess && showSuccessPopup) {
    const weekDay = response.day
      ? moment.weekdaysShort(WeekdayEnum[response.day])
      : '';
    const formattedDate = response.displayDate
      ? moment(response.displayDate).format('MMM DD')
      : '';
    // const formattedTiming = response.displayTime
    //   ? moment(
    //     `${response.displayDate} ${response.displayTime.slice(
    //       0,
    //       response.displayTime.length - 2,
    //     )}`,
    //   ).format('hh:mm a')
    //   : '';
    return (
      <ConfirmationPopup
        rescheduledDateTime={`${weekDay} ${formattedDate} at ${response.displayTime}`}
        location={response?.room}
        handleOkay={handleOkay}
      />
    );
  }

  return (
    <Popup withBackdrop onClose={() => onClose(false, true)}>
      {(isRescheduleLoading ||
        isSlotsLoading ||
        isLeaveDaysLoading ||
        isProviderServicesLoading) && <Loader useFullPage />}
      <div className="reschedule-popup">
        <div className="popup-header">
          <Typography size={24} weight="500">
            {t('RESCHEDULE_SESSION')}
          </Typography>
          {providerData.provider.leave.onLeave ? (
            <div className="on-leave-banner">
              {t('BANNER_TEXT_OOO', {
                date: providerData.provider.leave.to
                  ? moment.unix(providerData.provider.leave.to).format('DD MMM')
                  : '',
              })}
            </div>
          ) : null}
        </div>
        <div className="reschedule-form">
          <div className="form-item">
            <div className="form-item-row">
              <div className="form-item-row-item">
                <Typography size={16} weight="600">
                  {t('SELECT_SESSION_DURATION')}
                </Typography>
                <Typography size={12} weight="500" withColor="#0a0c0e5c">
                  {t('CREDITS_USED_NOTIFY')}
                </Typography>
                <div className="form-item-row">
                  {durationList.map(({ label, value }) => (
                    <div className="form-item-row-item">
                      <SessionSelectButton
                        selectedDuration={selectedDuration || ''}
                        buttonName={label}
                        onClick={() => setSelectedDuration(value)}
                        currentElement={value}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {user.isCareNavigator &&
          role === ProviderRoles.INTELLECTCLINICOFFSITE ? (
              <div className="form-item">
                <Typography size={14} weight="500">
                  {t('location')}
                </Typography>
                <SelectBox
                  fullWidth
                  selectedValue={locationId}
                  values={
                    providerServices
                      ? providerServices.clinics.map((clinic) => ({
                        label: clinic.name,
                        value: clinic.id,
                      }))
                      : []
                  }
                  disabled={isProviderServicesLoading || !providerServices}
                  onChange={(value) => setLocationId(value)}
                />
              </div>
            ) : null}

          <div className="form-item">
            <div className="form-item-row">
              <div className="form-item-row-item">
                <Typography size={16} weight="600">
                  {t('START_DATE')}
                </Typography>
                <DatePicker
                  enabledDates={getAvailableSlotsDates()}
                  disabledDates={providerLeaveDays}
                  onSelect={(date) => setSelectedDate(date)}
                />
              </div>
            </div>
          </div>

          <div className="form-item fixed-container">
            <Typography size={16} weight="600">
              {t('SELECT_TIME')}&nbsp;({getTimeZoneAbbreviation()})
            </Typography>
            <SelectList
              options={slots}
              selectedValue={selectedSlot}
              onClick={(newSlot) => setSelectedSlot(newSlot)}
              emptyLabel={{
                show: Boolean(selectedDate) && !slots.length,
                label: `${t('NO_SLOTS_AVAILABLE')}`,
              }}
            />
          </div>
          <div className="action-container">
            <Button
              width="100%"
              height="40px"
              label={t('CONFIRM_CTA')}
              onClick={handleReschedule}
              variant="blue"
              disabled={!selectedDate || !selectedSlot}
              fontSize={14}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default RescheduleSession;
