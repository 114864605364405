import { useFetch } from '@hooks/useFetch';
import {
  cancelMeeting,
  cancelMeetingAsCareNavigator,
  getCancellationReasons,
} from '@services/provider';
import { useMutation } from 'react-query';

function useCancellation({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: Error) => void;
}) {
  return useMutation(
    ({
      userId,
      meetingId,
      note,
      reason,
    }: {
      userId?: string | null;
      meetingId: string;
      note?: string;
      reason?: {
        type: string;
        otherReason: string;
      };
    }) => {
      if (userId) {
        return cancelMeetingAsCareNavigator(
          userId,
          meetingId,
          note as string,
          reason,
        );
      }
      return cancelMeeting(meetingId, note as string, reason);
    },
    {
      onSuccess,
      onError,
    },
  );
}

function useGetCancellationReasons({
  onError,
  locale,
  userType,
}: {
  onError: (e: any) => void;
  locale: string;
  userType: number;
}) {
  return useFetch<Array<{ type: string; reason: string }>>(
    ['cancellation-reasons', locale, userType],
    () => getCancellationReasons({ locale, userType }),
    {
      enabled: !!(locale.length && userType.toString().length),
      onError,
      refetchOnWindowFocus: false,
    },
  );
}

export const useCancellationReasons = useGetCancellationReasons;

export default useCancellation;
