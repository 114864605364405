import * as React from 'react';
import './ChatHeader.scss';
import { providerCardPillLabel } from '@constants/provider';
import useLayout from '@hooks/useLayout';
import useTracking from '@hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AllConversationListType } from '../../data-types/ChatBrokerTypes';
import Typography from '../../../../widgets/Typography';
import ChatAvatar from '../ChatAvatarV2';
import minimizeIcon from '../../assets/minimize.svg';

export interface ChatHeaderProps {
  userMetaData: AllConversationListType;
  isUserInfoVisible: boolean;
}

function ChatHeader({ userMetaData, isUserInfoVisible }: ChatHeaderProps) {
  const { providerName, providerRole, providerImage } = userMetaData;
  const { updateChatWindowSettings } = useLayout();
  const { track } = useTracking();
  const { t } = useTranslation();

  return (
    <div className="chat-header">
      <div className="chat-header-container">
        <div
          className={`user-info-container ${
            isUserInfoVisible ? 'hideUserInfo' : ''
          }`}
        >
          <ChatAvatar name={providerName} profileImage={providerImage} />
          <div className="user-info">
            <div className="user-tags-and-name">
              <Typography size={16} weight="500" color="primary">
                {providerName}
              </Typography>
            </div>
            <div className="user-type">
              <Typography size={10} weight="400" color="primary">
                {providerCardPillLabel[providerRole]}
              </Typography>
            </div>
          </div>
        </div>

        <div className="toolbar-container">
          <div
            className="tool-item"
            role="button"
            tabIndex={0}
            onClick={() => {
              updateChatWindowSettings({ show: false });
              track(EventNames.closeChat, {
                eventAction: EventActions.click,
                eventCategory: EventCategories.closeChat,
                userType: Analytics.getUserType(),
              });
            }}
          >
            <img src={minimizeIcon} alt="close chat" />
          </div>
        </div>
      </div>
      {userMetaData?.providerLeave?.onLeave ? (
        <div className="on-leave-chat-banner">
          {t('BANNER_TEXT_OOO', {
            date: userMetaData?.providerLeave.to
              ? moment.unix(userMetaData?.providerLeave.to).format('DD MMM')
              : '',
          })}
        </div>
      ) : null}
    </div>
  );
}

export default ChatHeader;